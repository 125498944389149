import { Times } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import Chip from '../../../common/components/Chip/Chip'
import { useInsuranceName } from '../../../common/hooks/useInsurance'
import { selectedInsuranceAtom } from '../../../state/common/atoms'
import { useModalEvents } from '../../../xstate/events'

interface Props {
  setStatusMessage(message: string): void
}

const SearchFilterInsuranceChip: React.FC<React.PropsWithChildren<Props>> = ({
  setStatusMessage,
}) => {
  const { t } = useTranslation()
  const [selectedInsurance, setSelectedInsurance] = useRecoilState(selectedInsuranceAtom)
  const { name: insuranceName } = useInsuranceName(selectedInsurance)
  const { openInsuranceModal } = useModalEvents()

  return (
    <>
      {selectedInsurance === null ? (
        <Chip
          variant="important"
          ariaLabel={t('component.searchFilterChips.insurance')}
          label={t('component.searchFilterChips.insurance')}
          onClick={openInsuranceModal}
          order="icon-first"
          dataCy="searchFilterInsuranceChip-chip"
        />
      ) : (
        <Chip
          variant="important"
          ariaLabel={`${t('common.removeFilter')} ${insuranceName}`}
          label={insuranceName}
          onClick={() => {
            setStatusMessage(`${t('common.filterRemoved')} ${insuranceName}`)
            setSelectedInsurance(null)
          }}
          icon={<Times />}
        />
      )}
    </>
  )
}

export default SearchFilterInsuranceChip
