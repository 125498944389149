import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import * as yup from 'yup'

import useUserState from '../../domain/reserve/hooks/useUserState'
import { teliaJWTAtom } from '../../state/user/atoms'
import { useFeatureFlags } from '../hooks/useFeatureFlags'

const MARKETING_CONSENT_ID = 'general_digital_marketing'
const MARKETING_CATEGORY_ID = 1

export const useUserConsents = () => {
  const jwt = useRecoilValue(teliaJWTAtom)
  const {
    userState: { user },
  } = useUserState()
  const featureFlags = useFeatureFlags()

  const { data, isLoading } = useQuery({
    queryKey: ['userConsents'],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_OMAMEHILAINEN_API}/rest/v1/users/v1/${user?.omUid}/consents`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: jwt ?? '' },
      })
        .then((res) => res.json())
        .then((raw) => consentSchema.validate(raw, { stripUnknown: true, strict: true }))
        .catch((error) => {
          Sentry.captureException(error)
          return null
        }),
    enabled: Boolean(jwt && user && featureFlags?.marketingConsentEnabled),
  })

  return { consents: data ?? undefined, loading: isLoading }
}

export const useUserMarketingConsent = () => {
  const { i18n } = useTranslation()
  const { consents, loading } = useUserConsents()
  const marketingConsent = useMemo(() => {
    if (loading || !consents) {
      return { value: undefined, header: '', description: '', label: '' }
    }

    const consent = consents.consents.find((c) => c.id === MARKETING_CONSENT_ID)
    const category = consents.consentCategories.find(
      (consentCategory) => consentCategory.id === MARKETING_CATEGORY_ID
    )
    const content = consents.consentContents.find(
      (consentContent) => consentContent.id === MARKETING_CONSENT_ID
    )

    return {
      value: consent?.value,
      header: category?.i18n.title[i18n.language] ?? '',
      description: category?.i18n.description[i18n.language] ?? '',
      label: content?.i18n.title[i18n.language] ?? '',
    }
  }, [consents, i18n.language, loading])

  return { ...marketingConsent, loading }
}

export const saveUserMarketingConsent = async (jwt: string | null, omUid: number, value: boolean) =>
  fetch(`${process.env.REACT_APP_OMAMEHILAINEN_API}/rest/v1/users/v1/${omUid}/consents`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: jwt ?? '' },
    body: JSON.stringify({ [MARKETING_CONSENT_ID]: value }),
  })

const consentSchema = yup.object().shape({
  consents: yup
    .array()
    .of(
      yup.object().shape({
        omUid: yup.number().required(),
        pid: yup.number().required(),
        id: yup.string().required(),
        value: yup.boolean().required(),
        version: yup.number().nullable(),
      })
    )
    .required(),
  consentContents: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        type: yup.string().required(),
        optType: yup.string().required(),
        minimumRequiredVersion: yup.number().nullable(),
        versionInUse: yup.number().required(),
        createdDt: yup.string().required(),
        modifiedDt: yup.string().required(),
        releaseDt: yup.string().required(),
        isVisible: yup.boolean().required(),
        categoryId: yup.number().required(),
        version: yup.number().required(),
        i18n: yup.object().shape({
          title: yup.object().shape({
            fi: yup.string().nullable(),
            sv: yup.string().nullable(),
            en: yup.string().nullable(),
          }),
          terms: yup.object().shape({
            fi: yup.string().nullable(),
            sv: yup.string().nullable(),
            en: yup.string().nullable(),
          }),
        }),
      })
    )
    .required(),
  consentCategories: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        i18n: yup.object().shape({
          title: yup.object().shape({
            fi: yup.string().nullable(),
            sv: yup.string().nullable(),
            en: yup.string().nullable(),
          }),
          description: yup.object().shape({
            fi: yup.string().nullable(),
            sv: yup.string().nullable(),
            en: yup.string().nullable(),
          }),
        }),
      })
    )
    .required(),
})
