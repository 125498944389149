import { useSelector } from '@xstate/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { Gender as ApiGender } from '../__generated__/api'
import { BreadcrumbPath } from '../common/components/Breadcrumb/Breadcrumb'
import { Gender } from '../common/components/FilterOptions/types'
import { isOHCAtom, selectedInsuranceAtom } from '../state/common/atoms'
import {
  AppointmentSearchMode,
  appointmentTypesNoCallback,
  defaultOhcSearchNodeId,
  defaultSelectedSearchNodeId,
  defaultSelectedTimeRanges,
} from '../state/search/atoms'

import { AppMachineContext } from './providers'
import { SearchTargetValue } from './types'

const useModalMachine = () => {
  const actorRef = AppMachineContext.useActorRef()
  return useSelector(actorRef, (snapshot) => snapshot.context.modalMachineRef)
}

const useSearchMachine = () => {
  const actorRef = AppMachineContext.useActorRef()
  return useSelector(actorRef, (snapshot) => snapshot.context.searchMachineRef)
}

export const useModalMachineState = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot)
}

export const useSelectedSearchNodeId = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedNodeId)
}

export const useSelectedOMCarePlanGuid = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedOMCarePlanGuid)
}

export const useSelectedPractitionerId = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedPractitionerId)
}

// FIXME: get from searchMachine instead?
export const useSelectedPractitioner = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedPractitioner)
}

export const useModalBreadcrumb = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.searchBreadcrumb)
}

export const useSelectedStandardScheduleOption = () => {
  const actorRef = useModalMachine()
  return useSelector(
    actorRef,
    (snapshot) => snapshot.context.selectedStandardScheduleOption ?? 'default'
  )
}

export const useSelectedFlexibleScheduleOption = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedFlexibleScheduleOption)
}

export const useSelectedNoteModalId = () => {
  const actorRef = useModalMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedNoteModalId)
}

export const useAppointmentSearchMode = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) =>
    snapshot.context.selectedPractitionerOption === 'callback'
      ? AppointmentSearchMode.CALLBACK
      : AppointmentSearchMode.REGULAR
  )
}

const useSelectedInsurance = () => {
  // FIXME: move to xstate
  const selectedInsurance = useRecoilValue(selectedInsuranceAtom)
  return selectedInsurance
}

export const useNodeSearchParams = () => {
  const searchTarget = useSearchTarget()
  const filterOptions = useSearchFilterOptions()
  const selectedDate = useSelectedDate()
  const selectedLocation = useSearchLocation()

  // FIXME: move to xstate
  const selectedInsurance = useSelectedInsurance()
  const isOHC = useRecoilValue(isOHCAtom)

  return useMemo(
    () => ({
      id: searchTarget.value === SearchTargetValue.Node ? searchTarget.id : '',
      locationIds: selectedLocation,
      appointmentTypes:
        filterOptions.appointmentTypes.length === 0
          ? appointmentTypesNoCallback
          : filterOptions.appointmentTypes,
      ...(filterOptions.timeRanges?.length !== defaultSelectedTimeRanges.length
        ? { timeRanges: filterOptions.timeRanges }
        : {}),
      ...(filterOptions.language ? { languageId: Number(filterOptions.language.id) } : {}),
      ...(filterOptions.gender === Gender.FEMALE
        ? { gender: ApiGender.F }
        : filterOptions.gender === Gender.MALE
        ? { gender: ApiGender.M }
        : {}),
      ...(filterOptions.patientAgeGroup
        ? { patientAgeGroupId: filterOptions.patientAgeGroup.id }
        : {}),
      ...(filterOptions.durations && filterOptions.durations.length > 0
        ? { durations: filterOptions.durations }
        : {}),
      ...(selectedInsurance !== null ? { insuranceContractId: selectedInsurance } : {}),
      startDate: (selectedDate ?? dayjs()).toJSON(),
      isOHC: isOHC,
    }),
    [searchTarget, selectedLocation, filterOptions, selectedInsurance, selectedDate, isOHC]
  )
}

export const useSearchStandardScheduleOption = () => {
  const actorRef = useSearchMachine()
  const selectedOption = useSelector(
    actorRef,
    (snapshot) => snapshot.context.selectedPractitionerOption
  )

  return useMemo(() => {
    if (!selectedOption || typeof selectedOption === 'object') {
      return 'default'
    }
    return selectedOption
  }, [selectedOption])
}

export const useSearchFlexibleScheduleOption = () => {
  const actorRef = useSearchMachine()
  const selectedOption = useSelector(
    actorRef,
    (snapshot) => snapshot.context.selectedPractitionerOption
  )

  return useMemo(() => {
    if (!selectedOption || !(typeof selectedOption === 'object')) {
      return undefined
    }
    return selectedOption
  }, [selectedOption])
}

export const usePractitionerSearchParams = () => {
  const searchTarget = useSearchTarget()
  const selectedDate = useSelectedDate()
  const filterOptions = useSearchFilterOptions()
  const selectedFlexibleScheduleOption = useSearchFlexibleScheduleOption()
  const selectedStandardScheduleOption = useSearchStandardScheduleOption()
  const selectedLocation = useSearchLocation()
  const breadcrumb = useBreadCrumb()

  const nodeId = useMemo(() => {
    // Practitioner search initiated through node search as the breadcrumb contains (default) > node > practitioner
    if (
      breadcrumb.length === 3 &&
      breadcrumb[2].searchTarget.value === SearchTargetValue.Practitioner &&
      breadcrumb[1].searchTarget.value === SearchTargetValue.Node
    ) {
      return breadcrumb[1].searchTarget.id
    }
    return undefined
  }, [breadcrumb])

  return useMemo(
    () => ({
      practitionerId: searchTarget.value === SearchTargetValue.Practitioner ? searchTarget.id : 0,
      appointmentTypes:
        filterOptions.appointmentTypes.length === 0
          ? appointmentTypesNoCallback
          : filterOptions.appointmentTypes,
      ...(nodeId ? { nodeId } : {}), // Include nodeId if practitioner search initiated through node search
      startDate: (selectedDate ?? dayjs()).toJSON(),
      ...(selectedFlexibleScheduleOption
        ? {
            durations: selectedFlexibleScheduleOption.availableDurations,
            serviceId: selectedFlexibleScheduleOption.serviceId,
            location: selectedFlexibleScheduleOption.locations,
          }
        : typeof selectedStandardScheduleOption === 'number'
        ? { duration: selectedStandardScheduleOption }
        : selectedStandardScheduleOption.startsWith('node-')
        ? { nodeId: selectedStandardScheduleOption.substring('node-'.length) }
        : selectedStandardScheduleOption.startsWith('reason-')
        ? { reasonId: selectedStandardScheduleOption.substring('reason-'.length) }
        : {}),
      ...(filterOptions.timeRanges?.length !== defaultSelectedTimeRanges.length
        ? { timeRanges: filterOptions.timeRanges }
        : {}),
      ...(selectedLocation.length > 0 ? { location: selectedLocation } : {}),
    }),
    [
      searchTarget,
      filterOptions,
      selectedDate,
      selectedFlexibleScheduleOption,
      selectedStandardScheduleOption,
      selectedLocation,
      nodeId,
    ]
  )
}

export const useCalendarSearchParams = () => {
  const { startDate, ...nodeSearchParams } = useNodeSearchParams()
  const practitionerSearchParams = usePractitionerSearchParams()
  const searchTarget = useSearchTarget()

  return useMemo(
    () => ({
      nodeSearchParams,
      practitionerSearchParams,
      isPractitionerSearch: searchTarget.value === SearchTargetValue.Practitioner,
      isNodeSearch: searchTarget.value === SearchTargetValue.Node,
    }),
    [nodeSearchParams, practitionerSearchParams, searchTarget]
  )
}

export const useSearchParams = () => {
  const nodeSearchParams = useNodeSearchParams()
  const practitionerSearchParams = usePractitionerSearchParams()
  const searchTarget = useSearchTarget()

  return useMemo(
    () => ({
      nodeSearchParams,
      practitionerSearchParams,
      isPractitionerSearch: searchTarget.value === SearchTargetValue.Practitioner,
      isNodeSearch: searchTarget.value === SearchTargetValue.Node,
    }),
    [nodeSearchParams, practitionerSearchParams, searchTarget]
  )
}

export const useSearchFilterOptions = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.filterOptions)
}

export const useSearchTarget = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedSearchTarget)
}

export const useSelectedDate = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.selectedDate)
}

export const useIsUserSelectedDate = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.isUserSelectedDate)
}

export const useDefaultNode = () => {
  const isOHC = useRecoilValue(isOHCAtom)
  return isOHC ? defaultOhcSearchNodeId : defaultSelectedSearchNodeId
}

export const useIsUserSelectedNode = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => snapshot.context.isUserSelectedNode)
}

export const useSelectedNodeIdOrDefault = () => {
  const actorRef = useSearchMachine()
  const isOHC = useRecoilValue(isOHCAtom)
  return useSelector(actorRef, (snapshot) =>
    snapshot.context.selectedSearchTarget.value === SearchTargetValue.Node
      ? snapshot.context.selectedSearchTarget.id
      : isOHC
      ? defaultOhcSearchNodeId
      : defaultSelectedSearchNodeId
  )
}

export const useBreadCrumb = (): BreadcrumbPath[] => {
  const { t } = useTranslation()
  const isOHC = useRecoilValue(isOHCAtom)
  const actorRef = useSearchMachine()
  const searchBreadcrumb = useSelector(actorRef, (snapshot) => snapshot.context.breadcrumb)

  const breadcrumb = useMemo(() => {
    return searchBreadcrumb.map((breadcrumbItem) => {
      return {
        name: null,
        searchTarget: breadcrumbItem,
      }
    })
  }, [searchBreadcrumb])

  return useMemo(
    () => [
      {
        name: isOHC ? t('common.OHCHome') : t('common.home'),
        searchTarget: {
          id: isOHC ? defaultOhcSearchNodeId : defaultSelectedSearchNodeId,
          value: SearchTargetValue.Node,
        },
      },
      ...breadcrumb,
    ],
    [isOHC, t, breadcrumb]
  )
}

export const useSearchLocation = () => {
  const actorRef = useSearchMachine()
  return useSelector(actorRef, (snapshot) => {
    const nodeLocation = snapshot.context.selectedNodeLocation
    const practitionerLocation = snapshot.context.selectedPractitionerLocation
    const searchTarget = snapshot.context.selectedSearchTarget
    return searchTarget.value === SearchTargetValue.Node ? nodeLocation : practitionerLocation
  })
}
