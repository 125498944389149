import { atom } from 'recoil'

import { AppointmentType } from '../../__generated__/api'
import { Modal } from '../../__generated__/api'
import type { AppointmentListVariant } from '../../common/components/AppointmentList/AppointmentListToggle'
import { Gender } from '../../common/components/FilterOptions/types'
import dayjs from '../../common/utils/dayjs/dayjs'

export enum AppointmentSearchMode {
  REGULAR = 'regular',
  CALLBACK = 'callback',
}

export const defaultSelectedDate = dayjs().utc().startOf('day')

export const defaultOhcSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_OHC_SEARCH_NODE ?? ''

export const defaultSelectedSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_SEARCH_NODE ?? ''

export const defaultDentalSearchNodeId =
  process.env.REACT_APP_DEFAULT_APPOINTMENT_DENTAL_SEARCH_NODE ?? ''

export const defaultSelectedStandardScheduleOption = 'default'

export const getDefaultSelectedLocation = () => {
  const storedLocation = sessionStorage.getItem('selectedLocation')
  if (storedLocation) {
    sessionStorage.removeItem('selectedLocation')
    return JSON.parse(storedLocation)
  } else {
    return defaultSelectedLocation
  }
}
export const setDefaultSelectedLocation = (location: string[]) => {
  sessionStorage.setItem('selectedLocation', JSON.stringify(location))
}

export const defaultSelectedLocation = process.env.REACT_APP_DEFAULT_SELECTED_LOCATION?.split(
  ','
) ?? ['city2']

export const allLocationsSelection = ['ALL-LOCATIONS']

export const defaultSelectedLanguage = null

export const defaultSelectedGender = Gender.UNSPECIFIED

export const appointmentTypesNoCallback = Object.values(AppointmentType).filter(
  (type) => type !== AppointmentType.Callback
)

export const defaultSelectedAppointmentTypes = []

export const defaultSelectedTimeRanges = []

export const defaultSelectedPatientAgeGroup = null

export const defaultSelectedDurations = []

export const defaultSelectedAppointmentListVariant = 'time'

export const defaultAppointmentSearchMode = AppointmentSearchMode.REGULAR

export const selectedAppointmentListVariantAtom = atom<AppointmentListVariant>({
  key: 'search-selectedAppointmentListVariantAtom',
  default: defaultSelectedAppointmentListVariant,
})

export const callbackModalOpenAtom = atom<boolean>({
  key: 'search-callbackModalOpenAtom',
  default: false,
})

export const searchServiceModalOpenAtom = atom<boolean>({
  key: 'search-searchServiceModalOpenAtom',
  default: false,
})

export const searchServiceModalAutoOpenAtom = atom<boolean>({
  key: 'search-searchServiceModalAutoOpenAtom',
  default: true,
})

export const searchNodeRedirectModal = atom<{ url: string; modalName: string } | null>({
  key: 'search-searchNodeRedirectModalName',
  default: null,
})
export const searchNodeModal = atom<Modal | null>({
  key: 'search-searchNodeModal',
  default: null,
})
export const controlSearchNodeModal = atom<['force', string] | 'skip' | null>({
  key: 'search-controlSearchNodeModal',
  default: null,
})

export const landingModalOpenAtom = atom<boolean>({
  key: 'search-landingModalOpen',
  default: true,
})
