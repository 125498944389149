import React from 'react'
import { useTranslation } from 'react-i18next'

import { convertSearchNodes } from '../../../common/components/Search/utils'
import ServiceSelect from '../../../common/components/SearchSelect/SearchSelect'
import { useNodes } from '../../../common/hooks/useNode'
import { useModalEvents } from '../../../xstate/events'
import { useModalBreadcrumb } from '../../../xstate/selectors'

const SearchServiceSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { listedNodes, favoriteNodes } = useNodes()
  const { selectService, closeModal } = useModalEvents()
  const breadcrumb = useModalBreadcrumb()

  return (
    <ServiceSelect
      listedNodes={listedNodes}
      favoriteNodes={favoriteNodes}
      breadcrumb={convertSearchNodes(breadcrumb, t('component.serviceSearch.secondaryGroupLabel'))}
      onSelect={selectService}
      onClose={closeModal}
    />
  )
}

export default SearchServiceSelect
