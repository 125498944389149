import React from 'react'

import usePreserveQueryParams from '../../hooks/usePreserveQueryParams'

/**
 * Component that preserves specific query parameters across navigation
 * This is a utility component with no UI that should be included once in the app
 */
const QueryParamPreserver: React.FC = () => {
  usePreserveQueryParams()
  return null
}

export default QueryParamPreserver
