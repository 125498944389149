import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { AppointmentType, PractitionerDetails } from '../../../__generated__/api'
import {
  AppointmentDuration,
  AppointmentLengthOption,
} from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import Modal from '../../../common/components/Modal/Modal'
import { modalTriggererAtom as appointmentOptionsModalTriggerer } from '../../../state/appointmentOptionsSelect/atoms'
import { selectedAppointmentLengthAtom } from '../../../state/common/atoms'

import LengthSelect from './LengthSelect'

export type AppointmentOptions = {
  duration: AppointmentDuration
  serviceId?: number
  preselectedType?: AppointmentType
}

interface Props {
  appointmentId: number
  practitionerDetails: PractitionerDetails
  lengthOptions: AppointmentLengthOption[]
  onSelect(options: AppointmentOptions): void
  onSelectUnavailableDuration(duration: AppointmentDuration): void
  onClose(): void
}

const AppointmentOptionsSelectModal: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
  lengthOptions,
  onSelect,
  onSelectUnavailableDuration,
  onClose,
}) => {
  const { t } = useTranslation()
  const practitionerPageSelectedAppointmentLength = useRecoilValue(selectedAppointmentLengthAtom)
  const modalTriggerer = useRecoilValue(appointmentOptionsModalTriggerer)
  const [isAutoSelecting, setIsAutoSelecting] = useState(false)
  const [selectedAppointmentLength, setSelectedAppointmentLength] =
    useState<AppointmentLengthOption | null>(null)

  useEffect(() => {
    if (!selectedAppointmentLength) {
      setSelectedAppointmentLength(
        lengthOptions.find((option) => option.available) ?? lengthOptions[0]
      )
    }
  }, [lengthOptions, selectedAppointmentLength])

  useEffect(() => {
    if (practitionerPageSelectedAppointmentLength) {
      const found = lengthOptions?.find(
        (option) => option.duration === practitionerPageSelectedAppointmentLength
      )
      if (found && found.available) {
        setSelectedAppointmentLength(found)
      }
    }
  }, [practitionerPageSelectedAppointmentLength, lengthOptions])

  // Skip the modal and go straight to the next phase if
  // 1) The practitioner has no patient restrictions to show
  // 2) There is no length options other than the default one to select
  useEffect(() => {
    if (practitionerDetails.patientRestrictions.length === 0 && lengthOptions.length === 1) {
      if (
        modalTriggerer === 'appointmentSelect' &&
        onSelect &&
        !isAutoSelecting &&
        selectedAppointmentLength
      ) {
        setIsAutoSelecting(true)
        onSelect({
          duration: selectedAppointmentLength.duration,
          serviceId: selectedAppointmentLength.serviceId,
        })
      } else if (modalTriggerer === 'back' && onClose) {
        onClose()
      }
    }
  }, [
    practitionerDetails,
    lengthOptions,
    modalTriggerer,
    onSelect,
    isAutoSelecting,
    selectedAppointmentLength,
    onClose,
  ])

  if (selectedAppointmentLength === null || isAutoSelecting) {
    return null
  }

  return (
    <Modal
      open
      onClose={onClose}
      primaryButtonText={t('common.continue')}
      fullWidth={true}
      onPrimaryButtonClick={() => {
        onSelect({
          duration: selectedAppointmentLength.duration,
          serviceId: selectedAppointmentLength.serviceId,
        })
      }}
      secondaryButtonText={t('common.cancel')}
      onSecondaryButtonClick={() => {
        onClose()
      }}
      primaryButtonDisabled={!selectedAppointmentLength.available}
      primaryButtonDaPath="appointment-duration-modal-continue"
      secondaryButtonDaPath="appointment-duration-modal-cancel"
    >
      <LengthSelect
        practitionerDetails={practitionerDetails}
        appointmentLengthOptions={lengthOptions}
        selectedLength={selectedAppointmentLength}
        onSelect={setSelectedAppointmentLength}
        onSelectUnavailableDuration={onSelectUnavailableDuration}
        autoFocusOnHeader
      />
    </Modal>
  )
}

export default AppointmentOptionsSelectModal
