import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useModalEvents } from '../../../xstate/events'
import Modal from '../Modal/Modal'

const Container = styled.div``

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
`

const StyledH1 = styled.h1`
  font-size: 1.125rem;
  font-weight: 450;
`

const CallbackModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { searchCallbackAppointments, closeModal } = useModalEvents()

  return (
    <Modal open={true} onClose={closeModal} fullWidth>
      <Container>
        <ContentContainer>
          <StyledH1>{t('component.callbackModal.title')}</StyledH1>
          <p>{t('component.callbackModal.description')}</p>
          <p>{t('component.callbackModal.additionalInfo')}</p>
          <Button size="large" onClick={searchCallbackAppointments}>
            {t('component.callbackModal.buttonText')}
          </Button>
        </ContentContainer>
      </Container>
    </Modal>
  )
}

export default CallbackModal
