import styled from '@emotion/styled'
import { Gray300 } from '@mehilainen/mds-customer/colors'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { SupportedLanguage } from '../../../__generated__/api'
import Modal from '../../../common/components/Modal/Modal'
import { Text } from '../../../common/components/Typography/Typography'
import { AuthProvider, useAuth } from '../../../common/hooks/useAuth'
import { useOHC } from '../../../common/hooks/useOHC'
import authenticationService from '../../../common/services/authenticationService'
import { scale } from '../../../common/utils/scale'
import { loginStatusAtom, teliaJWTAtom, teliaJWTSessionStorageKey } from '../../../state/user/atoms'
import { useModalEvents } from '../../../xstate/events'

export const ButtonContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: opacity 150ms ease-in;
  opacity: ${(props) => (props.isLoading ? 0.3 : 1)};
  gap: 12px;
`

export const BankButton = styled.button`
  appearance: none;
  background-color: white;
  background-position: center;
  background-size: 80% auto;
  background-repeat: no-repeat;
  border-radius: ${scale(0.5)};
  border: 1.5px solid ${Gray300};
  cursor: pointer;
  position: relative;
  transition:
    box-shadow 150ms ease-in,
    transform 150ms ease-in;
  height: 77px;
  width: 163px;
`

const FooterText = styled(Text)`
  margin-top: 24px;
  align-self: center;
`

const Header = styled(Text)`
  margin-bottom: 24px;
  align-self: center;
`

const Note = styled(Header)``

const AuthenticationModal: React.FC = () => {
  const { login, closeModal } = useModalEvents()
  const { i18n, t } = useTranslation()
  const setTeliaJWT = useSetRecoilState(teliaJWTAtom)
  const { authProviders } = useAuth()
  const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom)
  const { isOHCSide } = useOHC()

  const loginCallback = useCallback(
    async (provider: AuthProvider, locale: SupportedLanguage) => {
      await authenticationService.login(
        { provider: provider.id, locale },
        (token) => {
          setTeliaJWT(token)
          sessionStorage.setItem(teliaJWTSessionStorageKey, token)
          setLoginStatus('authenticated')
          login()
        },
        () => {}
      )
    },
    [setTeliaJWT, setLoginStatus, login]
  )

  const onClose = useCallback(() => {
    if (isOHCSide && loginStatus !== 'authenticated') {
      window.location.href = '/'
      return
    }
    closeModal()
  }, [isOHCSide, loginStatus, closeModal])

  const note = useMemo(
    () => (isOHCSide ? undefined : t('component.appointmentOptionsSelect.authenticationModalNote')),
    [isOHCSide, t]
  )

  return (
    <Modal open={true} onClose={onClose} fullWidth>
      <Header $size={600}>{t('component.authenticationModal.header')}</Header>
      {note && <Note>{note}</Note>}
      <ButtonContainer
        isLoading={authProviders.length === 0}
        data-cy="authenticationModal-buttonContainer"
      >
        {authProviders.map((authProvider, i) => (
          <BankButton
            key={i}
            aria-label={authProvider.name}
            onClick={() => loginCallback(authProvider, i18n.language as SupportedLanguage)}
            style={{ backgroundImage: `url(/img/logo_${authProvider.id}.svg)` }}
          />
        ))}
      </ButtonContainer>
      <FooterText $size={300}>{t('component.authenticationModal.footer')}</FooterText>
    </Modal>
  )
}

export default AuthenticationModal
