import styled from '@emotion/styled'
import { Gray800, Error100, Info800, Info100, Error600 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentType,
  ContractRuleStatus,
  ReservationDestinationSystem,
} from '../../../__generated__/api'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup'
import { Text } from '../Typography/Typography'

export type AppointmentTypeOption = {
  type: AppointmentType
  clinic?: string
  address?: string
  postnumber?: string
  postplace?: string
  locationId?: string
  ohcRule?: ContractRuleStatus
}

interface AppointmentTypeLabelProps {
  type: AppointmentType
  clinic?: string
  address?: string
  postplace?: string
  postnumber?: string
  reservationDestinationSystem?: ReservationDestinationSystem
}

const AppointmentTypeHeading = styled.span`
  font-size: 0.938rem;
  font-weight: 500;
  color: ${Gray800};
`

const Stamp = styled.div<{ mode: 'info' | 'error' }>`
  align-self: baseline;
  background: ${({ mode }) => (mode === 'info' ? Info100 : Error100)};
  border-radius: 0 4px 0 8px;
  padding: ${`${scale(0)} ${scale(1.5)}`};
`

const AppointmentTypeText = styled(Text)``
AppointmentTypeText.defaultProps = { $size: 300, $weight: 'Regular', $height: 'Medium' }

const AppointmentTypeLabelHeader: React.FC<React.PropsWithChildren<{ type: AppointmentType }>> = ({
  type,
}) => {
  const { t } = useTranslation()
  const heading = t(`component.appointmentTypeSelect.label.${type}.heading`)
  return (
    <>
      <AppointmentTypeHeading>{heading}</AppointmentTypeHeading>
    </>
  )
}

const AppointmentTypeDescription: React.FC<React.PropsWithChildren<AppointmentTypeLabelProps>> = ({
  type,
  clinic,
  address,
  postnumber,
  postplace,
  reservationDestinationSystem,
}) => {
  const { t, i18n } = useTranslation()

  const text =
    type === AppointmentType.Clinic ? (
      <>
        <AppointmentTypeText>{clinic}</AppointmentTypeText>
        <AppointmentTypeText>{address}</AppointmentTypeText>
        <AppointmentTypeText>{`${postnumber} ${postplace}`}</AppointmentTypeText>
      </>
    ) : (
      <AppointmentTypeText>
        {i18n.exists(
          `component.appointmentTypeSelect.label.${type}.${reservationDestinationSystem}.text`
        )
          ? t(`component.appointmentTypeSelect.label.${type}.${reservationDestinationSystem}.text`)
          : t(`component.appointmentTypeSelect.label.${type}.text`)}
      </AppointmentTypeText>
    )

  return (
    <>
      <ColumnFlex>{text}</ColumnFlex>
    </>
  )
}

interface Props {
  appointmentTypes: AppointmentTypeOption[]
  reservationDestinationSystem?: ReservationDestinationSystem
  value?: AppointmentTypeOption
  onSelect(value: AppointmentTypeOption): void
}

const AppointmentTypeSelect: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentTypes,
  reservationDestinationSystem,
  value,
  onSelect,
}) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <RadioButtonGroup
      options={appointmentTypes}
      value={value}
      onChange={onSelect}
      isSelected={(option, selection) => {
        // Types must match
        if (option.type !== selection?.type) {
          return false
        }
        // If it is tied to a location (e.g. appointment type is Clinic), locationIds must match
        if (selection.locationId && selection.locationId !== option.locationId) {
          return false
        }
        return true
      }}
      renderOptionHeader={(appointmentType) => (
        <>
          <AppointmentTypeLabelHeader type={appointmentType.type} />
        </>
      )}
      renderOptionDescription={(option) => (
        <AppointmentTypeDescription
          type={option.type}
          clinic={option.clinic}
          address={option.address}
          postplace={option.postplace}
          postnumber={option.postnumber}
          reservationDestinationSystem={reservationDestinationSystem}
        />
      )}
      renderStamp={(option) =>
        option.ohcRule &&
        option.ohcRule !== 'allowed' &&
        option.ohcRule !== 'payment_commitment_allowed' ? (
          <Stamp
            mode={
              option.ohcRule === 'disallowed' || option.ohcRule === 'payment_commitment_disallowed'
                ? 'error'
                : 'info'
            }
          >
            <Text
              $size={isMobile ? 200 : 300}
              $height="Small"
              $color={
                option.ohcRule === 'disallowed' ||
                option.ohcRule === 'payment_commitment_disallowed'
                  ? Error600
                  : Info800
              }
            >
              {t(
                `component.appointmentTypeSelect.ohc.${
                  option.ohcRule === 'payment_commitment_disallowed' ? 'disallowed' : option.ohcRule
                }`
              )}
            </Text>
          </Stamp>
        ) : undefined
      }
      getKey={(appointmentType) =>
        `${appointmentType.type}${
          appointmentType.locationId ? `-${appointmentType.locationId}` : ''
        }`
      }
    />
  )
}

export default AppointmentTypeSelect
