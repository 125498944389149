import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useLoginState from '../../hooks/useLoginState'
import authenticationService from '../../services/authenticationService'

interface Props {
  redirectPath: string
}

const PartnerRedirect: React.FC<React.PropsWithChildren<Props>> = ({ redirectPath }) => {
  const { loginStatus, setApiToken } = useLoginState()
  const history = useHistory()

  useEffect(() => {
    if (!loginStatus || loginStatus !== 'external') {
      setApiToken(`Partner ${process.env.REACT_APP_PARTNER_TOKEN}`)
      authenticationService.setIsPartner(true)
      history.replace(redirectPath)
      return
    }

    // Already logged in as external
    history.replace(redirectPath)
  }, [loginStatus, redirectPath, setApiToken, history])

  return <></>
}

export default PartnerRedirect
