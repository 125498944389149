import styled from '@emotion/styled'
import { Gray700, Info100, Info800 } from '@mehilainen/mds-customer/colors'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentSearchResult, CallbackSearchResult } from '../../../__generated__/api'
import { useNodeSearchParams, useSelectedDate } from '../../../xstate/selectors'
import { useApi } from '../../hooks/useApi'
import api from '../../services/api'
import { isDentalMaintenanceError } from '../../utils/error/utils'
import { scale } from '../../utils/scale'
import { formatDate2 } from '../../utils/text'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { DentalMaintenance } from '../DentalMaintenance/DentalMaintenance'
import { CenteredColumnFlex, ColumnFlex } from '../Layout/Layout'
import Loader from '../Loader/Loader'
import Tiles, { LoadingTiles } from '../Tile/Tiles'
import { Text } from '../Typography/Typography'

import { mapToPractitionerGroups } from './utils'

const NoAppointments = styled.div`
  color: ${Info800};
  background: ${Info100};
  border-radius: 4px;
  padding: ${scale(1)} ${scale(1.5)};
`

const LoadMoreContainer = styled(CenteredColumnFlex)`
  margin-top: ${scale(4)};
`

const Container = styled(ColumnFlex)`
  gap: 24px;
`

interface Props {
  appointments: Array<AppointmentSearchResult | CallbackSearchResult>
  appointmentsPending: boolean
  isUserSelectedDate: boolean
  onPractitionerSelect(practitionerId: number, selectedDate: Dayjs, appointmentId?: number): void
  onSpecialistClick(appointment: AppointmentSearchResult): void
  remoteSupported: boolean
}

const maxNrOfMonthsForward = 6

const PractitionerList: React.FC<React.PropsWithChildren<Props>> = ({
  appointments,
  appointmentsPending,
  isUserSelectedDate,
  onPractitionerSelect,
  onSpecialistClick,
  remoteSupported,
}) => {
  const { t, i18n } = useTranslation()
  const selectedDate = useSelectedDate()
  const searchParams = useNodeSearchParams()
  // get the latest appointment date and search for more practitioners after that date
  const searchStartDate = appointments?.length
    ? dayjs(appointments[appointments.length - 1].time)
        .add(1, 'day')
        .startOf('day')
    : selectedDate
  const [nrOfMonthsForward, setNrOfMonths] = useState<number>(1)
  const loadMoreEnabled = useMemo(
    () => nrOfMonthsForward < maxNrOfMonthsForward,
    [nrOfMonthsForward]
  )
  const {
    data: appointmentsResponse,
    pending: laterAppointmentsPending,
    error: appointmentsError,
  } = useApi(
    api.v1.availablePractitionersSearch,
    {
      ...searchParams,
      startDate: selectedDate.toISOString(),
      nroOfMonths: nrOfMonthsForward,
    },
    []
  )
  const allAppointments = useMemo(() => {
    let res = []
    if (!laterAppointmentsPending && !appointmentsError && appointmentsResponse) {
      res = [...appointments, ...appointmentsResponse]
    } else {
      res = appointments
    }
    return res.filter((a: AppointmentSearchResult) => !a.isResourceList)
  }, [appointments, appointmentsError, appointmentsResponse, laterAppointmentsPending])

  useEffect(() => {
    setNrOfMonths(1)
  }, [searchParams])

  const { first, later } = mapToPractitionerGroups(
    allAppointments,
    selectedDate.isToday() ? searchStartDate : selectedDate
  )

  const displayDentalMaintenance = isDentalMaintenanceError(appointmentsError)

  if (displayDentalMaintenance) {
    return <DentalMaintenance variant="nested" />
  }

  if (appointmentsPending) {
    return <LoadingTiles variant="specialist" />
  }

  return (
    <Container>
      {first.length > 0 ? (
        <>
          <Text as="h2" $weight="Regular" $size={300} $color={Gray700} $uppercase>
            {selectedDate.isToday()
              ? t('component.practitionerList.availableFirst')
              : t('component.practitionerList.availableSelectedDate', {
                  date: formatDate2(selectedDate, t),
                  lng: i18n.language,
                })}
          </Text>
          <Tiles
            appointments={first}
            variant="specialist"
            onAppointmentClick={(appointment) => {
              onPractitionerSelect(
                appointment.specialistId,
                selectedDate,
                appointment.appointmentId
              )
            }}
            onSpecialistClick={(appointment) => {
              onSpecialistClick(appointment)
            }}
            remoteSupported={remoteSupported}
            notifications={[]}
            onDcNotificationClick={() => {}}
          />
        </>
      ) : isUserSelectedDate ? (
        <NoAppointments>{t('component.appointmentList.noAppointments')}</NoAppointments>
      ) : null}
      {later.length > 0 && (
        <>
          <Text as="h2" $weight="Regular" $size={300} $color={Gray700} $uppercase>
            {t('component.practitionerList.availableLater')}
          </Text>
          <Tiles
            appointments={later}
            variant="specialist"
            onAppointmentClick={(appointment) => {
              onPractitionerSelect(
                appointment.specialistId,
                selectedDate,
                appointment.appointmentId
              )
            }}
            onSpecialistClick={(appointment) => {
              onSpecialistClick(appointment)
            }}
            remoteSupported={remoteSupported}
            notifications={[]}
            onDcNotificationClick={() => {}}
          />
        </>
      )}
      <LoadMoreContainer>
        {laterAppointmentsPending ? (
          <Loader />
        ) : loadMoreEnabled ? (
          <DefaultButton
            onClick={() => setNrOfMonths(maxNrOfMonthsForward)}
            variant="outlined"
            data-cy="practitionerList-loadMoreButton"
          >
            {t('common.showMore')}
          </DefaultButton>
        ) : (
          <></>
        )}
      </LoadMoreContainer>
    </Container>
  )
}

export default PractitionerList
