import { createBrowserInspector } from '@statelyai/inspect'
import { createActorContext } from '@xstate/react'
import React from 'react'

import { appMachine } from './machines'

export const AppMachineContext = createActorContext(appMachine, {
  inspect:
    process.env.REACT_APP_ENABLE_XSTATE_INSPECTOR === 'true'
      ? createBrowserInspector().inspect
      : undefined,
})

export const MachinesProvider = ({ children }: { children: React.ReactNode }) => {
  return <AppMachineContext.Provider>{children}</AppMachineContext.Provider>
}
