import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'

import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useUserMarketingConsent } from '../../../common/services/omApiConsent'
import { scale } from '../../../common/utils/scale'

import { ReserveCheckbox } from './FormComponents'
import ReserveFormFieldset from './ReserveFormFieldset'

// This is a hack because some of the provided headings can be all caps
const ForcedCapitalizedText = styled.div`
  text-transform: lowercase;
  word-break: break-word;
  hyphens: auto;

  &:first-letter {
    text-transform: uppercase;
  }
`

interface Props
  extends Pick<
    FormikProps<{
      marketingPermit?: boolean
    }>,
    'values' | 'handleChange' | 'errors'
  > {}

const ReserveMarketingPermission: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  handleChange,
}) => {
  const { header, description, label, loading } = useUserMarketingConsent()

  return loading ? (
    <Skeleton variant="rectangular" height="120px" />
  ) : (
    <ReserveFormFieldset legend={<ForcedCapitalizedText>{header}</ForcedCapitalizedText>}>
      <ColumnFlex $gap={scale(2)}>
        <Text $size={300} $weight="Regular" $height="Medium">
          {description}
        </Text>
        <ReserveCheckbox
          name="marketingPermit"
          checked={values.marketingPermit}
          onChange={handleChange}
          label={label}
        />
      </ColumnFlex>
    </ReserveFormFieldset>
  )
}

export default ReserveMarketingPermission
