import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  DefaultButton,
  DefaultLoadingButton,
} from '../../../common/components/DefaultButton/DefaultButton'
import DatePickerNew from '../../../common/components/InputFieldNew/DatePickerNew'
import ScrollToError from '../../../common/components/ScrollToError/ScrollToError'
import { createOccupationalAccidentFormValidationSchema } from '../utils/form'

import AccidentAdditionalDetailsForm from './AccidentAdditionalDetailsForm'
import { ButtonContainer } from './FormComponents'

export type OccupationalAccidentInformation = {
  incidentDate?: Date
  incidentDescription: string
  employer: string
}

interface Props {
  onSubmit(data?: OccupationalAccidentInformation): void
  onBack(): void
}

const OccupationalAccidentForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onBack,
}) => {
  const { t } = useTranslation()

  const validationSchema = createOccupationalAccidentFormValidationSchema(t)

  const initialValues: OccupationalAccidentInformation = {
    employer: '',
    incidentDescription: '',
  }

  return (
    <Formik<typeof initialValues>
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
        <AccidentAdditionalDetailsForm onContinue={() => onSubmit()} onBack={onBack}>
          <ScrollToError />
          <TextField
            id="employer"
            label={t('component.occupationalAccidentForm.employer')}
            placeholder={t('component.occupationalAccidentForm.employerPlaceholder')}
            helperText={touched.employer && errors.employer}
            error={Boolean(touched.employer && errors.employer)}
            value={values.employer}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            data-cy="occupationalAccidentEmployer"
          />
          <DatePickerNew
            id="incidentDate"
            label={t('component.occupationalAccidentForm.incidentDate')}
            helperText={touched.incidentDate && errors.incidentDate}
            error={Boolean(touched.incidentDate && errors.incidentDate)}
            value={values.incidentDate}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            data-cy="occupationalAccidentIncidentDate"
            max={dayjs().format('YYYY-MM-DD')}
          />
          <TextField
            id="incidentDescription"
            label={t('component.occupationalAccidentForm.incidentDescription')}
            placeholder={t('component.occupationalAccidentForm.incidentDescriptionPlaceholder')}
            helperText={touched.incidentDescription && errors.incidentDescription}
            error={Boolean(touched.incidentDescription && errors.incidentDescription)}
            value={values.incidentDescription}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            data-cy="occupationalAccidentIncidentDescriptionField"
          />
          <ButtonContainer>
            <DefaultLoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
              {t('common.continue')}
            </DefaultLoadingButton>
            <DefaultButton type="button" variant="outlined" onClick={onBack}>
              {t('common.back')}
            </DefaultButton>
          </ButtonContainer>
        </AccidentAdditionalDetailsForm>
      )}
    </Formik>
  )
}

export default OccupationalAccidentForm
