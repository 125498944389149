import styled from '@emotion/styled'
import { White, Gray900, Primary500 } from '@mehilainen/mds-customer/colors'
import { CircleInfo } from '@mehilainen/mds-customer/icons'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { RowFlex, ColumnFlex, CenteredRowFlex } from '../Layout/Layout'
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup'
import { Bold, Text } from '../Typography/Typography'

export type AppointmentDuration =
  | number
  | 'default'
  | `node-${string}`
  | `reason-${string}`
  | 'callback'

export type AppointmentLengthOption = {
  duration: AppointmentDuration
  description: string[]
  serviceName?: string
  serviceId?: number
  available?: boolean
  isCallback?: boolean
  nodeId?: string
  nodeName?: string
  reasonId?: string
  reasonName?: string
}

export const CallbackOption: AppointmentLengthOption = {
  duration: 'callback',
  description: [],
  isCallback: true,
  available: true,
}

interface AppointmentLengthOptionDescriptionProps {
  option: AppointmentLengthOption
  onSelectUnavailableDuration?(duration: AppointmentDuration): void
}

const DisabledLinkContainer = styled(CenteredRowFlex)`
  color: ${Gray900};
  gap: 8px;
  margin-top: 18px;

  svg {
    width: 18px;
    height: 18px;
    color: ${Gray900};
  }
`

const DisabledLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`

const StyledUl = styled.ul`
  list-style: disc inside;
`

const Stamp = styled.div`
  align-self: baseline;
  background: ${Primary500};
  border-radius: 0px 0px 0px 8px;
  padding: ${`${scale(0)} ${scale(1.5)}`};
`

const AppointmentLengthOptionDescriptionContainer = styled(RowFlex)`
  width: 100%;
  justify-content: space-between;
`

const AppointmentLengthOptionHeader: React.FC<
  React.PropsWithChildren<AppointmentLengthOptionDescriptionProps>
> = ({ option }) => {
  const { t } = useTranslation()
  return (
    <AppointmentLengthOptionDescriptionContainer
      data-cy={`appointmentLengthOption-${option.duration}`}
    >
      <Bold>
        {option.isCallback
          ? t('component.appointmentLengthSelect.option.callbackHeading')
          : option.serviceName
          ? t('component.appointmentLengthSelect.option.headingWithService', {
              duration: option.duration,
              service: option.serviceName,
            })
          : option.nodeName
          ? option.nodeName
          : option.reasonName
          ? option.reasonName
          : option.duration === 'default'
          ? t('component.appointmentLengthSelect.option.defaultHeading')
          : t('component.appointmentLengthSelect.option.heading', {
              duration: option.duration,
            })}
      </Bold>
    </AppointmentLengthOptionDescriptionContainer>
  )
}

const AppointmentLengthOptionDescription: React.FC<
  React.PropsWithChildren<AppointmentLengthOptionDescriptionProps>
> = ({ option, onSelectUnavailableDuration }) => {
  const { t } = useTranslation()

  if (option.isCallback) {
    const callbackDescription = t('component.appointmentLengthSelect.option.callbackDescription')
    return (
      <AppointmentLengthOptionDescriptionContainer>
        <ColumnFlex>
          <StyledUl>
            {callbackDescription.split(';').map((description, index) => (
              <li key={`${description}-${index}`}>
                <Text $size={300} $height="Large" $weight="Regular">
                  {description}
                </Text>
              </li>
            ))}
          </StyledUl>
        </ColumnFlex>
      </AppointmentLengthOptionDescriptionContainer>
    )
  }

  return (
    <AppointmentLengthOptionDescriptionContainer>
      <ColumnFlex>
        {option.duration === 'default' ? (
          <Text $size={300} $height="Large" $weight="Regular">
            {t('component.appointmentLengthSelect.option.defaultDescription')}
          </Text>
        ) : option.description.length > 0 ? (
          <StyledUl>
            {option.description.map((description, index) => (
              <li key={`${description}-${index}`}>
                <Text $size={300} $height="Large" $weight="Regular">
                  {description}
                </Text>
              </li>
            ))}
          </StyledUl>
        ) : (
          <></>
        )}
        {option.available === false && onSelectUnavailableDuration && (
          <DisabledLinkContainer>
            <CircleInfo />
            <Text $size={300} $height="Small" $weight="Regular">
              {t('component.appointmentLengthSelect.option.disabled')}
            </Text>
            <DisabledLink
              $size={300}
              $height="Small"
              $weight="Regular"
              onClick={() => onSelectUnavailableDuration(option.duration)}
              role="button"
            >
              {t('component.appointmentLengthSelect.option.disabledLinkText')}
            </DisabledLink>
          </DisabledLinkContainer>
        )}
      </ColumnFlex>
    </AppointmentLengthOptionDescriptionContainer>
  )
}

interface Props {
  options: AppointmentLengthOption[]
  value?: AppointmentDuration | null
  onSelect(option: AppointmentLengthOption): void
  onSelectUnavailableDuration?(duration: number): void
  className?: string
}

const AppointmentLengthSelect: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  value,
  onSelect,
  onSelectUnavailableDuration,
  className,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const selectedValue = useMemo(
    () => options.find((option) => option.duration === value),
    [value, options]
  )

  return (
    <RadioButtonGroup
      options={options}
      value={selectedValue}
      onChange={onSelect}
      renderOptionHeader={(option) => <AppointmentLengthOptionHeader option={option} />}
      renderOptionDescription={(option) => (
        <AppointmentLengthOptionDescription
          option={option}
          onSelectUnavailableDuration={onSelectUnavailableDuration}
        />
      )}
      renderStamp={(option) => {
        if (option.duration === 'default') {
          return (
            <Stamp>
              <Text $size={isMobile ? 200 : 300} $height="Small" $color={White}>
                {t('component.appointmentLengthSelect.option.defaultStamp')}
              </Text>
            </Stamp>
          )
        }
      }}
      isDisabled={(option) => option.available === false}
      className={className}
    />
  )
}

export default styled(AppointmentLengthSelect)``
