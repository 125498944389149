import api from './api'

let cache: Record<string, boolean> | null = null

export const getFeatureFlagValue = async (name: string): Promise<boolean | undefined> => {
  if (!cache) {
    try {
      cache = await api.v1.featureFlags().then((res) => {
        return res.data.reduce<Record<string, boolean>>((prev, cur) => {
          return { ...prev, [cur.name]: cur.value }
        }, {})
      })
    } catch {
      return undefined
    }
  }

  return cache && cache[name] ? cache[name] : undefined
}
