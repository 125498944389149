import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Black,
  Primary100,
  Primary600,
  Primary450,
  Primary500,
  Error600,
} from '@mehilainen/mds-customer/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentSearchResult,
  AppointmentType,
  ContractRuleStatus,
} from '../../../__generated__/api'
import { AppointmentSearchMode } from '../../../state/search/atoms'
import { useIsMobile, useIsTablet } from '../../hooks/useBreakpoint'
import { breakpoint } from '../../utils/breakpoint'
import dayjs from '../../utils/dayjs/dayjs'
import { scale } from '../../utils/scale'
import { formatCallbackTime } from '../../utils/text'
import { PrimaryHover } from '../DefaultButton/DefaultButton'
import { ColumnFlex } from '../Layout/Layout'
import { BackgroundColor, HeadingTextColor } from '../Notification/Notification'
import { Text } from '../Typography/Typography'

import AppointmentLocationDetails from './AppointmentLocationDetails'
import AppointmentSpecialistDetails from './AppointmentSpecialistDetails'
import AppointmentTimeDetails from './AppointmentTimeDetails'
import BaseTile from './BaseTile'

const MobileColumnFlex = styled(ColumnFlex)``
export const ThreeColumnGrid = css`
  display: grid;
  grid-template-columns: 100px 1fr auto;
  gap: 6px;

  @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
    grid-template-columns: 75px 1fr auto;
  }
`

export const CallbackColumnGrid = css`
  display: grid;
  grid-template-columns: 150px 1fr 1fr auto;
  gap: 6px;

  @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
    grid-template-columns: 80px 1fr 1fr auto;
  }
`

export const MobileBaseTile = styled(BaseTile)<{ highPadding: boolean }>`
  align-items: flex-start;
  gap: ${scale(1.5)};
  ${(props) => props.highPadding && `padding-bottom: ${scale(4)};`}
  ${AppointmentTimeDetails} {
    flex-basis: 25%;
  }

  ${MobileColumnFlex} {
    gap: ${scale(1)};
    flex-basis: 80%;
  }
`

export const DesktopBaseTile = styled(BaseTile)<{
  hideSpecialistDetails: boolean
  isCallback: boolean
  highPadding: boolean
}>`
  ${(props) => props.highPadding && `padding-bottom: ${scale(3)};`}
  ${(props) =>
    props.isCallback
      ? CallbackColumnGrid
      : props.hideSpecialistDetails
      ? ThreeColumnGrid
      : css`
          display: grid;
          grid-template-columns: 100px 1fr 1fr auto;
          gap: 6px;

          @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
            grid-template-columns: 80px 1fr 1fr auto;
          }
        `}

  ${AppointmentTimeDetails} {
    @media (min-width: ${breakpoint.sm}px) {
      align-self: center;
    }
  }

  ${AppointmentLocationDetails} {
    align-self: center;
  }

  &:focus-within {
    border-color: ${Primary450};
  }
`

const StyledButton = styled(Button)`
  align-self: flex-start;

  ${PrimaryHover}
`

type OHCStampStatus = ContractRuleStatus | 'primarySpecialist' | undefined
interface OHCStampProps {
  isMobile: boolean
  stampStatus: OHCStampStatus
}

const OHCStamp: React.FC<React.PropsWithChildren<OHCStampProps>> = styled.div<OHCStampProps>`
  border-radius: ${scale(0.75)} 0;
  position: absolute;
  padding: ${(props) => (props.isMobile ? '2px 8px;' : '4px 10px;')};
  line-height: normal;
  bottom: 0;
  right: 0;
  background: ${(props) => {
    switch (props.stampStatus) {
      case ContractRuleStatus.Disallowed:
      case ContractRuleStatus.PaymentCommitmentDisallowed:
        return BackgroundColor['error']
      case ContractRuleStatus.Restriction:
      case ContractRuleStatus.ReferralRequired:
      case ContractRuleStatus.AssessmentRequired:
      case ContractRuleStatus.DentalRestriction:
      case 'company_insurance':
        return BackgroundColor['info']
      case 'primarySpecialist':
        return Primary100
      case ContractRuleStatus.Allowed:
      case ContractRuleStatus.PaymentCommitmentAllowed:
      case ContractRuleStatus.BookingPermitAllowed:
        return 'none'

      default:
        return ''
    }
  }};

  ${Text} {
    color: ${(props) => {
      switch (props.stampStatus) {
        case 'payment_commitment_allowed':
        case 'allowed':
        case 'booking_permit_allowed':
        case 'primarySpecialist':
          return Primary600
        case 'disallowed':
        case 'payment_commitment_disallowed':
          return Error600
        case 'restriction':
        case 'company_insurance':
        case 'referral_required':
        case 'assessment_required':
        case 'dental_restriction':
          return HeadingTextColor['info']
        default:
          return Black
      }
    }};
  }
`

interface Props {
  appointment: AppointmentSearchResult
  hideSpecialistDetails?: 'hide-both' | 'hide-mobile' | 'false'
  onSpecialistClick?(): void
  onClick?(): void
  remoteSupported: boolean
}

const TimeslotTile: React.FC<React.PropsWithChildren<Props>> = ({
  appointment,
  hideSpecialistDetails,
  onSpecialistClick,
  onClick,
  remoteSupported,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const ohcStampStatus = getOHCStampStatus(
    appointment.primarySpecialistRole !== undefined,
    appointment.isCoveredByOHC
  )

  // Construct aria label for the reserve button
  const isCallbackAppointment = appointment.appointmentTypes?.includes(
    AppointmentSearchMode.CALLBACK
  )
  const isRemoteAppointment = !appointment.appointmentTypes?.includes(AppointmentType.Clinic)
  const isAlsoRemoteAppointment =
    appointment.appointmentTypes?.includes(AppointmentType.Clinic) &&
    appointment.appointmentTypes?.length > 1
  const callBackTime = isCallbackAppointment && formatCallbackTime(dayjs(appointment.time))
  const ariaLabelReserveText = t('component.appointmentList.reserveButtonText')
  const ariaLabelSpeacialist = `, ${appointment.specialistName}`
  const ariaLabelDate = `, ${dayjs(appointment.time).format(
    isCallbackAppointment ? 'dd D.M.' : 'dd D.M. H:mm'
  )}`
  const ariaLabelCallbackTime = callBackTime ? ` ${callBackTime}` : ''
  const ariaLabelLocation = !isCallbackAppointment
    ? isRemoteAppointment
      ? `, ${t('component.appointmentLocationDetails.onlyRemote')}`
      : `, ${appointment.locationName}${
          isAlsoRemoteAppointment ? `, ${t('component.appointmentLocationDetails.alsoRemote')}` : ''
        }`
    : ''
  const ariaLabel =
    ariaLabelReserveText +
    ariaLabelSpeacialist +
    ariaLabelDate +
    ariaLabelCallbackTime +
    ariaLabelLocation

  if (isMobile) {
    return (
      <MobileBaseTile
        onClick={onClick}
        onKeyPress={(event) => event.key === 'Enter' && onClick?.()}
        highPadding={Boolean(ohcStampStatus)}
        data-cy="appointmentTile-reserve"
      >
        <AppointmentTimeDetails
          date={dayjs(appointment.time)}
          duration={appointment.hasFlexibleScheduleOptions ? null : appointment.duration}
          variant="S"
          isCallbackAppointment={isCallbackAppointment}
        />
        <MobileColumnFlex>
          {hideSpecialistDetails !== 'hide-both' && hideSpecialistDetails !== 'hide-mobile' && (
            <AppointmentSpecialistDetails
              name={appointment.specialistName}
              title={appointment.specialistTitle}
              isResourceList={appointment.isResourceList}
              image={appointment.image}
              variant="XS"
              allowsNewKelaClients={appointment.specialistAllowsNewKelaClients}
              extraElement={
                <AppointmentLocationDetails
                  locationName={appointment.locationName}
                  locationShortName={appointment.locationShortName}
                  locationAddress={appointment.locationAddress}
                  appointmentTypes={appointment.appointmentTypes as AppointmentType[]}
                  variant="S"
                  remoteSupported={remoteSupported}
                  date={dayjs(appointment.time)}
                />
              }
            />
          )}
        </MobileColumnFlex>
        <AngleRight htmlColor={Primary500} fontSize="large" />
        {ohcStampStatus && (
          <OHCStamp isMobile={isMobile} stampStatus={ohcStampStatus}>
            <Text $size={200}>
              {t(getOHCStampI18nPath(ohcStampStatus, appointment.primarySpecialistRole))}
            </Text>
          </OHCStamp>
        )}
      </MobileBaseTile>
    )
  }

  return (
    <DesktopBaseTile
      onClick={onClick}
      onKeyPress={(event) => event.key === 'Enter' && onClick?.()}
      hideSpecialistDetails={hideSpecialistDetails === 'hide-both'}
      isCallback={isCallbackAppointment}
      highPadding={Boolean(ohcStampStatus)}
    >
      <AppointmentTimeDetails
        date={dayjs(appointment.time)}
        duration={appointment.hasFlexibleScheduleOptions ? null : appointment.duration}
        isCallbackAppointment={isCallbackAppointment}
        variant={isTablet ? 'S' : 'M'}
      />
      {hideSpecialistDetails !== 'hide-both' && (
        <AppointmentSpecialistDetails
          name={appointment.specialistName}
          title={appointment.specialistTitle}
          isResourceList={appointment.isResourceList}
          image={appointment.image}
          onClick={onSpecialistClick}
          allowsNewKelaClients={appointment.specialistAllowsNewKelaClients}
          variant={isTablet ? 'S' : 'L'}
        />
      )}
      <AppointmentLocationDetails
        locationName={appointment.locationName}
        locationShortName={appointment.locationShortName}
        locationAddress={appointment.locationAddress}
        appointmentTypes={appointment.appointmentTypes as AppointmentType[]}
        remoteSupported={remoteSupported}
        variant={isTablet ? 'S' : 'M'}
        date={dayjs(appointment.time)}
      />
      <StyledButton data-cy="appointmentTile-reserve" aria-label={ariaLabel}>
        {t('component.appointmentList.reserveButtonText')}
      </StyledButton>
      {ohcStampStatus && (
        <OHCStamp isMobile={isMobile} stampStatus={ohcStampStatus}>
          <Text $size={200} $weight={ohcStampStatus === 'primarySpecialist' ? 'Medium' : 'Regular'}>
            {t(getOHCStampI18nPath(ohcStampStatus, appointment.primarySpecialistRole))}
          </Text>
        </OHCStamp>
      )}
    </DesktopBaseTile>
  )
}

export default TimeslotTile

const getOHCStampI18nPath = (
  stampStatus: OHCStampStatus,
  role: 'TTL' | 'TTH' | 'TFT' | 'TPSY' | 'YLEL' | 'RATE' | 'SOSSU' | undefined
) => {
  switch (stampStatus) {
    case 'primarySpecialist':
      switch (role) {
        case 'TTL':
          return 'component.appointmentList.ohc.primaryTTLSpecialist'
        case 'TTH':
          return 'component.appointmentList.ohc.primaryTTHSpecialist'
        case 'TFT':
          return 'component.appointmentList.ohc.primaryTFTSpecialist'
        case 'TPSY':
          return 'component.appointmentList.ohc.primaryTPSYSpecialist'
        case 'YLEL':
          return 'component.appointmentList.ohc.primaryYLELSpecialist'
        case 'RATE':
          return 'component.appointmentList.ohc.primaryRATESpecialist'
        case 'SOSSU':
          return 'component.appointmentList.ohc.primarySOSSUSpecialist'
      }
      return ''
    case ContractRuleStatus.Allowed:
    case ContractRuleStatus.PaymentCommitmentAllowed:
    case ContractRuleStatus.BookingPermitAllowed:
      return 'component.appointmentList.ohc.included'
    case ContractRuleStatus.Disallowed:
      return 'component.appointmentList.ohc.notIncluded'
    case ContractRuleStatus.PaymentCommitmentDisallowed:
      return 'component.appointmentList.ohc.notIncluded'
    case ContractRuleStatus.Restriction:
      return 'component.appointmentList.ohc.restricted'
    case ContractRuleStatus.ReferralRequired:
      return 'component.appointmentList.ohc.referral_required'
    case ContractRuleStatus.AssessmentRequired:
      return 'component.appointmentList.ohc.assessment_required'
    case ContractRuleStatus.DentalRestriction:
      return 'component.appointmentList.ohc.dental_restriction'
    case 'company_insurance':
      return 'component.appointmentList.ohc.restricted'
    default:
      return ''
  }
}

const getOHCStampStatus = (isPrimarySpecialist: boolean, isCoveredByOHC?: ContractRuleStatus) => {
  if (isCoveredByOHC === undefined) return undefined
  if (
    [
      ContractRuleStatus.Restriction,
      ContractRuleStatus.ReferralRequired,
      ContractRuleStatus.Disallowed,
      ContractRuleStatus.AssessmentRequired,
      ContractRuleStatus.PaymentCommitmentDisallowed,
      ContractRuleStatus.CompanyInsurance,
      ContractRuleStatus.DentalRestriction,
    ].includes(isCoveredByOHC)
  ) {
    return isCoveredByOHC as OHCStampStatus
  }
  if (isPrimarySpecialist) {
    return 'primarySpecialist'
  }
  if (
    [
      ContractRuleStatus.Allowed,
      ContractRuleStatus.PaymentCommitmentAllowed,
      ContractRuleStatus.BookingPermitAllowed,
    ].includes(isCoveredByOHC)
  ) {
    return isCoveredByOHC as OHCStampStatus
  }

  return undefined
}
