import { Gray600 } from '@mehilainen/mds-customer/colors'
import { styled, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnFlex, VisuallyHidden } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'

import ReserveFormFieldset from './ReserveFormFieldset'

const ARIA_ALERT_THRESHOLD = 20 /* characters left */

const CharacterCount = styled(Text)`
  margin-left: auto;
  white-space: nowrap;
`

interface Props
  extends Pick<
    FormikProps<{
      additionalInformation: string
      discountCode: string
    }>,
    'values' | 'handleChange' | 'handleBlur' | 'errors'
  > {
  additionalInformationMaxLength: number
  isOHC: boolean
}

const ReserveAdditionalInformationForm: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  additionalInformationMaxLength,
  isOHC,
}) => {
  const { t, i18n } = useTranslation()
  const [ariaInfo, setAriaInfo] = useState<string>('')

  useEffect(() => {
    if (
      values.additionalInformation.length ===
      additionalInformationMaxLength - ARIA_ALERT_THRESHOLD
    ) {
      setAriaInfo(t('common.characterLeft', { count: ARIA_ALERT_THRESHOLD }))
    } else if (values.additionalInformation.length === additionalInformationMaxLength) {
      setAriaInfo(t('common.characterFull'))
    } else {
      setAriaInfo('')
    }
  }, [additionalInformationMaxLength, t, values.additionalInformation.length])

  return (
    <ReserveFormFieldset legend={t('component.reserveAdditionalInformationForm.subHeading')}>
      <ColumnFlex>
        <TextField
          id="additionalInformation"
          rows={4}
          multiline
          label={t('component.reserveAdditionalInformationForm.label.additionalInformation')}
          helperText={errors.additionalInformation}
          error={Boolean(errors.additionalInformation)}
          value={values.additionalInformation}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: additionalInformationMaxLength }}
          InputLabelProps={{ sx: { whiteSpace: 'wrap' }, shrink: false }}
          aria-describedby="additionalInformationWordcount"
        />
        <CharacterCount
          id="additionalInformationWordcount"
          $size={300}
          $color={Gray600}
          aria-label={t('common.characterCount', { char: values.additionalInformation.length })}
        >{`${values.additionalInformation.length} / ${additionalInformationMaxLength}`}</CharacterCount>
        <VisuallyHidden aria-live="assertive">{ariaInfo}</VisuallyHidden>
      </ColumnFlex>
      {!isOHC && (
        <TextField
          id="discountCode"
          label={t('component.reserveAdditionalInformationForm.label.discountCode')}
          helperText={
            errors.discountCode
              ? errors.discountCode
              : i18n.exists('component.reserveAdditionalInformationForm.helperText.discountCode')
              ? t('component.reserveAdditionalInformationForm.helperText.discountCode')
              : undefined
          }
          value={values.discountCode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </ReserveFormFieldset>
  )
}

export default ReserveAdditionalInformationForm
