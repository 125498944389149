import styled from '@emotion/styled'
import { Alert, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentReservationInfo,
  BookingInstructions as BookingInstructionsT,
  BookingNavigation,
} from '../../../__generated__/api'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useOHC } from '../../hooks/useOHC'
import { useEhoitoNavigationEvents } from '../../services/omApi'
import { scale } from '../../utils/scale'
import AnchorButton from '../AnchorButton/AnchorButton'
import AppointmentDetails from '../AppointmentDetails/AppointmentDetails'
import BookingInstructions from '../BookingInstructions/BookingInstructions'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { ColumnFlex, MinMaxDiv } from '../Layout/Layout'
import EventButton from '../OMLight/EventButton'
import { Text } from '../Typography/Typography'

import ArrivalInstructionsModal from './ArrivalInstructionsModal'
import { DocratesRedirect, OMRedirect } from './BookingConfirmationRedirect'
import { createCalendarEvent } from './utils'

const Container = styled(ColumnFlex)<{ isMobile?: boolean }>`
  padding: 0 ${(props) => (props.isMobile ? scale(1.5) : scale(0))};
`

const ButtonsContainer = styled(ColumnFlex)`
  margin: ${scale(2)} 0;
  align-items: center;
`

const Heading = styled.h1`
  font-size: 1.938rem;
  line-height: 1.4;
  font-weight: 700;
`

const HeadingContainer = styled(ColumnFlex)`
  align-items: center;
  text-align: center;
  margin: 10px;

  ${Heading} {
    margin-bottom: ${scale(2)};
    margin-top: ${scale(1.75)};
  }

  h1 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`

const BookingInstructionsContainer = styled.div`
  margin-top: ${scale(1)};
`

const DocratesButtonsContainer = styled(ColumnFlex)`
  margin: ${scale(4)} 0;
  align-items: center;
`

const Events: React.FC = () => {
  const { t } = useTranslation()
  const { events } = useEhoitoNavigationEvents()
  const { isOHCSide } = useOHC()

  if (!isOHCSide || !events || events.length === 0) {
    return null
  }

  return (
    <ColumnFlex>
      <Text as="h2" $size={400} $height="Medium" $weight="Medium">
        {t('component.bookingConfirmation.events.title')}
      </Text>
      <EventButton events={events} />
    </ColumnFlex>
  )
}

interface Props {
  uid: string
  appointment: AppointmentReservationInfo
  confirmationEmail: string | null
  confirmationContactByPhone?: boolean
  bookingInstructions?: BookingInstructionsT | null
  onBackToStart(): void
  onPractitionerClicked(practitionerId: number): void
}

const BookingConfirmation: React.VFC<Props> = ({
  uid,
  appointment,
  confirmationEmail,
  confirmationContactByPhone,
  bookingInstructions,
  onBackToStart,
  onPractitionerClicked,
}) => {
  const { t } = useTranslation()
  const [isArrivalInstructionsModalOpen, setIsArrivalInstructionsModalOpen] =
    useState<boolean>(false)
  const isMobile = useIsMobile()
  const headingRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    headingRef.current?.focus()
  }, [])

  return (
    <Container isMobile={isMobile}>
      <MinMaxDiv size="540px">
        <HeadingContainer>
          <Text
            as="h1"
            $size={700}
            $height="Medium"
            $weight="Medium"
            data-cy="bookingConfirmation-heading"
            ref={headingRef}
            tabIndex={-1}
          >
            {appointment.bookingNavigation === BookingNavigation.Docrates
              ? t('component.bookingConfirmation.headingDocrates')
              : appointment.bookingNavigation === BookingNavigation.DocratesAdvancePayment
              ? t('component.bookingConfirmation.headingDocratesAdvancePayment')
              : t('component.bookingConfirmation.heading')}
          </Text>
          {confirmationEmail && confirmationContactByPhone && (
            <Text $size={300} $height="Large" $weight="Regular" data-hj-suppress>
              {t('component.bookingConfirmation.textPhoneAndEmail', { email: confirmationEmail })}
            </Text>
          )}
          {confirmationEmail && !confirmationContactByPhone && (
            <Text $size={300} $height="Large" $weight="Regular" data-hj-suppress>
              {t('component.bookingConfirmation.textOnlyEmail', { email: confirmationEmail })}
            </Text>
          )}
          {appointment.minorDisclosureOfInformationForbidden && (
            <Alert severity="info">
              {t('component.bookingConfirmation.minorDisclosureOfInformationForbidden')}
            </Alert>
          )}
        </HeadingContainer>

        <DocratesRedirect bookingNavigation={appointment.bookingNavigation} />

        <MinMaxDiv size="540px" gap="0">
          <AppointmentDetails
            {...appointment}
            appointmentType={appointment.type}
            displayInstructions
            onSpecialistDetailsClick={() => onPractitionerClicked(appointment.practitionerId)}
            instructionsComponent={
              appointment.type === 'clinic' ? (
                <AnchorButton
                  variant="text"
                  onClick={() => setIsArrivalInstructionsModalOpen(true)}
                >
                  {t('component.bookingConfirmation.arrivalInstructions')}
                </AnchorButton>
              ) : appointment.type === 'video' ? (
                <AnchorButton
                  variant="text"
                  href={t('component.bookingConfirmation.videoInstructionsUrl')}
                  target="_blank"
                >
                  {t('component.bookingConfirmation.moreInfo')}
                </AnchorButton>
              ) : appointment.type === 'phone' ? (
                <AnchorButton
                  variant="text"
                  href={t('component.bookingConfirmation.phoneInstructionsUrl')}
                  target="_blank"
                >
                  {t('component.bookingConfirmation.moreInfo')}
                </AnchorButton>
              ) : undefined
            }
            addToCalendarComponent={
              <AnchorButton variant="text" onClick={() => createCalendarEvent(uid, appointment)}>
                {t('component.bookingConfirmation.addToCalendar')}
              </AnchorButton>
            }
          />
          {bookingInstructions && (
            <BookingInstructionsContainer>
              <BookingInstructions instructions={bookingInstructions} />
            </BookingInstructionsContainer>
          )}
          {appointment.bookingNavigation === BookingNavigation.None && (
            <ButtonsContainer>
              <Events />
              <DefaultButton
                onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  event.preventDefault()
                  onBackToStart()
                }}
              >
                {t('component.bookingConfirmation.bookAnotherTime')}
              </DefaultButton>
            </ButtonsContainer>
          )}
          {appointment.bookingNavigation === BookingNavigation.DocratesAdvancePayment && (
            <DocratesButtonsContainer>
              <Button
                variant="outlined"
                component="a"
                href={t('component.bookingConfirmation.docratesUrl')}
                className="no-external-icon no-link-styles"
              >
                {t('component.bookingConfirmation.bookAnotherTime')}
              </Button>
              <Events />
            </DocratesButtonsContainer>
          )}
        </MinMaxDiv>

        {appointment.bookingNavigation === BookingNavigation.None && <OMRedirect />}
      </MinMaxDiv>

      {appointment.arrivalInfo && (
        <ArrivalInstructionsModal
          arrivalInstructions={appointment.arrivalInfo}
          isOpen={isArrivalInstructionsModalOpen}
          setIsOpen={setIsArrivalInstructionsModalOpen}
        />
      )}
    </Container>
  )
}

export default BookingConfirmation
