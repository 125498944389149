import React, { useMemo } from 'react'

import AppointmentNoteModal, {
  AppointmentNoteType,
} from '../../../domain/search/components/AppointmentNoteModal'
import { useModalEvents } from '../../../xstate/events'
import { useSelectedNoteModalId } from '../../../xstate/selectors'
import { useModals } from '../../hooks/useModals'

const NoteModal: React.FC = () => {
  const { getModal } = useModals()
  const modalId = useSelectedNoteModalId()
  const { closeModal, discardNoteModal } = useModalEvents()

  // TODO(AV3-2734): Implement the correct modal configuration, now only supports custom notes
  const appointmentNoteConfig = useMemo(
    () =>
      modalId
        ? {
            type: AppointmentNoteType.CUSTOM,
            modal: getModal(modalId),
            onContinue: closeModal,
            onClose: closeModal,
            onNode: discardNoteModal,
            onDigitalClinic: () => {},
          }
        : undefined,
    [getModal, modalId, closeModal, discardNoteModal]
  )

  if (!appointmentNoteConfig) {
    return null
  }

  return <AppointmentNoteModal note={appointmentNoteConfig} />
}

export default NoteModal
