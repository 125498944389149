import React, { createContext, ReactNode, useCallback, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { PublicNode } from '../../__generated__/api'
import api from '../services/api'
import { flattenNodes } from '../utils/nodes'

import { useApi } from './useApi'
import { useOHC } from './useOHC'

type NodesContextType = {
  nodes: PublicNode[]
  listedNodes: PublicNode[]
  favoriteNodes: PublicNode[]
  flattenedNodes: PublicNode[]
  getNodeById(id?: string): { node: PublicNode | null; pending: boolean }
  isNodeListed(id?: string): boolean
}

const NodesContext = createContext<NodesContextType>({
  nodes: [],
  listedNodes: [],
  favoriteNodes: [],
  flattenedNodes: [],
  getNodeById: () => ({ node: null, pending: true }),
  isNodeListed: () => false,
})

export const NodesProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { i18n } = useTranslation()
  const { isOHCSide, ohcAllowedStatus } = useOHC()
  const shouldMakeRequest = isOHCSide ? ohcAllowedStatus === 'allowed' : true
  const getOhcNodes = isOHCSide && ohcAllowedStatus === 'allowed'

  const { data: allNodes, pending: allNodesPending } = useApi(
    api.v1.allNodes,
    { lang: i18n.language, isOhc: getOhcNodes },
    []
  )
  const { data: listedNodes } = useApi(
    getOhcNodes ? api.v1.getOhcNodes : api.v1.searchNodes,
    { lang: i18n.language },
    [],
    shouldMakeRequest
  )
  const { data: favoriteNodes } = useApi(
    api.v1.favoriteNodes,
    { menu: getOhcNodes ? 'ohc' : 'private', lang: i18n.language },
    [],
    shouldMakeRequest
  )
  const flattenedNodes = useMemo(() => flattenNodes(allNodes), [allNodes])
  const flattenedListedNodes = useMemo(() => flattenNodes(listedNodes), [listedNodes])

  const getNodeById = useCallback(
    (id?: string) => {
      if (!id) {
        return { node: null, pending: false }
      }

      return {
        node: flattenedNodes.find((node) => node.id === id) ?? null,
        pending: allNodesPending,
      }
    },
    [flattenedNodes, allNodesPending]
  )

  const isNodeListed = useCallback(
    (id?: string) => {
      if (!id) {
        return false
      }

      return flattenedListedNodes.some((node) => node.id === id)
    },
    [flattenedListedNodes]
  )

  const memoized = useMemo(
    () => ({
      nodes: allNodes,
      listedNodes,
      favoriteNodes,
      flattenedNodes,
      getNodeById,
      isNodeListed,
    }),
    [allNodes, favoriteNodes, flattenedNodes, getNodeById, listedNodes, isNodeListed]
  )

  return <NodesContext.Provider value={memoized}>{children}</NodesContext.Provider>
}

export const useNodes = () => {
  return useContext(NodesContext)
}

export const useNode = (
  nodeId: string | undefined
): { node: PublicNode | null; pending: boolean } => {
  const { getNodeById } = useNodes()

  return getNodeById(nodeId)
}
