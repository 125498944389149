import { useTranslation } from 'react-i18next'

import { PractitionerDetails, SupportedLanguage } from '../../__generated__/api'
import api from '../services/api'

import { useApi } from './useApi'

export const usePractitionerDetails = (
  practitionerId: number | undefined | null
): { practitionerDetails: PractitionerDetails | null; pending: boolean } => {
  const { i18n } = useTranslation()
  const { data, pending, error } = useApi(
    api.v1.getPractitionerDetails,
    {
      practitionerId: practitionerId ?? -1,
      lang: i18n.language as SupportedLanguage,
    },
    null,
    Boolean(practitionerId)
  )

  return { practitionerDetails: !data || error ? null : data, pending }
}
