import React from 'react'

import CallbackModal from '../../common/components/CallbackModal/CallbackModal'
import InsuranceModal from '../../common/components/InsuranceSelectModal/InsuranceModal'
import LandingModal from '../../common/components/LandingModal/LandingModal'
import NoteModal from '../../common/components/NoteModal/NoteModal'
import OMLightModal from '../../common/components/OMLight/OMLightModal'
import { useModalMachineState } from '../../xstate/selectors'
import AuthenticationModal from '../reserve/components/AuthenticationModal'
import PractitionerModal from '../search/components/PractitionerModal'
import SearchFilterModal from '../search/components/SearchFilterModal'
import ServiceSelectModal from '../search/components/ServiceSelectModal'

const Modals: React.FC = () => {
  const state = useModalMachineState()

  if (state.matches('LandingModal')) {
    return <LandingModal />
  }

  if (state.matches('CallbackModal')) {
    return <CallbackModal />
  }

  if (state.matches('PractitionerModal')) {
    return <PractitionerModal />
  }

  if (state.matches('ServiceModal')) {
    return <ServiceSelectModal />
  }

  if (state.matches('FilterModal')) {
    return <SearchFilterModal />
  }

  if (state.matches('AuthenticationModal')) {
    return <AuthenticationModal />
  }

  if (state.matches('OMLightModal')) {
    return <OMLightModal />
  }

  if (state.matches('InsuranceModal')) {
    return <InsuranceModal />
  }

  if (state.matches('NoteModal')) {
    return <NoteModal />
  }

  return null
}

export default Modals
