import { eHoitoEvent } from '@mehilainen/omamehilainen-types/lib/ehoito'

import { teliaJWTSessionStorageKey } from '../../state/user/atoms'
import i18n from '../i18n/i18n'

import { getFeatureFlagValue } from './featureFlags'

export const fetchEhoitoEvents = async (): Promise<eHoitoEvent[]> => {
  const jwt = sessionStorage.getItem(teliaJWTSessionStorageKey)

  if (!jwt) {
    return []
  }

  const omlightEnabled = await getFeatureFlagValue('omlightEnabled')

  if (!omlightEnabled) {
    return []
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwt,
    'x-mehilainen-preferred-language': i18n.language,
  }

  const codes = ['TEP_API_IKARYHMA', 'TEP_API_MAARAIKA', 'TEP_API_MUUTTARK', 'TEP_API_TYOHTULO']
  const tepAPIEventIDs = await Promise.all(
    codes.map((code) =>
      fetch(`${process.env.REACT_APP_OMAMEHILAINEN_API}/rest/v1/users/front-page-json`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          type: ['ehoito'],
          code,
        }),
      }).then((res) => res.json())
    )
  )
    .then((results) => {
      const fetchedEvents = results.flat() as eHoitoEvent[]
      const filteredEvents = fetchedEvents.filter((event) => {
        return event.visibility ? !event.visibility.isHidden : true
      })
      return new Set(filteredEvents.map((event) => event.id))
    })
    .catch(() => {
      return new Set()
    })

  const allEvents: eHoitoEvent[] = await fetch(
    `${process.env.REACT_APP_OMAMEHILAINEN_API}/rest/v1/users/front-page-json`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        type: ['ehoito'],
      }),
    }
  )
    .then((res) => res.json())
    .catch(() => [])

  return allEvents.filter((event) => tepAPIEventIDs.has(event.id))
}
