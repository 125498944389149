import styled from '@emotion/styled'
import { Black } from '@mehilainen/mds-customer/colors'
import { Breadcrumbs, Button, ButtonProps } from '@mui/material'
import React from 'react'

import { SearchTarget, SearchTargetValue } from '../../../xstate/types'
import { useNode } from '../../hooks/useNode'
import { usePractitionerDetails } from '../../hooks/usePractitionerDetails'
import { Text } from '../Typography/Typography'

const StyledBreadcrumbs = styled(Breadcrumbs)<{ color: string }>`
  .MuiButton-root:hover {
    background-color: transparent;
  }
  .MuiBreadcrumbs-separator {
    color: ${(props) => props.color};
  }
`

type StyledButtonProps = ButtonProps & { textColor: string; textSize: 'default' | 'small' }

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['textColor', 'textSize'].includes(prop),
})<StyledButtonProps>`
  text-transform: none;
  padding: 0;
`

export type BreadcrumbPath = { name: string | null; searchTarget: SearchTarget }

const BreadcrumbItem: React.FC<{ item: BreadcrumbPath }> = ({ item }) => {
  const node = useNode(
    item.name === null && item.searchTarget.value === SearchTargetValue.Node
      ? item.searchTarget.id
      : undefined
  )
  const practitioner = usePractitionerDetails(
    item.name === null && item.searchTarget.value === SearchTargetValue.Practitioner
      ? item.searchTarget.id
      : undefined
  )
  return item.name ?? node.node?.name ?? practitioner.practitionerDetails?.name ?? ''
}

interface Props {
  path: BreadcrumbPath[]
  onSelect?(path: BreadcrumbPath, index: number): void
  color?: string
  textSize?: 'default' | 'small'
}

const Breadcrumb: React.FC<React.PropsWithChildren<Props>> = ({
  path,
  onSelect,
  color = Black,
  textSize = 'default',
}) => {
  return (
    <StyledBreadcrumbs separator="›" color={color}>
      {path.map((item, index) => (
        <StyledButton
          key={`breadcrumb-item-${index}`}
          onClick={() => onSelect?.(item, index)}
          variant="text"
          aria-current="page"
          textColor={color}
          textSize={textSize}
        >
          <Text $size={300} $height="Medium" $weight="Regular" $color={color}>
            <BreadcrumbItem item={item} />
          </Text>
        </StyledButton>
      ))}
    </StyledBreadcrumbs>
  )
}

export default Breadcrumb
