import styled from '@emotion/styled'
import { White } from '@mehilainen/mds-customer/colors'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../../__generated__/api'
import FilterOptions from '../../../common/components/FilterOptions/FilterOptions'
import Modal from '../../../common/components/Modal/Modal'
import { useApi } from '../../../common/hooks/useApi'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { useNode } from '../../../common/hooks/useNode'
import api from '../../../common/services/api'
import { useModalEvents, useSearchEvents } from '../../../xstate/events'
import { useSearchLocation, useSearchTarget } from '../../../xstate/selectors'
import { SearchTargetValue } from '../../../xstate/types'

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    background: ${White};
  }
`

const SearchFilterModal: React.FC = () => {
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const { closeModal } = useModalEvents()
  const { resetSearchFilters } = useSearchEvents()

  const selectedLocation = useSearchLocation()
  const searchTarget = useSearchTarget()

  const { node: selectedNode } = useNode(
    searchTarget.value === SearchTargetValue.Node ? searchTarget.id : undefined
  )

  const { data: patientAgeGroups } = useApi(
    api.v1.getAgeGroups,
    { lang: i18n.language as SupportedLanguage },
    []
  )

  const { data: languages } = useApi(
    api.v1.getLanguages,
    { lang: i18n.language as SupportedLanguage },
    []
  )

  const { data: durations } = useApi(
    api.v1.getNodeFlexibleSearchOptions,
    { locations: selectedLocation, nodeId: selectedNode ? selectedNode.id : '' },
    [],
    Boolean(selectedNode)
  )

  return (
    <StyledModal
      fullWidth
      maxWidth="md"
      open={true}
      onClose={closeModal}
      primaryButtonText={t('component.filterOptions.save')}
      secondaryButtonText={t('component.filterOptions.reset')}
      onPrimaryButtonClick={closeModal}
      onSecondaryButtonClick={() => resetSearchFilters()}
      stickyFooter={isMobile}
    >
      <FilterOptions
        languages={languages}
        patientAgeGroups={patientAgeGroups}
        durations={durations}
        minimal={searchTarget.value === SearchTargetValue.Practitioner}
        selectedNode={selectedNode}
      />
    </StyledModal>
  )
}

export default SearchFilterModal
