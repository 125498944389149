import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ElectronicHealthRecordSystem,
  NodeServiceType,
  PractitionerDetails,
  PractitionerNode,
} from '../../../__generated__/api'
import {
  AppointmentLengthOption,
  CallbackOption,
} from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import SelectInput from '../../../common/components/SelectInput/SelectInput'
import { useFeatureFlags } from '../../../common/hooks/useFeatureFlags'
import { useNodes } from '../../../common/hooks/useNode'
import { useOHC } from '../../../common/hooks/useOHC'
import {
  AppointmentSearchMode,
  defaultSelectedStandardScheduleOption,
} from '../../../state/search/atoms'
import { useModalEvents } from '../../../xstate/events'
import {
  useAppointmentSearchMode,
  useSelectedFlexibleScheduleOption,
  useSelectedStandardScheduleOption,
} from '../../../xstate/selectors'

interface Props {
  practitionerDetails: PractitionerDetails
}

const SearchDhPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  const { t } = useTranslation()
  const { isOHCSide } = useOHC()
  const selectedStandardScheduleOption = useSelectedStandardScheduleOption()
  const selectedFlexibleScheduleOption = useSelectedFlexibleScheduleOption()
  const appointmentSearchMode = useAppointmentSearchMode()

  const defaultStandardLengthOption: AppointmentLengthOption = useMemo(
    () => ({ duration: defaultSelectedStandardScheduleOption, description: [] }),
    []
  )

  const featureFlags = useFeatureFlags()

  const standardLengthOptions: AppointmentLengthOption[] = useMemo(
    () => [
      defaultStandardLengthOption,
      ...practitionerDetails.appointmentLengthOptions,
      ...practitionerDetails.nodes
        .filter((node: PractitionerNode) =>
          node.serviceType === NodeServiceType.Ohc ? isOHCSide : true
        )
        .map((node: PractitionerNode) => ({
          duration: `node-${node.id}` as `node-${string}`,
          description: [],
          nodeName: node.name,
          nodeId: node.id,
        })),
      ...(practitionerDetails.allowsCallbackReservations && featureFlags?.callbackRequestsEnabled
        ? [CallbackOption]
        : []),
    ],
    [defaultStandardLengthOption, practitionerDetails, featureFlags, isOHCSide]
  )

  const inputValue = useMemo(() => {
    if (appointmentSearchMode === AppointmentSearchMode.CALLBACK) {
      return t('component.searchPractitionerServiceSelect.callbackText')
    }
    const flexibleScheduleText = selectedFlexibleScheduleOption
      ? selectedFlexibleScheduleOption.availableDurations.length === 1
        ? t('component.searchPractitionerServiceSelect.flexibleScheduleText', {
            service: selectedFlexibleScheduleOption.serviceName,
            duration: selectedFlexibleScheduleOption.availableDurations[0],
          })
        : selectedFlexibleScheduleOption.serviceName
      : undefined

    const standardScheduleText =
      selectedStandardScheduleOption === defaultSelectedStandardScheduleOption
        ? t('component.searchPractitionerServiceSelect.defaultDurationText')
        : typeof selectedStandardScheduleOption === 'number'
        ? t('component.searchPractitionerServiceSelect.standardScheduleText', {
            duration: selectedStandardScheduleOption,
          })
        : standardLengthOptions.find((option) => option.duration === selectedStandardScheduleOption)
            ?.nodeName

    return flexibleScheduleText ?? standardScheduleText
  }, [
    t,
    selectedFlexibleScheduleOption,
    selectedStandardScheduleOption,
    appointmentSearchMode,
    standardLengthOptions,
  ])

  const { openPractitionerModal } = useModalEvents()

  return (
    <SelectInput
      label={t('component.searchPractitionerServiceSelect.label')}
      value={inputValue}
      onClick={openPractitionerModal}
      dataCy="searchPractitionerServiceSelect"
    />
  )
}

const SearchAssisDentPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  const { t } = useTranslation()
  const selectedStandardScheduleOption = useSelectedStandardScheduleOption()
  const { isNodeListed } = useNodes()

  const options: AppointmentLengthOption[] = useMemo(() => {
    if (!practitionerDetails.assisDentReasons) {
      return []
    }
    return practitionerDetails.assisDentReasons
      .filter((reason) => {
        if (isNodeListed(reason.nodeId)) {
          return true
        }
        return `reason-${reason.id}` === selectedStandardScheduleOption
      })
      .map((reason) => ({
        duration: `reason-${reason.id}` as `reason-${string}`,
        description: [],
        reasonId: reason.id,
        reasonName: reason.name,
      }))
  }, [isNodeListed, practitionerDetails.assisDentReasons, selectedStandardScheduleOption])

  const value =
    options.find((option) => selectedStandardScheduleOption === `reason-${option.reasonId}`)
      ?.reasonName ?? ''

  const { openPractitionerModal } = useModalEvents()

  return (
    <>
      <SelectInput
        label={t('component.searchPractitionerServiceSelect.label')}
        value={value}
        onClick={openPractitionerModal}
        dataCy="searchPractitionerServiceSelect"
      />
    </>
  )
}

const SearchPractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  if (practitionerDetails.primarySource === ElectronicHealthRecordSystem.Dh) {
    return <SearchDhPractitionerServiceSelect practitionerDetails={practitionerDetails} />
  } else {
    return <SearchAssisDentPractitionerServiceSelect practitionerDetails={practitionerDetails} />
  }
}

export default SearchPractitionerServiceSelect
