import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue, useRecoilState } from 'recoil'

import { ContractUsePeriods, LocationType } from '../../__generated__/api'
import { EMPLOYMENT_START_THRESHOLD_DAYS } from '../../constants'
import { isOHCAtom } from '../../state/common/atoms'
import { defaultOhcSearchNodeId, defaultSelectedSearchNodeId } from '../../state/search/atoms'
import {
  OHCAllowance,
  loginStatusAtom,
  ohcAllowedStatusAtom,
  LoginStatus,
} from '../../state/user/atoms'
import { useSearchEvents } from '../../xstate/events'
import { SearchTargetValue } from '../../xstate/types'
import api from '../services/api'
import ohcService from '../services/ohc'

import { useApi2 } from './useApi'

type OHCContextType = {
  isOHCSide: boolean
  ohcAllowedStatus: OHCAllowance | null
  namedCheckupOfficeCityId: number | null
  setOHC(ohc: boolean, pushHistory?: boolean): void
}

const OHCContext = createContext<OHCContextType>({
  isOHCSide: false,
  ohcAllowedStatus: null,
  namedCheckupOfficeCityId: null,
  setOHC: () => {},
})

interface OHCProviderProps {
  children: React.ReactNode
}

export const OHCProvider: React.FC<OHCProviderProps> = ({ children }) => {
  const [isOHCSide, setIsOHC] = useRecoilState(isOHCAtom)
  const loginStatus = useRecoilValue(loginStatusAtom)
  const [ohcAllowedStatus, setOhcAllowedStatus] = useRecoilState(ohcAllowedStatusAtom)
  const allowedLoginStatus: LoginStatus[] = ['authenticated', 'impersonated']
  const shouldUpdateContractData =
    isOHCSide && allowedLoginStatus.includes(loginStatus) && ohcAllowedStatus === null
  const { setSearchLocation } = useSearchEvents()
  const [prevLocation, setPrevLocation] = useState<string>()

  const { data: contractUsePeriods, error: contractUsePeriodsError } = useApi2(
    api.v1.getContractDates,
    {},
    null,
    shouldUpdateContractData
  )

  const history = useHistory()
  const { setSearchTarget } = useSearchEvents()

  useEffect(() => {
    if (contractUsePeriods !== null && ohcAllowedStatus === null) {
      setOhcAllowedStatus(isUserAllowedToUseOHC(contractUsePeriods))
    } else if (contractUsePeriodsError && ohcAllowedStatus === null) {
      setOhcAllowedStatus('disallowed')
    }
  }, [contractUsePeriods, ohcAllowedStatus, contractUsePeriodsError, setOhcAllowedStatus])

  useEffect(() => {
    ohcService.setOHC(isOHCSide)
    ohcService.setOHCAllowed(ohcAllowedStatus)
    ohcService.setNamedCheckupOfficeCityId(contractUsePeriods?.namedCheckupOfficeCityId ?? null)
  }, [isOHCSide, ohcAllowedStatus, contractUsePeriods])

  useEffect(() => {
    const newLocation = `${LocationType.City}${contractUsePeriods?.namedCheckupOfficeCityId}`
    if (contractUsePeriods?.namedCheckupOfficeCityId && prevLocation !== newLocation) {
      setSearchLocation([newLocation])
      setPrevLocation(newLocation)
    }
  }, [contractUsePeriods?.namedCheckupOfficeCityId, setSearchLocation, prevLocation])

  const setOHC = useCallback(
    (ohc: boolean, pushHistory = true) => {
      setIsOHC(ohc)
      ohcService.setOHC(ohc)
      setSearchTarget({
        id: ohc ? defaultOhcSearchNodeId : defaultSelectedSearchNodeId,
        value: SearchTargetValue.Node,
      })
      if (pushHistory) {
        history.push(ohc ? '/tyoterveys' : '/')
      }
    },
    [setIsOHC, setSearchTarget, history]
  )

  const memoized = useMemo(
    () => ({
      isOHCSide,
      ohcAllowedStatus,
      namedCheckupOfficeCityId: contractUsePeriods?.namedCheckupOfficeCityId ?? null,
      setOHC,
    }),
    [isOHCSide, ohcAllowedStatus, contractUsePeriods?.namedCheckupOfficeCityId, setOHC]
  )

  return <OHCContext.Provider value={memoized}>{children}</OHCContext.Provider>
}

export const useOHC = () => {
  const context = useContext(OHCContext)
  if (!context) {
    throw new Error('useOHC must be used within an OHCProvider')
  }
  return context
}

const isUserAllowedToUseOHC = (contractUsePeriods: ContractUsePeriods | null): OHCAllowance => {
  if (!contractUsePeriods) {
    return 'disallowed'
  }
  const hasDates = (contractUsePeriods.dates.length ?? 0) > 0
  if (!hasDates) {
    return 'disallowed'
  }
  const now = new Date()
  const allowedToUseContractNow = contractUsePeriods.dates.some((date) => {
    const startDate = new Date(date.startDate)
    const endDate = new Date(date.endDate ?? '9999-12-31T23:59:59.999Z')
    if (now >= startDate && now <= endDate) {
      return true
    }
  })

  if (contractUsePeriods.employmentStarts) {
    const thirtyDaysBeforeEmploymentStarts = new Date(contractUsePeriods.employmentStarts)
    thirtyDaysBeforeEmploymentStarts.setDate(
      thirtyDaysBeforeEmploymentStarts.getDate() - EMPLOYMENT_START_THRESHOLD_DAYS
    )
    if (now < thirtyDaysBeforeEmploymentStarts) {
      return 'disallowed'
    }
  }

  if (!allowedToUseContractNow) {
    return 'disallowed'
  }

  if (!contractUsePeriods.isOnlineReservationAllowed) {
    return 'online_reservation_denied'
  }

  return 'allowed'
}
