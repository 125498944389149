import styled from '@emotion/styled'
import { White, Primary500 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { AppointmentInfoResult, AppointmentType, PublicNode } from '../../../__generated__/api'
import { ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import AppointmentSpecialistDetails from '../../../common/components/Tile/AppointmentSpecialistDetails'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { useOHC } from '../../../common/hooks/useOHC'
import * as Analytics from '../../../common/utils/analytics'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { formatCallbackTime, formatDateAndTime2 } from '../../../common/utils/text'
import { AppointmentDetailsBackground } from '../../../constants'
import {
  selectedAppointmentLengthAtom,
  selectedAppointmentTypeAtom,
} from '../../../state/common/atoms'
import { practitionerModalPractitionerId } from '../../../state/practitioner/atoms'

const Container = styled(ColumnFlex)<{ isMobile: boolean }>`
  position: relative;
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'stretch')};
  background: ${AppointmentDetailsBackground};
  border-radius: 8px;
  overflow: hidden;
  padding: 40px 24px 24px 24px;
  gap: 12px;
`

const AppointmentDetailsTitle = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px 6px 12px;
  color: ${White};
  background: ${Primary500};
  border-radius: 0 0 8px 0;
`

const PractitionerContainer = styled(RowFlex)<{ isMobile: boolean }>`
  justify-content: space-between;
  gap: 25px;
  padding-right: 30px;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
`

const AppointmentInfoContainer = styled(ColumnFlex)<{ isMobile: boolean }>`
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'stretch')};
  gap: 4px;
`

interface Props {
  appointmentTitle?: string
  appointmentInfo: AppointmentInfoResult | null
  node: PublicNode | null
  nodePending: boolean
}

const ReserveAppointmentSummary: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentTitle,
  appointmentInfo,
  node,
  nodePending,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { isOHCSide } = useOHC()
  const setPractitionerModalPractitionerId = useSetRecoilState(practitionerModalPractitionerId)
  const selectedAppointmentLength = useRecoilValue(selectedAppointmentLengthAtom)
  const selectedAppointmentType = useRecoilValue(selectedAppointmentTypeAtom)

  Analytics.useTrackVerify({
    appointmentInfo,
    node,
    nodePending,
    event: 'appointment_details',
    selectedAppointmentType,
    isOHC: isOHCSide,
    isDental: appointmentInfo?.appointmentSource === 'AssisDent',
  })

  if (!appointmentInfo) {
    return null
  }

  return (
    <Container isMobile={isMobile}>
      <AppointmentDetailsTitle $size={200}>
        {t('component.typeSelect.reservation')}
      </AppointmentDetailsTitle>
      <PractitionerContainer isMobile={isMobile}>
        <AppointmentInfoContainer isMobile={isMobile}>
          <Text $size={300} $weight="Medium" $height="Medium">
            {selectedAppointmentType === AppointmentType.Callback
              ? formatCallbackTime(dayjs(appointmentInfo.date), true)
              : formatDateAndTime2(dayjs(appointmentInfo.date))}
          </Text>
          <Text $size={300} $weight="Regular" $height="Medium">
            {selectedAppointmentType === AppointmentType.Phone
              ? t('common.phoneAppointment')
              : selectedAppointmentType === AppointmentType.Video
              ? t('common.videoAppointment')
              : selectedAppointmentType === AppointmentType.Callback
              ? t('common.callback')
              : appointmentInfo.locationName}
          </Text>
          <Text $size={300} $weight="Regular" $height="Medium">
            {appointmentTitle}
            {appointmentTitle ? ', ' : ''}
            {selectedAppointmentType !== AppointmentType.Callback && (
              <span data-cy="appointment-duration">
                {!selectedAppointmentLength || selectedAppointmentLength === 'default'
                  ? Math.round(appointmentInfo.duration)
                  : selectedAppointmentLength}{' '}
                min
              </span>
            )}
          </Text>
        </AppointmentInfoContainer>
        <AppointmentSpecialistDetails
          variant="M"
          name={appointmentInfo.practitionerName}
          title={appointmentInfo.practitionerTitle}
          image={appointmentInfo.practitionerImage}
          isResourceList={appointmentInfo.isResourceList}
          onClick={() => setPractitionerModalPractitionerId(appointmentInfo.practitionerId)}
        />
      </PractitionerContainer>
    </Container>
  )
}

export default ReserveAppointmentSummary
