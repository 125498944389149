import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelectedSearchNodeId } from '../../../xstate/selectors'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useNode } from '../../hooks/useNode'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { ColumnFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import { Text } from '../Typography/Typography'

const Container = styled.div``

const ContentContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  margin-top: ${(props) => (props.isMobile ? '36px' : '0px')};
`

const ButtonContainer = styled(ColumnFlex)`
  width: 100%;
  gap: 12px;
  align-items: center;
`

const NodeRedirectModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const selectedNodeId = useSelectedSearchNodeId()
  const { node } = useNode(selectedNodeId)
  const [modal, setModal] = useState<{ modalName: string; url: string } | null>(null)

  const redirect = useCallback(
    (url: string) => {
      const avBaseUrl = new URL(
        process.env.REACT_APP_AV_BASE_URL ?? 'https://ajanvaraus.mehilainen.fi'
      )
      avBaseUrl.pathname = url
      avBaseUrl.searchParams.set('language', i18n.language)
      window.location.replace(avBaseUrl.toString())
    },
    [i18n.language]
  )

  const modalParam = 'modal'
  useEffect(() => {
    if (node?.redirect) {
      const url = new URL(node.redirect, window.location.origin)
      const params = new URLSearchParams(url.search)
      if (params.has(modalParam)) {
        const modalName = params.get(modalParam)!
        setModal({ modalName, url: url.pathname })
      } else {
        redirect(node.redirect)
      }
    }
  }, [node, redirect, setModal])

  if (!validModal(modal)) {
    return null
  }

  const i18nBasePath = `component.nodeRedirectModal.${modal?.modalName}`

  return (
    <Modal open={true} onClose={() => setModal(null)} fullWidth>
      <Container>
        <ContentContainer isMobile={isMobile}>
          <Text $weight="Medium" $size={500}>
            {t(i18nBasePath + '.title')}
          </Text>
          <Text $height="Large" $size={300}>
            {t(i18nBasePath + '.description')}
          </Text>
          <ButtonContainer>
            <DefaultButton size="large" onClick={() => redirect(modal!.url)}>
              {t(i18nBasePath + '.continueButtonText') ||
                t('component.nodeRedirectModal.continueButtonTextCommon')}
            </DefaultButton>
            <DefaultButton size="large" variant="outlined" onClick={() => setModal(null)}>
              {t('common.cancel')}
            </DefaultButton>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </Modal>
  )
}

function validModal(modal: { url: string; modalName: string } | null): boolean {
  if (modal === null) {
    return false
  }
  return Boolean(modal.url) && Boolean(modal.modalName)
}

export default NodeRedirectModal
