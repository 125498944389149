import { OHCAllowance } from '../../state/user/atoms'

class OHCService {
  private ohc: boolean = false
  private ohcAllowed: OHCAllowance | null = null
  private namedCheckupOfficeCityId: number | null = null

  public setOHC(ohc: boolean): void {
    this.ohc = ohc
  }

  public isOHC(): boolean {
    return this.ohc
  }

  public setOHCAllowed(ohcAllowed: OHCAllowance | null): void {
    this.ohcAllowed = ohcAllowed
  }

  public isOHCAllowed(): OHCAllowance | null {
    return this.ohcAllowed
  }

  public setNamedCheckupOfficeCityId(id: number | null): void {
    this.namedCheckupOfficeCityId = id
  }

  public getNamedCheckupOfficeCityId(): number | null {
    return this.namedCheckupOfficeCityId
  }
}

const ohcService = new OHCService()

export default ohcService
