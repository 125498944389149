import styled from '@emotion/styled'
import { Gray900, White } from '@mehilainen/mds-customer/colors'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentType,
  ElectronicHealthRecordSystem,
  PractitionerDetails,
} from '../../../__generated__/api'
import { useApi } from '../../hooks/useApi'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useOHC } from '../../hooks/useOHC'
import api from '../../services/api'
import { breakpoint } from '../../utils/breakpoint'
import { formatDateAndTime, localizeRating } from '../../utils/text'
import Avatar from '../Avatar/Avatar'
import { ColumnFlex, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(RowFlex)<{ isSmall: boolean }>`
  align-items: center;
  gap: 20px;
  margin-bottom: ${(props) => (props.isSmall ? '10px' : '30px')};

  @media (max-width: ${breakpoint.sm}px) {
    flex-direction: column;
  }
`

const StyledAvatar = styled(Avatar)<{ isSmall: boolean }>`
  border-radius: 10px;
  height: ${(props) => (props.isSmall ? '75px' : '128px')};
  width: ${(props) => (props.isSmall ? '50px' : '105px')};
`

const TextContainer = styled(ColumnFlex)`
  gap: 16px;
`

const AppointmentAndEmploymentStatusContainer = styled(ColumnFlex)`
  text-align: end;
`

const MobileCenteredText = styled(ColumnFlex)`
  @media (max-width: ${breakpoint.sm}px) {
    align-items: center;
  }
`

const ActionContainer = styled(ColumnFlex)`
  @media (min-width: ${breakpoint.sm}px) {
    margin-left: auto;
  }
  @media (max-width: ${breakpoint.sm}px) {
    align-items: center;
  }
`

const ReserveButtonContainer = styled.div<{ mobile: boolean }>`
  align-self: ${(props) => (props.mobile ? 'center' : 'end')};
`

const RoundButton = styled(Button)`
  align-self: flex-end;
  border-radius: 100px;
  height: 50px;
  margin-bottom: 17px;
  text-transform: none;
`

interface Props {
  practitioner: PractitionerDetails
  showDisplayAppointmentsButton: boolean
  variant?: 'default' | 'white'
  className?: string
  isSmall?: boolean
  disableRating?: boolean
  onDisplayAppointmentsClick?(): void
  autoFocus?: boolean
}

const PractitionerHeader: React.FC<React.PropsWithChildren<Props>> = ({
  variant = 'default',
  practitioner,
  onDisplayAppointmentsClick,
  showDisplayAppointmentsButton,
  isSmall,
  disableRating,
  className,
  autoFocus,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { isOHCSide } = useOHC()
  const [date] = useState(dayjs())

  const { data: appointmentSearchResult, pending } = useApi(
    api.v1.practitionerAppointmentSearch,
    {
      practitionerId: practitioner.id,
      startDate: date.toISOString(),
      appointmentTypes: [AppointmentType.Video, AppointmentType.Phone, AppointmentType.Clinic],
      isOhc: isOHCSide,
    },
    null,
    showDisplayAppointmentsButton && practitioner.primarySource === ElectronicHealthRecordSystem.Dh
  )

  const color = variant === 'default' ? Gray900 : White
  const employmentTypeText = practitioner.isEntrepreneur
    ? t('component.practitionerDetails.entrepreneurText')
    : null

  const headerRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (autoFocus) {
      headerRef.current?.focus()
    }
  }, [autoFocus])

  return (
    <Container isSmall={Boolean(isSmall)} className={className}>
      <StyledAvatar
        image={practitioner.image}
        rating={disableRating ? undefined : practitioner.rating}
        isSmall={Boolean(isSmall)}
        ratingFontSize={isSmall ? '11px' : '15px'}
        ratingPadding={isSmall ? '4px' : '8px'}
        style={{ transition: '300ms' }}
      />
      <TextContainer>
        <MobileCenteredText>
          <Text
            as="h1"
            $size={isSmall ? 400 : 600}
            $weight={isSmall ? 'Bold' : 'Regular'}
            $height="Medium"
            $color={color}
            ref={headerRef}
            tabIndex={-1}
          >
            {practitioner.name}
          </Text>
          <Text $size={300} $weight="Regular" $height="Large" $color={color}>
            {practitioner.title}
          </Text>
        </MobileCenteredText>
        {!disableRating && localizeRating(practitioner.rating) && !isMobile && (
          <ColumnFlex>
            <Text $size={200} $weight="Regular" $height="Large" $color={color}>
              {t('component.practitionerHeader.ratingDetails')}
            </Text>
          </ColumnFlex>
        )}
      </TextContainer>
      {practitioner.primarySource === ElectronicHealthRecordSystem.Assisdent &&
        showDisplayAppointmentsButton && (
          <ActionContainer>
            <ReserveButtonContainer mobile={isMobile}>
              <RoundButton
                type="button"
                variant={variant === 'white' ? 'outlined' : undefined}
                onClick={onDisplayAppointmentsClick}
              >
                {t('component.practitionerHeader.reserveButton')}
              </RoundButton>
            </ReserveButtonContainer>
          </ActionContainer>
        )}
      {practitioner.primarySource === ElectronicHealthRecordSystem.Dh &&
        !pending &&
        appointmentSearchResult &&
        appointmentSearchResult.appointments.length > 0 &&
        showDisplayAppointmentsButton &&
        onDisplayAppointmentsClick && (
          <ActionContainer>
            <ReserveButtonContainer mobile={isMobile}>
              <RoundButton
                type="button"
                variant={variant === 'white' ? 'outlined' : undefined}
                onClick={onDisplayAppointmentsClick}
              >
                {t('component.practitionerHeader.reserveButton')}
              </RoundButton>
            </ReserveButtonContainer>
            <AppointmentAndEmploymentStatusContainer>
              <Text $size={200} $weight="Regular" $height="Large" $color={color}>
                {t('component.practitionerHeader.availableDate', {
                  date: formatDateAndTime(
                    dayjs(appointmentSearchResult.appointments[0].time),
                    t
                  ).toLowerCase(),
                })}
              </Text>
              <Text $size={200} $weight="Regular" $height="Large" $color={color}>
                {employmentTypeText}
              </Text>
            </AppointmentAndEmploymentStatusContainer>
          </ActionContainer>
        )}
    </Container>
  )
}

export default PractitionerHeader
