import equal from 'fast-deep-equal'
import React from 'react'

import { LocationResult, PractitionerDetails, PublicNode } from '../../../__generated__/api'
import LocationSelect from '../../../common/components/LocationSelect/LocationSelect'
import PractitionerLocationSelect from '../../../common/components/LocationSelect/PractitionerLocationSelect'
import { allLocationsSelection } from '../../../state/search/atoms'
import { useSearchEvents } from '../../../xstate/events'
import { useSearchLocation } from '../../../xstate/selectors'

interface Props {
  locations: LocationResult[]
  selectedPractitioner?: PractitionerDetails
  selectedNode: PublicNode | null
}

const SearchLocationSelect: React.FC<React.PropsWithChildren<Props>> = ({
  locations,
  selectedPractitioner,
  selectedNode,
}) => {
  const { setSearchLocation } = useSearchEvents()
  const selectedSearchLocation = useSearchLocation()

  if (selectedPractitioner) {
    return (
      <PractitionerLocationSelect
        practitioner={selectedPractitioner}
        allLocations={locations}
        value={selectedSearchLocation.length === 1 ? selectedSearchLocation[0] : undefined}
        onSelect={(location) => (location ? setSearchLocation([location]) : setSearchLocation([]))}
      />
    )
  }

  const filteredLocations =
    selectedNode && !selectedNode.video && !selectedNode.phone
      ? locations.filter((location) => !equal([location.uniqueId], allLocationsSelection))
      : locations

  return (
    <LocationSelect
      locations={filteredLocations}
      value={selectedSearchLocation}
      onSelect={setSearchLocation}
      selectedNode={selectedNode}
    />
  )
}

export default SearchLocationSelect
