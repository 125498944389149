import {
  AppointmentType,
  LanguageResult,
  PatientAgeGroup,
  TimeRange,
} from '../../../__generated__/api'

export enum Gender {
  UNSPECIFIED = 'unspecified',
  FEMALE = 'female',
  MALE = 'male',
}

export type FilterOptions = {
  appointmentTypes: AppointmentType[]
  timeRanges: TimeRange[]
  gender: Gender
  language: LanguageResult | null
  patientAgeGroup: PatientAgeGroup | null
  durations: number[]
}
