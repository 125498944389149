import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'

import { ColumnFlex, MinMaxDiv } from '../Layout/Layout'

const Heading = styled.h1`
  color: black;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.4;
`

const Container = styled(ColumnFlex)`
  background: white;
  border-radius: 12px;
  margin: 10px;
  padding: 32px 16px;
  align-items: center;
  text-align: center;

  > .MuiSvgIcon-root {
    color: black;
    margin-bottom: 16px;
    width: 40px;
    height: 40px;
  }

  ${Heading} {
    margin-bottom: 24px;
  }
`

interface Props {
  icon?: JSX.Element
  heading?: string
  className?: string
  autoFocus?: boolean
}

const Card: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  heading,
  className,
  autoFocus,
  children,
}) => {
  const headingRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    if (autoFocus && headingRef.current) {
      headingRef.current.focus()
    }
  }, [autoFocus])

  return (
    <MinMaxDiv size="750px">
      <Container className={className}>
        {icon}
        {heading && (
          <Heading tabIndex={-1} ref={headingRef}>
            {heading}
          </Heading>
        )}
        {children}
      </Container>
    </MinMaxDiv>
  )
}

export default Card
