import styled from '@emotion/styled'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import equal from 'fast-deep-equal'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentType, PublicNode } from '../../../__generated__/api'
import { allLocationsSelection } from '../../../state/search/atoms'
import { useSearchLocation } from '../../../xstate/selectors'

import Fieldset from './Fieldset'

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`

interface Props {
  name: string
  value: AppointmentType[]
  handleChange: (event: React.ChangeEvent<{ value: AppointmentType[] }>) => void
  selectedNode: PublicNode | null
}

const AppointmentTypeSelect: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  value,
  handleChange,
  selectedNode,
}) => {
  const { t } = useTranslation()
  const selectedLocation = useSearchLocation()

  return (
    <Fieldset
      legend={t('component.filterOptions.appointmentTypeSelect.legend')}
      tooltip={t('component.filterOptions.appointmentTypeSelect.tooltip')}
      tooltipLink={{
        link: t('component.filterOptions.appointmentTypeSelect.tooltipLink'),
        text: t('common.readMore'),
      }}
      expanded={true}
    >
      <StyledFormGroup>
        {[AppointmentType.Clinic, AppointmentType.Video, AppointmentType.Phone].map(
          (appointmentType) => (
            <FormControlLabel
              key={`appointmentTypeSelect-appointmentType-${appointmentType}`}
              control={
                <Checkbox
                  id={`appointmentTypeSelect-appointmentType-${appointmentType}-checkbox`}
                  checked={value.includes(appointmentType)}
                />
              }
              disabled={
                // Remote appointment location selected
                (appointmentType === AppointmentType.Clinic &&
                  equal(selectedLocation, allLocationsSelection)) ||
                // Node doesn't allow video appointments
                (AppointmentType.Video === appointmentType &&
                  selectedNode !== null &&
                  !selectedNode.video) ||
                // Node doesn't allow phone appointments
                (AppointmentType.Phone === appointmentType &&
                  selectedNode !== null &&
                  !selectedNode.phone)
              }
              label={t(`component.filterOptions.appointmentTypeSelect.label.${appointmentType}`)}
              onChange={() =>
                handleChange({
                  target: {
                    name,
                    value: !value.includes(appointmentType)
                      ? [...value, appointmentType]
                      : value.filter((type) => type !== appointmentType),
                  },
                } as unknown as React.ChangeEvent<{ value: AppointmentType[] }>)
              }
            />
          )
        )}
      </StyledFormGroup>
    </Fieldset>
  )
}

export default AppointmentTypeSelect
