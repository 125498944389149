import styled from '@emotion/styled'
import { Gray400, Gray900, Primary500 } from '@mehilainen/mds-customer/colors'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import React, { useState, useMemo, useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  InsuranceCompany,
  InsurancePayerOption,
  InsurancePaymentOptionListing,
} from '../../../__generated__/api'
import { selectedInsuranceAtom, selectedInsurancePayerAtom } from '../../../state/common/atoms'
import { useModalEvents, useSearchEvents } from '../../../xstate/events'
import { useIsUserSelectedNode } from '../../../xstate/selectors'
import { SearchTargetValue } from '../../../xstate/types'
import { useInsurances } from '../../hooks/useInsurance'
import useLoginState from '../../hooks/useLoginState'
import useTranslation from '../../hooks/useTranslation'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import { Text } from '../Typography/Typography'

import FenniaLogo from './images/Fennia.svg?react'
import IfLogo from './images/If.svg?react'
import LahiTapiolaLogo from './images/LahiTapiola.svg?react'
import PohjantahtiLogo from './images/Pohjantahti.svg?react'
import PohjolaLogo from './images/Pohjola.svg?react'

const Container = styled(ColumnFlex)`
  gap: ${scale(2)};
`

const Heading = styled(Text)`
  margin-bottom: ${scale(1.5)};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${scale(1)};
`

const StyledLabel = styled(FormControlLabel)`
  border: ${(props) => (props.checked ? `1px solid ${Primary500}` : `1px solid ${Gray400}`)};
  border-radius: 8px;
  padding: 12px 16px;
  margin: 4px 0;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    border-color: ${Primary500};
  }

  .MuiRadio-root {
    align-self: flex-start;
  }
`

interface InsuranceLogoProps {
  insuranceId: string
}

const InsuranceLogo: React.FC<React.PropsWithChildren<InsuranceLogoProps>> = ({ insuranceId }) => {
  const logoProps = {
    width: '18px',
    height: '18px',
    style: { marginRight: '16px' },
  }

  switch (Number(insuranceId)) {
    case 56:
      return <LahiTapiolaLogo {...logoProps} />
    case 804:
      return <PohjolaLogo {...logoProps} />
    case 38:
      return <FenniaLogo {...logoProps} />
    case 429:
      return <IfLogo {...logoProps} />
    case 527:
      return <PohjantahtiLogo {...logoProps} />
    default:
      return null
  }
}

interface InsuranceInstructionsProps {
  insuranceId: number
  insurances: InsuranceCompany[]
}

const InsuranceInstructions: React.FC<React.PropsWithChildren<InsuranceInstructionsProps>> = ({
  insuranceId,
  insurances,
}) => {
  const { t } = useTranslation()
  const insuranceDetails = useMemo(
    () => insurances.find((insurance) => insurance.id === insuranceId),
    [insuranceId, insurances]
  )

  if (!insuranceDetails) {
    return null
  }

  if (
    insuranceId === 56 &&
    insurances.some(
      (i) =>
        i.id === 56 &&
        i.insurancePayerOptions.some((option) => option.additionalInfoForm === 'lahitapiola')
    )
  ) {
    return (
      <Text $center $size={300} $color={Gray900}>
        {t('component.insuranceInstructions.lahiTapiola.text')}
      </Text>
    )
  }

  if (
    insuranceId === 804 &&
    insurances.some(
      (i) =>
        i.id === 804 &&
        i.insurancePayerOptions.some((option) => option.additionalInfoForm === 'pohjola')
    )
  ) {
    return (
      <Text $center $size={300} $color={Gray900}>
        {t('component.insuranceInstructions.pohjola.text')}
      </Text>
    )
  }

  if (insuranceDetails.bookingInstructions) {
    return (
      <Text $center $size={300} $color={Gray900}>
        {insuranceDetails.bookingInstructions}
      </Text>
    )
  }

  return null
}

interface InsurancePaymentOptionListingSelectProps {
  value: InsurancePaymentOptionListing
  selectedInsurance: number
  paymentOptionListings: InsurancePaymentOptionListing[]
  onChange(option: InsurancePaymentOptionListing): void
}

const InsurancePaymentOptionListingSelect: React.FC<
  React.PropsWithChildren<InsurancePaymentOptionListingSelectProps>
> = ({ value, selectedInsurance, paymentOptionListings, onChange }) => {
  const { t, i18n } = useTranslation()

  return (
    <FormControl>
      <FormLabel id="insurancePaymentOptionListingSelect-label">
        {t('component.insuranceSelectModal.payerOptionSelectLabel')}
      </FormLabel>
      <RadioGroup
        aria-labelledby="insurancePaymentOptionListingSelect-label"
        name="insurancePaymentOptionListingSelect-radioGroup"
        value={value}
        onChange={(event) => onChange(event.target.value as InsurancePaymentOptionListing)}
      >
        {paymentOptionListings.map((listing) => (
          <StyledLabel
            key={listing}
            value={listing}
            checked={listing === value}
            control={<Radio color="primary" />}
            label={
              i18n.exists(
                `component.insuranceSelectModal.paymentOptionListing.${selectedInsurance}.${listing}`
              )
                ? t(
                    `component.insuranceSelectModal.paymentOptionListing.${selectedInsurance}.${listing}`
                  )
                : t(`component.insuranceSelectModal.paymentOptionListing.${listing}`)
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

interface InsurancePaymentOptionSelectProps {
  value: string
  options: InsurancePayerOption[]
  onChange(option: string): void
}

const InsurancePaymentOptionSelect: React.FC<InsurancePaymentOptionSelectProps> = ({
  value,
  options,
  onChange,
}) => {
  const { t } = useTranslation()

  return (
    <FormControl>
      <FormLabel id="insurancePaymentOptionSelect-label">
        {t('component.insuranceSelectModal.paymentOptionSelectLabel')}
      </FormLabel>
      <RadioGroup
        aria-labelledby="insurancePaymentOptionSelect-label"
        name="insurancePaymentOptionSelect-radioGroup"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((option) => (
          <StyledLabel
            key={option.id}
            value={option.id}
            control={<Radio color="primary" />}
            label={
              <ColumnFlex $gap={scale(1)}>
                <Text>{option.name}</Text>
                {option.description && (
                  <Text $size={300} $color={Gray900}>
                    {option.description}
                  </Text>
                )}
              </ColumnFlex>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const InsuranceModal: React.FC = () => {
  const { t } = useTranslation()
  const { insurances } = useInsurances()
  const { closeModal } = useModalEvents()
  const { setSearchTarget } = useSearchEvents()
  const { loginStatus } = useLoginState()
  const isUserSelectedNode = useIsUserSelectedNode()

  const paymentOptionListings = useMemo(
    () =>
      loginStatus === 'external' || loginStatus === 'impersonated'
        ? [InsurancePaymentOptionListing.Extranet, InsurancePaymentOptionListing.ExtranetEmployer]
        : [InsurancePaymentOptionListing.Personal, InsurancePaymentOptionListing.Employer],
    [loginStatus]
  )

  const options = useMemo(
    () => [
      ...insurances
        .filter(
          (insurance) =>
            insurance.insurancePayerOptions.filter((option) =>
              paymentOptionListings.includes(option.listing)
            ).length > 0
        )
        .map((insurance) => ({
          value: insurance.id.toString(),
          label: insurance.name,
        })),
    ],
    [insurances, paymentOptionListings]
  )

  const [selectedInsurance, setSelectedInsurance] = useRecoilState(selectedInsuranceAtom)
  const setSelectedInsurancePayer = useSetRecoilState(selectedInsurancePayerAtom)
  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number | 'none'>(
    selectedInsurance ?? 'none'
  )

  const isInsuranceSelected = typeof selectedInsuranceId === 'number'

  const [selectedListing, setSelectedListing] = useState<InsurancePaymentOptionListing>(
    paymentOptionListings[0]
  )

  const paymentOptions = useMemo(
    () =>
      selectedInsuranceId
        ? insurances
            .find((insurance) => insurance.id === selectedInsuranceId)
            ?.insurancePayerOptions.filter((option) => option.listing === selectedListing) ?? []
        : [],
    [selectedInsuranceId, selectedListing, insurances]
  )

  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<string>()

  useEffect(() => {
    setSelectedPaymentOptionId(undefined)
  }, [selectedInsuranceId])

  return (
    <ResponsiveModal open={true} fullWidth onClose={closeModal}>
      <Container>
        {!selectedPaymentOptionId && (
          <>
            <div>
              <Heading as="h1" $size={500} $weight={'Medium'} $color={Gray900} $height="Medium">
                {t('component.insuranceSelectModal.header')}
              </Heading>
              <Text $color={Gray900} $size={300}>
                {t('component.insuranceSelectModal.description')}
              </Text>
            </div>
            <div>
              <TextField
                name="insurance-select"
                label={t('component.insuranceSelectModal.selectLabel')}
                select
                value={selectedInsuranceId.toString()}
                fullWidth
                onChange={(event) => {
                  const selectedValue = event.target.value
                  const selectedNumberValue = Number(selectedValue)
                  setSelectedInsuranceId(
                    !isNaN(selectedNumberValue) ? selectedNumberValue : (selectedValue as 'none')
                  )
                }}
                data-cy="insuranceSelectModal-select"
              >
                <MenuItem value="none">{t('common.select')}</MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <InsuranceLogo insuranceId={option.value} />
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {isInsuranceSelected && (
              <>
                <InsurancePaymentOptionListingSelect
                  value={selectedListing}
                  onChange={setSelectedListing}
                  selectedInsurance={selectedInsuranceId}
                  paymentOptionListings={paymentOptionListings}
                />
                <InsuranceInstructions insuranceId={selectedInsuranceId} insurances={insurances} />
              </>
            )}
            <ButtonsContainer>
              <div>
                <Button
                  size="large"
                  onClick={() => {
                    setSelectedPaymentOptionId(paymentOptions[0].id)
                  }}
                  disabled={selectedInsuranceId === 'none' || paymentOptions.length === 0}
                  data-cy="insuranceSelectModal-continue"
                >
                  {t('common.chooseAndContinue')}
                </Button>
              </div>
              <div>
                <Button onClick={closeModal} variant="text">
                  {t('component.insuranceSelectModal.back')}
                </Button>
              </div>
            </ButtonsContainer>
          </>
        )}
        {isInsuranceSelected && selectedPaymentOptionId && (
          <>
            <Heading as="h1" $size={500} $weight={'Medium'} $color={Gray900} $height="Medium">
              {t(`component.insuranceSelectModal.paymentOptionHeader.${selectedListing}`)}
            </Heading>
            <InsurancePaymentOptionSelect
              value={selectedPaymentOptionId}
              onChange={setSelectedPaymentOptionId}
              options={paymentOptions}
            />
            <ButtonsContainer>
              <div>
                <Button
                  size="large"
                  onClick={() => {
                    const selectedPaymentOption = paymentOptions.find(
                      (option) => option.id === selectedPaymentOptionId
                    )
                    if (selectedPaymentOption?.redirectNodeId && !isUserSelectedNode) {
                      setSearchTarget({
                        value: SearchTargetValue.Node,
                        id: selectedPaymentOption.redirectNodeId,
                      })
                    }
                    setSelectedInsurance(selectedInsuranceId)
                    setSelectedInsurancePayer(selectedPaymentOptionId)
                    closeModal()
                  }}
                >
                  {t('common.continueToBooking')}
                </Button>
              </div>
              <div>
                <Button onClick={() => setSelectedPaymentOptionId(undefined)} variant="text">
                  {t('component.insuranceSelectModal.back')}
                </Button>
              </div>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </ResponsiveModal>
  )
}

export default InsuranceModal
