import { Event, ListItem, UserButton } from '@mehilainen/mds-customer'
import { radius, shadow } from '@mehilainen/mds-tokens'
import { spacing } from '@mehilainen/mds-tokens/spacing'
import { Button, Divider, Menu, menuClasses, MenuProps, paperClasses, styled } from '@mui/material'
import React, { ReactNode } from 'react'

type EventComponentProps = React.ComponentProps<typeof Event>
type ListItemComponentProps = React.ComponentProps<typeof ListItem>
type ButtonComponentProps = React.ComponentProps<typeof Button>

type UserMenuProps = {
  ButtonProps?: ButtonComponentProps
  topComponent?: React.ReactNode
  events?: EventComponentProps[]
  links?: ListItemComponentProps[]
  name?: ReactNode
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    elevation={0}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    {...props}
  />
))(() => ({
  [`& .${paperClasses.root}`]: {
    padding: spacing[550].px,
    borderRadius: radius[300].px,
    marginTop: spacing[350].px,
    boxShadow: shadow[400],
    maxWidth: '390px',
    [`& .${menuClasses.list}`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

/**
 * UserMenu component
 * @param ButtonProps - props for `button`
 * @param events -  props for `Event` elements (props objects in an array)
 * @param links - props for `LinkItem` elements (props objects in an array)
 * @param userName - Name that should be in the UserButton
 */
export const UserMenu: React.VFC<UserMenuProps> = (props: UserMenuProps) => {
  const { ButtonProps, events, links, name, topComponent } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const hasButton = ButtonProps
  const hasEvents = !!events && events.length > 0
  const lastEventIndex = hasEvents && events.length - 1
  const hasLinks = !!links && links.length > 0

  return (
    <>
      <UserButton
        aria-controls={open ? 'mds-user-menu-dropdown' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        id="mds-user-menu-buttom"
        name={name}
        onClick={handleClick}
        open={open}
      />
      <StyledMenu
        anchorEl={anchorEl}
        id="mds-user-menu-dropdown"
        MenuListProps={{
          'aria-labelledby': 'mds-user-menu-buttom',
          component: 'div',
          disablePadding: true,
        }}
        onClose={handleClose}
        open={open}
        variant="menu"
        disablePortal
      >
        {topComponent && (
          <>
            {topComponent}
            <Divider sx={{ border: 0, marginTop: spacing[500].px }} />
          </>
        )}
        {hasEvents &&
          events.map((eventProps, index) => {
            const isLastEvent = lastEventIndex === index
            return (
              <Event
                key={`event-item-${index}`}
                role="menuitem"
                tabIndex={-1}
                {...eventProps}
                onClick={(clickEvent) => {
                  eventProps.onClick?.(clickEvent)
                  handleClose()
                }}
                sx={{
                  ...eventProps.sx,
                  marginBottom: isLastEvent ? 0 : spacing[300].px,
                }}
              />
            )
          })}
        {hasEvents && hasLinks && <Divider sx={{ border: 0, marginTop: spacing[500].px }} />}
        {hasLinks &&
          links.map((linkProps, index) => {
            const { children, ...otherProps } = linkProps
            return (
              <ListItem key={`event-item-${index}`} role="menuitem" tabIndex={-1} {...otherProps}>
                {children}
              </ListItem>
            )
          })}
        {hasButton && (hasEvents || hasLinks) && (
          <Divider sx={{ border: 0, marginTop: spacing[500].px }} />
        )}
        {hasButton && <Button role="menuitem" tabIndex={-1} {...ButtonProps} />}
      </StyledMenu>
    </>
  )
}
