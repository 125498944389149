import styled from '@emotion/styled'
import { Gray100, Gray300 } from '@mehilainen/mds-customer/colors'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BookingNavigation } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import { stripTagsAllowBasicHtml } from '../../utils/xss'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  border-radius: ${scale(1)};
  border: 1px solid ${Gray300};
  background: ${Gray100};
  padding: ${scale(3)};
  text-align: center;
`

const DocratesText = styled(Text)`
  display: flex;
  flex-direction: column;
  gap: ${scale(2)};
  text-align: left;
  > ul,
  ol {
    list-style: decimal;
    padding-left: ${scale(2)};
  }
`

export const OMRedirect: React.FC = () => {
  const { t } = useTranslation()
  return (
    <BookingConfirmationRedirect
      title={t('component.omRedirect.title')}
      buttonText={t('component.omRedirect.buttonText')}
      href={t('component.omRedirect.href')}
    >
      <Text $size={300} $height="Large" $weight="Regular">
        {t('component.omRedirect.text')}
      </Text>
      <Button
        className="no-external-icon no-link-styles"
        component="a"
        href={t('component.omRedirect.additionalInfoHref')}
        variant="text"
      >
        {t('component.omRedirect.additionalInfoButtonText')}
      </Button>
    </BookingConfirmationRedirect>
  )
}

interface DocratesRedirectProps {
  bookingNavigation: BookingNavigation
}

export const DocratesRedirect: React.FC<DocratesRedirectProps> = ({ bookingNavigation }) => {
  const { t } = useTranslation()

  if (
    !(
      bookingNavigation === BookingNavigation.Docrates ||
      bookingNavigation === BookingNavigation.DocratesAdvancePayment
    )
  ) {
    return null
  }

  return (
    <BookingConfirmationRedirect
      title={t(`component.docratesRedirect.${bookingNavigation}.title`)}
      buttonText={t(`component.docratesRedirect.${bookingNavigation}.buttonText`)}
      href={t(`component.docratesRedirect.${bookingNavigation}.href`)}
    >
      <DocratesText
        $size={300}
        $height="Large"
        $weight="Regular"
        dangerouslySetInnerHTML={{
          __html: stripTagsAllowBasicHtml(
            t(`component.docratesRedirect.${bookingNavigation}.text`)
          ),
        }}
      />
    </BookingConfirmationRedirect>
  )
}

interface Props {
  title: string
  buttonText: string
  href: string
}

const BookingConfirmationRedirect: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  buttonText,
  href,
  children,
}) => {
  return (
    <Container $gap={scale(2)}>
      <Text $center $size={450} $height="Medium" $weight="Medium">
        {title}
      </Text>
      {children}
      <div>
        <Button className="no-external-icon no-link-styles" component="a" href={href}>
          {buttonText}
        </Button>
      </div>
    </Container>
  )
}

export default BookingConfirmationRedirect
