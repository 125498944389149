import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'

import { AuthorizedPerson, User, UserWithDelegateRole } from '../../../__generated__/api'
import useLoginState from '../../../common/hooks/useLoginState'
import api from '../../../common/services/api'

type UserState = {
  user: UserWithDelegateRole | undefined
  authorizations: AuthorizedPerson[] | undefined
  pending: boolean
}

type UserContextType = {
  userState: UserState
  fetchAuthorizedUser(omUid: number): Promise<User | undefined>
}

const UserContext = createContext<UserContextType>({
  userState: { user: undefined, authorizations: undefined, pending: false },
  fetchAuthorizedUser: () => Promise.resolve(undefined),
})

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { loginStatus } = useLoginState()
  const [userState, setUserState] = useState<UserState>({
    user: undefined,
    authorizations: undefined,
    pending: true,
  })

  const setPending = useCallback((isPending: boolean) => {
    setUserState((prevState) => ({
      ...prevState,
      pending: isPending,
    }))
  }, [])

  const fetchAuthorizations = useCallback(async () => {
    setPending(true)
    const { data: users } = await api.v1.getAuthorizations()
    setUserState((prevState) => ({
      ...prevState,
      authorizations: users,
    }))
    setPending(false)
  }, [setPending])

  const fetchData = useCallback(async () => {
    try {
      setPending(true)
      const { data: user } = await api.v1.getUser()
      if (user.isDelegateUser) {
        fetchAuthorizations()
      }
      setUserState((prevState) => ({
        ...prevState,
        pending: false,
        user: user,
      }))
    } catch (error) {
      setUserState((prevState) => ({ ...prevState, user: undefined, authorizations: undefined }))
    }
    setPending(false)
  }, [setPending, fetchAuthorizations])

  useEffect(() => {
    if (loginStatus) {
      fetchData()
    }
  }, [fetchData, loginStatus])

  const fetchAuthorizedUser = useCallback(
    async (omUid: number): Promise<User | undefined> => {
      try {
        const response = await api.v1.getPrincipalUser(omUid)

        // If delegation is not allowed, refetch authorizations to update list of valid authorizations and return
        if (response.status !== 200) {
          fetchAuthorizations()
          return
        }

        return response.data
      } catch {
        fetchAuthorizations()
        return
      }
    },
    [fetchAuthorizations]
  )

  const value = useMemo(
    () => ({ userState, fetchAuthorizedUser }),
    [userState, fetchAuthorizedUser]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUserState = () => {
  return useContext(UserContext)
}

export default useUserState
