import styled from '@emotion/styled'
import { Primary500, White } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'

import {
  AppointmentInfoResult,
  AppointmentType,
  BookingInstructions as BookingInstructionsT,
  ReservationDestinationSystem,
} from '../../../__generated__/api'
import AppointmentTypeSelect, {
  AppointmentTypeOption,
} from '../../../common/components/AppointmentTypeSelect/AppointmentTypeSelect'
import BookingInstructions from '../../../common/components/BookingInstructions/BookingInstructions'
import { CenteredColumnFlex, MinMaxDiv } from '../../../common/components/Layout/Layout'
import AppointmentSpecialistDetails from '../../../common/components/Tile/AppointmentSpecialistDetails'
import { Text } from '../../../common/components/Typography/Typography'
import { scale } from '../../../common/utils/scale'
import { AppointmentDetailsBackground } from '../../../constants'
import { practitionerModalPractitionerId } from '../../../state/practitioner/atoms'

import SimpleAppointmentDetails from './SimpleAppointmentDetails'

const Container = styled(CenteredColumnFlex)`
  gap: ${scale(3)};
`

const PractitionerContainer = styled.div`
  margin-left: 25px;
`

const AppointmentDetails = styled(CenteredColumnFlex)`
  position: relative;
  overflow: hidden;
  padding: 40px 24px 24px 24px;
  gap: ${scale(2)};
  text-align: center;
  background: ${AppointmentDetailsBackground};
  border-radius: 8px;
`

const AppointmentDetailsTitle = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px 6px 12px;
  color: ${White};
  background: ${Primary500};
  border-radius: 0 0 8px 0;
`

interface Props {
  appointmentInfo: AppointmentInfoResult
  selectedDuration: number | 'default'
  appointmentTypeOptions: AppointmentTypeOption[]
  selectedType: AppointmentTypeOption
  appointmentTitle?: string
  bookingInstructions?: BookingInstructionsT | null
  isCallbackAppointment?: boolean
  reservationDestinationSystem?: ReservationDestinationSystem
  onSelect(type: AppointmentTypeOption): void
}

const TypeSelect: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentInfo,
  selectedDuration,
  appointmentTypeOptions,
  selectedType,
  appointmentTitle,
  bookingInstructions,
  isCallbackAppointment,
  reservationDestinationSystem,
  onSelect,
}) => {
  const { t } = useTranslation()
  const setPractitionerModalPractitionerId = useSetRecoilState(practitionerModalPractitionerId)

  const getTypeTitle = (typeOption: AppointmentTypeOption) => {
    if (isCallbackAppointment) return t('common.callback')
    if (typeOption.type === AppointmentType.Clinic) {
      return typeOption.clinic
    } else if (typeOption.type === AppointmentType.Video) {
      return t('common.videoAppointment')
    } else if (typeOption.type === AppointmentType.Phone) {
      return t('common.phoneAppointment')
    }
  }

  const getLocationPostPlace = (typeOption: AppointmentTypeOption) => {
    if (typeOption.postnumber && typeOption.postplace) {
      return `${typeOption.postnumber} ${typeOption.postplace}`
    }
  }

  const typeTitle =
    appointmentTypeOptions.length === 1 ? getTypeTitle(appointmentTypeOptions[0]) : undefined
  const locationAddress =
    appointmentTypeOptions.length === 1 && !isCallbackAppointment
      ? appointmentTypeOptions[0].address
      : undefined
  const locationPostPlace =
    appointmentTypeOptions.length === 1 && !isCallbackAppointment
      ? getLocationPostPlace(appointmentTypeOptions[0])
      : undefined

  return (
    <Container>
      <MinMaxDiv size="540px">
        <AppointmentDetails>
          <AppointmentDetailsTitle $size={200}>
            {t('component.typeSelect.reservation')}
          </AppointmentDetailsTitle>
          <PractitionerContainer>
            <AppointmentSpecialistDetails
              variant="M"
              name={appointmentInfo.practitionerName}
              title={appointmentInfo.practitionerTitle}
              image={appointmentInfo.practitionerImage}
              isResourceList={appointmentInfo.isResourceList}
              onClick={() => setPractitionerModalPractitionerId(appointmentInfo.practitionerId)}
            />
          </PractitionerContainer>

          <SimpleAppointmentDetails
            appointmentTitle={appointmentTitle}
            time={appointmentInfo.date}
            duration={selectedDuration === 'default' ? appointmentInfo.duration : selectedDuration}
            typeTitle={typeTitle}
            locationAddress={locationAddress}
            locationPostPlace={locationPostPlace}
            isCallbackAppointment={isCallbackAppointment}
          />
        </AppointmentDetails>
        {bookingInstructions && <BookingInstructions instructions={bookingInstructions} />}
        {appointmentTypeOptions.length > 1 && (
          <AppointmentTypeSelect
            appointmentTypes={appointmentTypeOptions}
            value={selectedType}
            onSelect={onSelect}
            reservationDestinationSystem={reservationDestinationSystem}
          />
        )}
      </MinMaxDiv>
    </Container>
  )
}

export default TypeSelect
