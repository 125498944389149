import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ListBlock } from '@mehilainen/mds-customer'
import { Black, Gray600, Gray700 } from '@mehilainen/mds-customer/colors'
import { White } from '@mehilainen/mds-customer/colors'
import { EllipsisH, ExclamationTriangle } from '@mehilainen/mds-customer/icons'
import { AutocompleteRenderOptionState } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NodeAutocompleteResult, PublicNode } from '../../../__generated__/api'
import { AppointmentSearchMode } from '../../../state/search/atoms'
import { useModalEvents } from '../../../xstate/events'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useOHC } from '../../hooks/useOHC'
import { useNodeSearch } from '../../hooks/useSearch'
import { useEhoitoNavigationEvents } from '../../services/omApi'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { escapeRegExp } from '../../utils/text'
import AnchorButton from '../AnchorButton/AnchorButton'
import Avatar from '../Avatar/Avatar'
import { CenteredColumnFlex, CenteredRowFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import EventButton from '../OMLight/EventButton'
import Search from '../Search/Search'
import { SearchTreeItem } from '../Search/types'
import { convertSearchNodes, searchTreeItemIconProvider } from '../Search/utils'
import { Text } from '../Typography/Typography'

const SearchContainer = styled.div<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 0 24px 0' : '32px 24px 32px 24px')};
  background: ${(props) => (props.isMobile ? White : 'transparent')};
`

const NoteContainer = styled(CenteredColumnFlex)`
  text-align: center;
  gap: 24px;

  .MuiSvgIcon-root {
    width: 48px;
    height: 48px;
    color: ${Black};
  }
`

const NoteTitle = styled.div`
  font-size: 1.938rem;
  line-height: 1.4;
`

const FullWidthBox = styled(Box)`
  width: 100%;
`

const ResultsContainer = styled(CenteredRowFlex)`
  ${Avatar} {
    margin-right: ${scale(1.5)};
  }
  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
    margin-right: ${scale(1.5)};
    color: ${Black};
  }
`

const TextContainer = styled(CenteredRowFlex)<{ isMobile: boolean }>`
  display: inline-block;
  width: 100%;
  font-size: ${(props) => (props.isMobile ? '0.875rem' : '0.938rem')};
  white-space: pre-wrap;
  @media (max-width: ${breakpoint.sm}px) {
    flex-wrap: wrap;
  }
`

const SearchItemNameHighlighted = styled.mark`
  background: none;
  font-weight: 700;
`

const PractitionerTitle = styled.span<{ isMobile: boolean }>`
  color: ${Gray600};
  margin-left: ${scale(2)};
  ${(props) =>
    props.isMobile &&
    css`
      font-size: 0.75rem;
    `}
`

const BreadcrumbTitle = styled(Text)`
  display: block;
  padding: 16px 0 8px 0;
`

interface AutocompleteRowProps {
  re: RegExp
  state: AutocompleteRenderOptionState
  option: NodeAutocompleteResult
  action: (option: NodeAutocompleteResult) => void
  isMobile: boolean
}

const AutocompleteRow: React.FC<React.PropsWithChildren<AutocompleteRowProps>> = ({
  re,
  state,
  option,
  action,
  isMobile,
}) => (
  <FullWidthBox {...state} onClick={() => action(option)}>
    <ResultsContainer>
      {option.groupData && (
        <EllipsisH /* FIXME: not the correct icon, no LayerGroup in new design-system */ />
      )}
      {option.practitionerData && <Avatar image={option.practitionerData.image} />}
      <TextContainer isMobile={isMobile}>
        {option.keyword
          .split(re)
          .map((part, index) =>
            re.test(part) ? (
              <SearchItemNameHighlighted key={`search-item-name-highlight-${part}-${index}`}>
                {part}
              </SearchItemNameHighlighted>
            ) : (
              part
            )
          )}
        {option.practitionerData && option.practitionerData.title && (
          <PractitionerTitle isMobile={isMobile}>{option.practitionerData.title}</PractitionerTitle>
        )}
      </TextContainer>
    </ResultsContainer>
  </FullWidthBox>
)

const getIcon = (item: SearchTreeItem<PublicNode>) => {
  const icon = searchTreeItemIconProvider(item)
  return (
    <img
      src={icon}
      aria-hidden="true"
      style={{
        width: '28px',
        height: '28px',
        filter:
          'invert(23%) sepia(85%) saturate(1811%) hue-rotate(130deg) brightness(100%) contrast(101%)',
        shapeRendering: 'crispEdges',
      }}
    />
  )
}

interface Props {
  listedNodes: PublicNode[]
  favoriteNodes: PublicNode[]
  breadcrumb: Array<SearchTreeItem<PublicNode>>
  onSelect: (service: any /* FIXME: typing */) => void
  onClose: () => void
}

const callbackItemId = AppointmentSearchMode.CALLBACK

const SearchSelect: React.FC<React.PropsWithChildren<Props>> = ({
  listedNodes,
  favoriteNodes,
  breadcrumb,
  onSelect,
  onClose,
}) => {
  const { t, i18n } = useTranslation()
  const { isOHCSide } = useOHC()
  const nodeSearch = useNodeSearch(isOHCSide)
  const isMobile = useIsMobile()
  const { events } = useEhoitoNavigationEvents()
  const { openCallbackModal } = useModalEvents()

  const noteProvider = (item: SearchTreeItem<PublicNode>) => {
    return (
      <NoteContainer>
        <ExclamationTriangle />
        <NoteTitle>{t('component.serviceSelect.noteTitle')}</NoteTitle>
        <div>{t('component.serviceSelect.noteText', { name: item.name.toLocaleLowerCase() })}</div>
        <div>
          <AnchorButton href="https://www.mehilainen.fi/tietoa-asiakkaalle/palvelunumerot">
            {t('component.serviceSelect.noteLink')}
          </AnchorButton>
        </div>
      </NoteContainer>
    )
  }

  const callbackItem: SearchTreeItem<PublicNode> = {
    name: t('common.callback'),
    id: callbackItemId,
    item: {
      id: callbackItemId,
    } as PublicNode,
  }

  const popularData = [
    ...convertSearchNodes(favoriteNodes, t('component.serviceSearch.secondaryGroupLabel')),
    callbackItem,
  ]

  const allData = convertSearchNodes(
    listedNodes,
    t('component.serviceSearch.secondaryGroupLabel')
  ).sort((item, other) => item.name.localeCompare(other.name, i18n.language))

  const breadcrumbHighlightProvider = (item: SearchTreeItem<PublicNode> | undefined) =>
    item?.item?.referralLinks.some((link) => link === 'ttk') && events?.length ? (
      <>
        <BreadcrumbTitle $size={200} $height="Medium" $uppercase $color={Gray700}>
          {t('component.serviceSearch.breadcrumbHighlight')}
        </BreadcrumbTitle>
        <EventButton events={events} />
        <BreadcrumbTitle $size={200} $height="Medium" $uppercase $color={Gray700}>
          {t('component.serviceSearch.otherHealthExaminations')}
        </BreadcrumbTitle>
      </>
    ) : null

  return (
    <ResponsiveModal
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableMobileClose
      omitDefaultPadding
      sx={{
        '& .MuiDialog-paper': {
          overflowY: 'visible',
        },
      }}
    >
      <SearchContainer isMobile={isMobile}>
        <Search
          searchFieldLabel={t('component.serviceSearch.searchFieldLabel')}
          searchResult={null}
          popularData={popularData}
          allData={allData}
          breadcrumb={breadcrumb}
          onBreadcrumbChange={(crumb) => {
            if (typeof crumb === 'function') {
              onSelect(crumb([]))
            } else {
              onSelect(crumb)
            }
          }}
          onSelectAndClose={(item) => {
            if (item.id === callbackItemId) {
              openCallbackModal()
              return
            }
            onSelect(item)
          }}
          onClose={onClose}
          noteProvider={noteProvider}
          searchPending={false /* FIXME */}
          searchIndex={nodeSearch}
          onAutocompleteOptionSelect={onSelect}
          renderAutocompleteOption={(props, option, state) => {
            const re = new RegExp(`(${escapeRegExp(state.inputValue)})`, 'gi')
            return (
              <li {...props}>
                <AutocompleteRow
                  re={re}
                  option={option}
                  state={state}
                  action={onSelect}
                  isMobile={isMobile}
                />
              </li>
            )
          }}
          getOptionLabel={(option) => option.keyword}
          optionListModifier={(options) => [
            ...options.filter((option) => !option.practitionerData),
            ...options.filter((option) => option.practitionerData),
          ]}
          backLabel={t('component.serviceSearch.backLabel')}
          useTracking
          open={true}
          renderItem={(item, onItemSelect, attr, dataset) => {
            return (
              <ListBlock
                {...attr}
                {...dataset}
                title={item.name}
                description={item.caption}
                onClick={onItemSelect}
                startIcon={getIcon(item)}
                endIcon={item?.children && item.children.length > 0 ? undefined : false}
                size={isMobile ? 'small' : 'medium'}
                sx={{ paddingLeft: '16px !important' }}
              />
            )
          }}
          breadcrumbHighlightProvider={breadcrumbHighlightProvider}
        />
      </SearchContainer>
    </ResponsiveModal>
  )
}

export default SearchSelect
