import styled from '@emotion/styled'
import { Gray500 } from '@mehilainen/mds-customer/colors'
import { Times } from '@mehilainen/mds-customer/icons'
import { Modal, Paper, Popper } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'

const Arrow = styled.span`
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;

  &::before,
  &::after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin: 0;
  }
`

const ArrowBorderColor = '#dddddd'

const StyledPopper = styled(Popper)`
  border: 1px solid ${ArrowBorderColor};
  border-radius: 12px;

  &[data-popper-placement*='bottom'] ${Arrow} {
    &::before,
    &::after {
      bottom: 100%;
    }
    &::before {
      border-width: 1.5em;
      border-bottom-color: ${ArrowBorderColor};
    }
    &::after {
      border-width: 1.3em;
      margin-left: 0.2em;
      border-bottom-color: white;
    }
  }

  &[data-popper-placement*='top'] ${Arrow} {
    top: 100%;

    &::before {
      border-width: 1.5em;
      border-top-color: ${ArrowBorderColor};
    }
    &::after {
      border-width: 1.3em;
      margin-left: 0.2em;
      border-top-color: white;
    }
  }

  &[data-popper-placement*='right'] ${Arrow} {
    margin-top: 1em;
    margin-bottom: 1em;

    &::before,
    &::after {
      right: 100%;
    }

    &::before {
      border-width: 1.5em;
      border-right-color: ${ArrowBorderColor};
    }
    &::after {
      border-width: 1.3em;
      border-right-color: white;
      margin-top: 0.2em;
      margin-left: 0.2em;
    }
  }

  &[data-popper-placement*='left'] ${Arrow} {
    margin-top: 1em;
    margin-bottom: 1em;
    left: 100%;

    &::before {
      border-width: 1.5em;
      border-left-color: ${ArrowBorderColor};
    }
    &::after {
      border-width: 1.3em;
      border-left-color: white;
      margin-top: 0.2em;
      margin-right: 0.2em;
    }
  }
`

const StyledDialogPaper = styled(Paper)`
  padding: 16px 32px 16px 16px;
  background: white;
  border-radius: 12px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
`

const StyledTooltipPaper = styled(StyledDialogPaper)`
  max-width: 400px;
  max-height: 400px;
`

const StyledClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  top: 5px;
  right: 0;
  color: ${Gray500};
`

const DialogContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
`

interface Props {
  open: boolean
  onClose: () => void
  anchorEl: HTMLElement | null
}

const TooltipModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  open,
  onClose,
  anchorEl,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement> | undefined) => {
    if (!event) return
    event.stopPropagation()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onClose()
    }
  }

  const onModalClose = (event: { stopPropagation?: () => void }) => {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation()
    }
    onClose()
  }

  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <>
      <Modal open={open} onClose={onModalClose} disablePortal>
        {isMobile ? (
          <DialogContainer onClick={handleClick}>
            <StyledDialogPaper>
              {children}
              <StyledClose aria-label={t('common.close')} onClick={onClose}>
                <Times color="inherit" />
              </StyledClose>
            </StyledDialogPaper>
          </DialogContainer>
        ) : (
          <StyledPopper
            disablePortal
            placement={'bottom-end'}
            style={{ zIndex: 99999 }}
            open={open}
            onClick={handleClick}
            anchorEl={open ? anchorEl : null}
            modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
          >
            <Arrow ref={setArrowRef} />
            <StyledTooltipPaper>
              {children}
              <StyledClose
                aria-label={t('common.close')}
                onClick={onClose}
                onKeyDown={handleKeyDown}
              >
                <Times color="inherit" />
              </StyledClose>
            </StyledTooltipPaper>
          </StyledPopper>
        )}
      </Modal>
    </>
  )
}
export default TooltipModal
