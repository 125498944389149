import { Dayjs } from 'dayjs'
import React, { createContext, ReactNode, useContext } from 'react'

import DateModal from '../../../common/components/DateSelect/DateModal'
import { CalendarAvailability } from '../../../common/components/DateSelect/DatePickerCalendar'
import { useDeepCompareCallback } from '../../../common/hooks/useDeepCompare'
import { useOHC } from '../../../common/hooks/useOHC'
import api from '../../../common/services/api'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { AppointmentSearchMode } from '../../../state/search/atoms'
import { useModalEvents, useSearchEvents } from '../../../xstate/events'
import {
  useAppointmentSearchMode,
  useCalendarSearchParams,
  useModalMachineState,
  useSelectedDate,
} from '../../../xstate/selectors'

type SearchCalendarContextType = {
  setCalendarModalOpen(): void
  selectedDate: Dayjs
  setSelectedDate(date: Dayjs): void
  calendarDataFetcher(date: Dayjs): Promise<CalendarAvailability[]>
}

const SearchCalendarContext = createContext<SearchCalendarContextType>({
  setCalendarModalOpen: () => {
    /* Placeholder */
  },
  selectedDate: dayjs(),
  setSelectedDate: () => {
    /* Placeholder */
  },
  calendarDataFetcher: () => Promise.resolve([]),
})

const apiCancelToken = 'SearchCalendarProvider-search-calendar'

export const SearchCalendarProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { nodeSearchParams, practitionerSearchParams, isPractitionerSearch } =
    useCalendarSearchParams()

  const { isOHCSide, ohcAllowedStatus } = useOHC()

  const selectedDate = useSelectedDate()
  const appointmentSearchMode = useAppointmentSearchMode()
  const modalMachineState = useModalMachineState()
  const { openDateModal: setCalendarModalOpen, closeModal } = useModalEvents()
  const { setSearchDate: setSelectedDate } = useSearchEvents()

  const nodeCalendarDataFetcher = useDeepCompareCallback(
    async (date: Dayjs) => {
      if (isOHCSide && ohcAllowedStatus !== 'allowed') {
        return []
      }
      api.http.abortRequest(apiCancelToken)
      const data = await api.v1
        .nodeSearchCalendar(
          {
            ...nodeSearchParams,
            startDate: date.toJSON(),
          },
          { cancelToken: apiCancelToken }
        )
        .then((res) => res.data ?? [])
        .catch(() => [])

      return [...data.map((slot) => ({ date: dayjs(slot.date), isAvailable: slot.isAvailable }))]
    },
    [nodeSearchParams, isOHCSide, ohcAllowedStatus]
  )

  const practitionerCalendarDataFetcher = useDeepCompareCallback(
    async (date: Dayjs) => {
      if (!practitionerSearchParams) {
        return []
      }
      const endpoint =
        appointmentSearchMode === AppointmentSearchMode.CALLBACK
          ? api.v1.practitionerCallbackSearchCalendar
          : api.v1.practitionerAppointmentSearchCalendar

      api.http.abortRequest(apiCancelToken)
      const data = await endpoint(
        {
          ...practitionerSearchParams,
          startDate: date.toJSON(),
          isOhc: isOHCSide,
        },
        { cancelToken: apiCancelToken }
      )
        .then((res) => res.data)
        .catch(() => [])

      return [...data.map((slot) => ({ date: dayjs(slot.date), isAvailable: slot.isAvailable }))]
    },
    [practitionerSearchParams, appointmentSearchMode, isOHCSide]
  )

  const calendarDataFetcher = isPractitionerSearch
    ? practitionerCalendarDataFetcher
    : nodeCalendarDataFetcher

  return (
    <SearchCalendarContext.Provider
      value={{
        setCalendarModalOpen,
        selectedDate,
        setSelectedDate,
        calendarDataFetcher,
      }}
    >
      {children}
      <DateModal
        open={modalMachineState.matches('DateModal')}
        calendarDataFetcher={calendarDataFetcher}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={closeModal}
      />
    </SearchCalendarContext.Provider>
  )
}

const useSearchCalendar = () => {
  return useContext(SearchCalendarContext)
}

export default useSearchCalendar
