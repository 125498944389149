import * as qs from 'qs'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { AppointmentType, SourceService } from '../../../__generated__/api'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import {
  marketingCampaignCodeAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
  selectedAppointmentTypeAtom,
  sourceServiceAtom,
} from '../../../state/common/atoms'
import { initialReserveHistoryRestoredAtom } from '../../../state/reserve/atoms'
import { AppointmentSearchMode } from '../../../state/search/atoms'
import { useAppointmentSearchMode } from '../../../xstate/selectors'

const useReserveHistory = (): void => {
  const history = useHistory()

  const [selectedAppointmentType, setSelectedAppointmentType] = useRecoilState(
    selectedAppointmentTypeAtom
  )
  const [selectedAppointmentLength, setSelectedAppointmentLength] = useRecoilState(
    selectedAppointmentLengthAtom
  )

  const [selectedConnectionId, setSelectedConnectionId] = useRecoilState(
    selectedAppointmentNodeConnectionIdAtom
  )

  const [selectedNodeId, setSelectedNodeId] = useRecoilState(selectedAppointmentNodeIdAtom)

  const [selectedService, setSelectedService] = useRecoilState(selectedAppointmentServiceIdAtom)
  const [initialReserveHistoryRestored, setInitialReserveHistoryRestored] = useRecoilState(
    initialReserveHistoryRestoredAtom
  )

  const { currentLanguage } = useChangeLanguage()

  const appointmentSearchMode = useAppointmentSearchMode()

  const [marketingCampaignCode, setMarketingCampaignCode] =
    useRecoilState(marketingCampaignCodeAtom)

  const [selectedSourceService, setSelectedSourceService] = useRecoilState(sourceServiceAtom)

  // Deserialization fn to restore state from url
  const restoreFromHistory = useCallback(
    (searchString: string) => {
      if (searchString !== '') {
        const parsed = qs.parse(searchString)

        if (parsed.length && typeof parsed.length === 'string') {
          if (parsed.length === 'default') {
            setSelectedAppointmentLength('default')
          } else {
            const parsedAppointmentLength = parseInt(parsed.length, 10)
            if (!isNaN(parsedAppointmentLength)) {
              setSelectedAppointmentLength(parsedAppointmentLength)
            }
          }
        } else {
          setSelectedAppointmentLength('default')
        }

        if (parsed.node && typeof parsed.node === 'string') {
          const parsedNode = parsed.node
          setSelectedNodeId(parsedNode)
        }

        if (parsed.conn && typeof parsed.conn === 'string') {
          const parsedConn = parsed.conn
          setSelectedConnectionId(parsedConn)
        }

        if (
          parsed.service &&
          typeof parsed.service === 'string' &&
          parsed.service !== 'undefined'
        ) {
          const parsedService = parsed.service
          setSelectedService(parseInt(parsedService, 10))
        }

        if (parsed.mode && typeof parsed.mode === 'string') {
          const parsedMode = parsed.mode
          if (parsedMode === AppointmentSearchMode.CALLBACK) {
            setSelectedAppointmentType(AppointmentType.Callback)
          } else {
            setSelectedAppointmentType(undefined)
          }
        }

        if (parsed.type && typeof parsed.type === 'string') {
          const parsedAppointmentType = parsed.type

          if (Object.values(AppointmentType).includes(parsedAppointmentType as AppointmentType)) {
            setSelectedAppointmentType(parsedAppointmentType as AppointmentType)
          }
        }

        if (parsed.marketingCampaignCode && typeof parsed.marketingCampaignCode === 'string') {
          setMarketingCampaignCode(parsed.marketingCampaignCode)
        }

        //TODO: Remove session storage implementation when AV3-2652 is implemented
        if (parsed.sourceService && typeof parsed.sourceService === 'string') {
          setSelectedSourceService(parsed.sourceService as SourceService)
          // Store to session storage
          sessionStorage.setItem('av3_sourceService', parsed.sourceService)
        } else {
          //Try to fetch from session storage if not in url
          const sourceService = sessionStorage.getItem('av3_sourceService')
          if (sourceService) {
            setSelectedSourceService(sourceService as SourceService)
          }
        }
      }
      setInitialReserveHistoryRestored(true)
    },
    [
      setInitialReserveHistoryRestored,
      setSelectedAppointmentType,
      setSelectedAppointmentLength,
      setSelectedService,
      setSelectedNodeId,
      setSelectedConnectionId,
      setMarketingCampaignCode,
      setSelectedSourceService,
    ]
  )

  useEffect(() => {
    if (!initialReserveHistoryRestored) {
      restoreFromHistory(history.location.search.slice(1))
    }
    return history.listen((location, action) => {
      if (action !== 'REPLACE') {
        restoreFromHistory(history.location.search.slice(1))
      }
    })
  }, [restoreFromHistory, history, initialReserveHistoryRestored])

  // Serialize relevant state to url query string
  useEffect(() => {
    if (initialReserveHistoryRestored) {
      history.replace({
        search: qs.stringify({
          type: selectedAppointmentType,
          length: selectedAppointmentLength,
          service: selectedService || undefined,
          lang: currentLanguage,
          node: selectedNodeId,
          conn: selectedConnectionId,
          marketingCampaignCodeAtom: marketingCampaignCode,
          sourceService: selectedSourceService,
        }),
      })
    }
  }, [
    history,
    selectedAppointmentType,
    selectedAppointmentLength,
    currentLanguage,
    selectedService,
    selectedNodeId,
    selectedConnectionId,
    appointmentSearchMode,
    marketingCampaignCode,
    selectedSourceService,
    initialReserveHistoryRestored,
  ])
}

export default useReserveHistory
