import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js'
import * as Yup from 'yup'

const YUP_PHONE_METHOD = 'phone'

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function (countryCode: CountryCode, message: string) {
  return this.test(YUP_PHONE_METHOD, message, function (value) {
    if (value === undefined || value === '') {
      return true
    }

    if (typeof value !== 'string' || value.trim() !== value) {
      return false
    }

    try {
      const phoneNumber = parsePhoneNumberWithError(value, countryCode)
      return phoneNumber.isValid()
    } catch {
      return false
    }
  })
})
