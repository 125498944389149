import { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'

import { AppointmentId } from '../../state/common/atoms'
import api from '../services/api'
import dayjs from '../utils/dayjs/dayjs'

import { useDeepCompareEffect } from './useDeepCompare'
import useLoginState from './useLoginState'
const EXTENDED_LENGTH = 20 * 60 // 20 min

export const useLockAppointment = (
  appointmentId: AppointmentId,
  isReservedRef: React.MutableRefObject<boolean>,
  duration?: number,
  lockLength?: number,
  nodeId?: string,
  serviceId?: number
): {
  lockReleaseDateTime: Dayjs | undefined
  error: boolean
  extendLock: () => void
  extended: boolean
} => {
  const { loginStatus } = useLoginState()
  const [lockReleaseDateTime, setLockReleaseDateTime] = useState<Dayjs>()
  const [lockedAppointmentIds, setLockedAppointmentIds] = useState<number[]>()
  const [error, setError] = useState<boolean>(false)
  const [extended, setExtended] = useState<boolean>(false)

  const lockApiCall = useCallback(
    (id: number, extendedLength?: boolean) => {
      api.v1
        .lockAppointment(
          {
            appointmentId: id,
            duration,
            lockLength: extendedLength ? EXTENDED_LENGTH : lockLength,
            nodeId,
            serviceId,
          },
          { credentials: 'include', baseUrl: process.env.REACT_APP_API }
        )
        .then((res) => {
          if (res.error) {
            setError(true)
            setLockReleaseDateTime(undefined)
            setLockedAppointmentIds(undefined)
            return
          }
          setLockReleaseDateTime(dayjs(res.data.endDateTime))
          setLockedAppointmentIds(res.data.appointmentIds)
        })
        .catch(() => {
          setError(true)
          setLockReleaseDateTime(undefined)
          setLockedAppointmentIds(undefined)
        })
    },
    [duration, lockLength, nodeId, serviceId]
  )

  useEffect(() => {
    setLockedAppointmentIds(undefined)
    setExtended(false)
  }, [appointmentId, duration])

  useDeepCompareEffect(() => {
    if (loginStatus && !lockedAppointmentIds && typeof appointmentId === 'number') {
      lockApiCall(appointmentId)
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps, no-console
      if (loginStatus && lockedAppointmentIds && !isReservedRef.current) {
        api.v1
          .unlockAppointment(
            { appointmentIds: lockedAppointmentIds },
            { credentials: 'include', baseUrl: process.env.REACT_APP_API }
          )
          .catch(() => {
            /* NO-OP: server will eventually clean up if the request fails. */
          })
      }
    }
  }, [
    loginStatus,
    lockedAppointmentIds, // Reference type so deep comparison is needed to detect no changes
    appointmentId,
    duration,
    lockLength,
    isReservedRef,
    lockApiCall,
  ])

  const extendLock = () => {
    setExtended(true)
    if (loginStatus && typeof appointmentId === 'number') {
      lockApiCall(appointmentId, true)
    }
  }

  return { lockReleaseDateTime, error, extendLock, extended }
}
