import styled from '@emotion/styled'
import { Gray700 } from '@mehilainen/mds-customer/colors'
import { Form } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import { CenteredColumnFlex, ColumnFlex, MinMaxDiv } from '../../../common/components/Layout/Layout'
import RadioButtonGroup from '../../../common/components/RadioButtonGroup/RadioButtonGroup'
import { Text } from '../../../common/components/Typography/Typography'
import { scale } from '../../../common/utils/scale'

import { ButtonContainer } from './FormComponents'

const Container = styled(ColumnFlex)`
  display: flex;
  flex-direction: column;
  margin: ${scale(1)};
  margin-bottom: ${scale(5)};
`

const Heading = styled(Text)`
  margin: ${scale(5)} 0;
`
Heading.defaultProps = { as: 'h1' }

const FormHeading = styled(Text)`
  margin-bottom: ${scale(3)};
`
FormHeading.defaultProps = { as: 'h2' }

const StyledForm = styled(Form)`
  margin-top: ${scale(6)};
  display: flex;
  flex-direction: column;
  gap: ${scale(1)};
`

interface Props {
  onContinue(): void
  onBack(): void
}

const AccidentAdditionalDetailsForm: React.FC<React.PropsWithChildren<Props>> = ({
  onContinue,
  onBack,
  children,
}) => {
  const { t } = useTranslation()
  const [previouslyVisited, setPreviouslyVisited] = useState<boolean>()

  return (
    <CenteredColumnFlex>
      <Heading $size={500} $weight="Medium" $height="Medium">
        {t('component.accidentAdditionalDetailsForm.heading')}
      </Heading>
      <MinMaxDiv size="540px">
        <Container>
          <RadioButtonGroup
            options={[true, false]}
            renderOptionHeader={(option) =>
              option === true
                ? t('component.accidentAdditionalDetailsForm.previouslyVisited.yes')
                : t('component.accidentAdditionalDetailsForm.previouslyVisited.no')
            }
            renderOptionDescription={(option) =>
              option === true && previouslyVisited ? (
                <Text $size={300} $weight="Regular" $height="Medium" $color={Gray700}>
                  {t(
                    'component.accidentAdditionalDetailsForm.previouslyVisited.yesDescriptionText'
                  )}
                </Text>
              ) : undefined
            }
            onChange={setPreviouslyVisited}
            value={previouslyVisited}
          />
          {previouslyVisited === false ? (
            <StyledForm>
              <FormHeading $size={400} $weight="Regular" $height="Small">
                {t('component.accidentAdditionalDetailsForm.subheading')}
              </FormHeading>
              {children}
            </StyledForm>
          ) : (
            <ButtonContainer>
              <DefaultButton onClick={onContinue} disabled={previouslyVisited === undefined}>
                {t('common.continue')}
              </DefaultButton>
              <DefaultButton variant="outlined" onClick={onBack}>
                {t('common.back')}
              </DefaultButton>
            </ButtonContainer>
          )}
        </Container>
      </MinMaxDiv>
    </CenteredColumnFlex>
  )
}

export default AccidentAdditionalDetailsForm
