import styled from '@emotion/styled'

import { scale } from '../../utils/scale'
import { Text } from '../Typography/Typography'

const TextFieldHeading = styled(Text)`
  margin-bottom: ${scale(1.5)};
`
TextFieldHeading.defaultProps = { as: 'h1', $weight: 'Medium' }

export default TextFieldHeading
