import styled from '@emotion/styled'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentLengthOption, SupportedLanguage } from '../../../__generated__/api'
import AppointmentLengthSelect from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import Modal from '../../../common/components/Modal/Modal'
import { Text } from '../../../common/components/Typography/Typography'
import { useApi } from '../../../common/hooks/useApi'
import api from '../../../common/services/api'
import { scale } from '../../../common/utils/scale'

const Heading = styled(Text)`
  margin-bottom: ${scale(4)};
`

interface Props {
  appointmentId: number
  preselectedServiceId: number
  preselectedDuration: number
  appointmentNotAvailableCallback(): void
  onClose(): void
  onSelect(duration: number): void
}

const SearchFlexiblePractitionerServiceSelect: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentId,
  preselectedServiceId,
  preselectedDuration,
  appointmentNotAvailableCallback,
  onClose,
  onSelect,
}) => {
  const { t, i18n } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<AppointmentLengthOption>()

  const { data: appointmentLengthOptions, pending } = useApi(
    api.v1.getAppointmentLengthOptions,
    {
      lang: i18n.language as SupportedLanguage,
      appointmentId: appointmentId,
    },
    []
  )

  const filteredOptions = useMemo(
    () => [
      ...appointmentLengthOptions.filter((option) => option.serviceId === preselectedServiceId),
    ],
    [appointmentLengthOptions, preselectedServiceId]
  )

  useEffect(() => {
    const found = filteredOptions.find((option) => option.duration === preselectedDuration)
    if (!pending && !found) {
      appointmentNotAvailableCallback()
      return
    }
    setSelectedOption(found as AppointmentLengthOption)
  }, [filteredOptions, pending, preselectedDuration, appointmentNotAvailableCallback, onClose])

  if (pending || !selectedOption) {
    return null
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      primaryButtonText={t('common.continue')}
      fullWidth={true}
      onPrimaryButtonClick={() => onSelect(selectedOption.duration)}
      secondaryButtonText={t('common.cancel')}
      onSecondaryButtonClick={onClose}
    >
      <Heading
        $size={450}
        $weight="Medium"
        $height="Medium"
        data-cy="practitionerFlexibleAppointmentLengthSelectModal-heading"
      >
        {t('component.practitionerFlexibleAppointmentLengthSelectModal.header')}
      </Heading>
      <AppointmentLengthSelect
        options={filteredOptions}
        value={selectedOption.duration}
        onSelect={(option) => setSelectedOption(option as AppointmentLengthOption)}
      />
    </Modal>
  )
}

export default SearchFlexiblePractitionerServiceSelect
