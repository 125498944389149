import { Event } from '@mehilainen/mds-customer'
import { Gray600, Gray800, Gray900 } from '@mehilainen/mds-customer/colors'
import { PollH, UserNurse, Vial } from '@mehilainen/mds-customer/icons'
import {
  eHoitoEvent,
  eHoitoEventType,
  eHoitoSubEventAppointment,
} from '@mehilainen/omamehilainen-types/lib/ehoito'
import { Referral, ReferralSubTypes } from '@mehilainen/omamehilainen-types/lib/referrals'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { NotificationStyle, SupportedLanguage } from '../../../__generated__/api'
import useUserState from '../../../domain/reserve/hooks/useUserState'
import { isFromAppAtom } from '../../../state/common/atoms'
import { useModalEvents } from '../../../xstate/events'
import { useSelectedOMCarePlanGuid } from '../../../xstate/selectors'
import { useIsMobile } from '../../hooks/useBreakpoint'
import useLoginState from '../../hooks/useLoginState'
import { useOHC } from '../../hooks/useOHC'
import { useEhoitoEvents } from '../../services/omApi'
import { scale } from '../../utils/scale'
import { formatDate2 } from '../../utils/text'
import { ColumnFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import Notification from '../Notification/Notification'
import { Text } from '../Typography/Typography'

import { getAppointmentUrlFromEventData } from './utils'

const getTranslation = (
  object: { fi: string; sv?: string; en?: string } | undefined,
  lang: SupportedLanguage
) => {
  if (!object) {
    return undefined
  }

  return object[lang] ?? object.fi
}

type LaboratoryPreparationInstruction = {
  id: number
  examination: string
  preparation?: string
}
interface LaboratoryPreparationInstructionsProps {
  instructions: LaboratoryPreparationInstruction[]
}

const LaboratoryPreparationInstructions = React.forwardRef<
  HTMLHeadingElement,
  LaboratoryPreparationInstructionsProps
>(({ instructions }, ref) => {
  const { t } = useTranslation()

  return (
    <ColumnFlex $gap={scale(2)}>
      <Text as="h2" $size={400} $height="Medium" $weight="Medium" ref={ref} tabIndex={-1}>
        {t('component.omLightModal.laboratoryPreparationInstructions.title')}
      </Text>

      <ColumnFlex $gap={scale(3)}>
        {instructions
          .filter((instruction) => instruction.preparation)
          .map((instruction) => (
            <ColumnFlex key={instruction.id} $gap={scale(1)}>
              <Text
                as="h3"
                $uppercase
                $size={200}
                $weight="Regular"
                $height="Small"
                $color={Gray600}
              >
                {instruction.examination}
              </Text>
              <Text $size={300} $weight="Regular" $height="Large">
                {instruction.preparation}
              </Text>
            </ColumnFlex>
          ))}
        <Text
          as="a"
          href={t('component.omLightModal.laboratoryPreparationInstructions.link')}
          $size={300}
          $height="Medium"
          $weight="Medium"
        >
          {t('component.omLightModal.laboratoryPreparationInstructions.linkText')}
        </Text>
      </ColumnFlex>

      <Button
        className="no-link-styles no-external-icon"
        href={`${process.env.REACT_APP_AV_BASE_URL}/parse/tt:1/erikoisala:NAYT`}
      >
        {t('component.omLightModal.laboratoryPreparationInstructions.bookAppointment')}
      </Button>
    </ColumnFlex>
  )
})

interface LaboratoryReferralTimelineProps {
  practitionerName: string
  expires: Date
  created: Date
}

// TODO: get timeline styles from MDS once they are released.
const LaboratoryReferralTimeline: React.FC<LaboratoryReferralTimelineProps> = ({
  expires,
  created,
  practitionerName,
}) => {
  const { t } = useTranslation()
  return (
    <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="filled" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <ColumnFlex>
            <Text $size={400} $height="Medium" $weight="Regular">
              {t('component.omLightModal.laboratoryDetails.timeline.expires')}
            </Text>
            <Text $size={300} $height="Medium" $weight="Regular">
              {formatDate2(dayjs(expires), t)}
            </Text>
          </ColumnFlex>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot variant="filled" />
        </TimelineSeparator>
        <TimelineContent>
          <ColumnFlex>
            <Text $size={400} $height="Medium" $weight="Regular">
              {t('component.omLightModal.laboratoryDetails.timeline.created')}
            </Text>
            <Text $size={300} $height="Medium" $weight="Regular">
              {formatDate2(dayjs(created), t)} · {practitionerName}
            </Text>
          </ColumnFlex>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}

interface LaboratoryDetailsProps {
  referral: Referral
}

const LaboratoryDetails: React.FC<LaboratoryDetailsProps> = ({ referral }) => {
  const { t, i18n } = useTranslation()
  const [showPreparationInstructions, setShowPreparationInstructions] = useState<boolean>(false)
  const { instructions, expires, created, practitionerName } = useMemo(
    () => ({
      instructions:
        referral.laboratoryExaminationDetails?.map((detail) => ({
          id: detail.id,
          examination: detail.i18n.examination.fi,
          preparation: getTranslation(detail.i18n.preparation, i18n.language as SupportedLanguage),
        })) ?? [],
      expires: new Date(referral.validUntil),
      created: new Date(referral.createdDate),
      practitionerName: referral.practitioner.name,
    }),
    [i18n.language, referral]
  )
  const headingRef = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    headingRef.current?.focus()
  }, [])

  const hasPreparationInstructions = instructions.some((instruction) => instruction.preparation)

  if (showPreparationInstructions) {
    return <LaboratoryPreparationInstructions instructions={instructions} ref={headingRef} />
  }

  return (
    <ColumnFlex $gap={scale(2)}>
      <Text as="h2" $size={400} $height="Medium" $weight="Medium" ref={headingRef} tabIndex={-1}>
        {t('component.omLightModal.laboratoryDetails.title')}
      </Text>

      {hasPreparationInstructions && (
        <Notification
          style={NotificationStyle.Info}
          title={t('component.omLightModal.laboratoryDetails.preparationNotification.title')}
          action={
            <Button
              onClick={() => setShowPreparationInstructions(true)}
              variant="outlined"
              size="small"
            >
              {t('component.omLightModal.laboratoryDetails.preparationNotification.buttonText')}
            </Button>
          }
        >
          {t('component.omLightModal.laboratoryDetails.preparationNotification.text')}
        </Notification>
      )}

      <ColumnFlex $gap={scale(1)}>
        <Text as="h3" $uppercase $size={200} $weight="Regular" $height="Small" $color={Gray600}>
          {t('component.omLightModal.laboratoryDetails.examinationsHeading')}
        </Text>
        <Text $size={300} $weight="Regular" $height="Large">
          {instructions.map((instruction) => instruction.examination).join(', ')}
        </Text>
      </ColumnFlex>

      <LaboratoryReferralTimeline
        practitionerName={practitionerName}
        expires={expires}
        created={created}
      />

      <Button
        className="no-link-styles no-external-icon"
        href={
          hasPreparationInstructions
            ? undefined
            : `${process.env.REACT_APP_AV_BASE_URL}/parse/tt:1/erikoisala:NAYT`
        }
        onClick={() =>
          hasPreparationInstructions ? setShowPreparationInstructions(true) : undefined
        }
      >
        {t('component.omLightModal.laboratoryDetails.bookAppointment')}
      </Button>
    </ColumnFlex>
  )
}

interface EhoitoEventComponentProps {
  event: eHoitoEvent | Referral
  onReferralSelect(referral: Referral): void
}

const EhoitoEventComponent: React.FC<EhoitoEventComponentProps> = ({ event, onReferralSelect }) => {
  const { t, i18n } = useTranslation()
  const { selectOMCarePlanGuid } = useModalEvents()

  if (event.type === eHoitoEventType.NAVIGATION) {
    return (
      <Event
        startIcon={<PollH />}
        title={getTranslation(event.i18n?.name, i18n.language as SupportedLanguage)}
        description={getTranslation(event.i18n?.description, i18n.language as SupportedLanguage)}
        onClick={() => {
          selectOMCarePlanGuid(event.carePlanGuid)
        }}
      />
    )
  }

  if (event.type === eHoitoEventType.QUESTION || event.type === eHoitoEventType.SURVEY) {
    return (
      <Event
        className="no-link-styles no-external-icon"
        component="a"
        href={`${process.env.REACT_APP_OMAMEHILAINEN_URL}/om-app/`}
        startIcon={<PollH />}
        title={getTranslation(event.i18n?.name, i18n.language as SupportedLanguage)}
        description={getTranslation(event.i18n?.description, i18n.language as SupportedLanguage)}
        topLabel={t('component.omLightModal.ehoitoEvent.surveyTopLabel')}
      />
    )
  }

  if (event.type === eHoitoEventType.APPOINTMENT) {
    return (
      <Event
        className="no-link-styles no-external-icon"
        component="a"
        href={getAppointmentUrlFromEventData(event.subEventData as eHoitoSubEventAppointment)}
        startIcon={<UserNurse />}
        title={getTranslation(event.i18n?.name, i18n.language as SupportedLanguage)}
        description={getTranslation(event.i18n?.description, i18n.language as SupportedLanguage)}
      />
    )
  }

  if (event.type === 'referral' && event.subType === ReferralSubTypes.LABORATORY) {
    return (
      <Event
        onClick={() => onReferralSelect(event)}
        startIcon={<Vial />}
        title={t('component.omLightModal.ehoitoEvent.laboratoryTitle')}
        description={getTranslation(event.i18n?.description, i18n.language as SupportedLanguage)}
      />
    )
  }

  // Catch all other ehoito events and redirect to OM.
  if (event.type !== 'referral') {
    return (
      <Event
        className="no-link-styles no-external-icon"
        component="a"
        href={`${process.env.REACT_APP_OMAMEHILAINEN_URL}/om-app/`}
        startIcon={<PollH />}
        title={getTranslation(event.i18n?.name, i18n.language as SupportedLanguage)}
        description={getTranslation(event.i18n?.description, i18n.language as SupportedLanguage)}
      />
    )
  }

  return null
}

interface EhoitoEventsListProps {
  events: Array<eHoitoEvent | Referral>
  onReferralSelect(referral: Referral): void
}

const EhoitoEventsList: React.FC<EhoitoEventsListProps> = ({ events, onReferralSelect }) => {
  return (
    <ColumnFlex $gap={scale(1)}>
      {events.map((event) => (
        <EhoitoEventComponent key={event.id} event={event} onReferralSelect={onReferralSelect} />
      ))}
    </ColumnFlex>
  )
}

interface Props {
  title: string
  description: string
  eventsToShow: eHoitoEvent[]
  onReferralSelect(referral: Referral): void
}

const OMLightModalContent: React.FC<Props> = ({
  title,
  description,
  eventsToShow,
  onReferralSelect,
}) => {
  const isMobile = useIsMobile()
  const headingRef = useRef<HTMLHeadingElement>(null)
  useEffect(() => {
    headingRef.current?.focus()
  }, [])

  return (
    <ColumnFlex $gap={scale(3)}>
      <ColumnFlex $gap={scale(2)}>
        <Text
          as="h2"
          $size={isMobile ? 450 : 550}
          $height="Medium"
          $weight="Medium"
          $center={isMobile}
          $color={Gray900}
          ref={headingRef}
          tabIndex={-1}
        >
          {title}
        </Text>
        <Text
          $size={isMobile ? 300 : 400}
          $height="Large"
          $weight="Regular"
          $center={isMobile}
          $color={Gray800}
        >
          {description}
        </Text>
      </ColumnFlex>
      <EhoitoEventsList events={eventsToShow} onReferralSelect={onReferralSelect} />
    </ColumnFlex>
  )
}

const OMLightModal: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { isOHCSide, ohcAllowedStatus } = useOHC()
  const { events } = useEhoitoEvents()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const carePlanGuid = useSelectedOMCarePlanGuid()
  const { loginStatus } = useLoginState()
  const {
    userState: { user, pending: userPending },
  } = useUserState()
  const [selectedReferral, setSelectedReferral] = useState<Referral | undefined>()
  const { closeModal, selectOMCarePlanGuid } = useModalEvents()

  useEffect(() => {
    if (isFromApp) {
      closeModal()
    }
  }, [isFromApp, closeModal])

  const eventsToShow = useMemo(() => {
    if (!carePlanGuid) {
      return events?.filter((event) => event.type === eHoitoEventType.NAVIGATION)
    }
    return events
      ?.filter((event) => event.type !== eHoitoEventType.NAVIGATION)
      .filter(
        (event) =>
          event.carePlanGuid === carePlanGuid ||
          (event as any as Referral).relatedCarePlanGuid === carePlanGuid
      )
  }, [carePlanGuid, events])

  const title = useMemo(() => {
    if (!carePlanGuid) {
      return t('component.omLightModal.initialTitle', { name: user?.firstName })
    }
    return (
      events?.find(
        (event) => event.type === eHoitoEventType.NAVIGATION && event.carePlanGuid === carePlanGuid
      )?.i18n?.name[i18n.language] ?? ''
    )
  }, [carePlanGuid, events, i18n.language, t, user?.firstName])

  const description = useMemo(() => {
    if (!carePlanGuid) {
      return t('component.omLightModal.initialDescription')
    }
    return (
      events?.find(
        (event) => event.type === eHoitoEventType.NAVIGATION && event.carePlanGuid === carePlanGuid
      )?.i18n?.description[i18n.language] ?? ''
    )
  }, [t, i18n, events, carePlanGuid])

  // We do not want to display this inside the mobile webview
  if (isFromApp) {
    return null
  }

  // Should only be displayed to strongly authenticated users and when OHC side is active
  if (loginStatus !== 'authenticated' || !isOHCSide || ohcAllowedStatus !== 'allowed') {
    return null
  }

  if (!eventsToShow || userPending) {
    return null
  }

  if (selectedReferral) {
    return (
      <Modal open={true} onClose={closeModal} onBack={() => setSelectedReferral(undefined)}>
        <LaboratoryDetails referral={selectedReferral} />
      </Modal>
    )
  }

  return (
    <Modal
      open={true}
      onClose={closeModal}
      onBack={carePlanGuid ? () => selectOMCarePlanGuid(undefined) : undefined}
      closeButtonLabel={t('common.dismiss')}
    >
      <OMLightModalContent
        title={title}
        description={description}
        eventsToShow={eventsToShow}
        onReferralSelect={setSelectedReferral}
      />
    </Modal>
  )
}

export default OMLightModal
