import React, { useEffect } from 'react'
import { useContext } from 'react'

import { TabTitleContext } from './TabTitleContext'

const TabTitle: React.FC<{ value: string }> = (props: { value: string }) => {
  const defaultTitle = useContext(TabTitleContext)
  const divider = props.value && defaultTitle ? ' - ' : ''
  const parts = defaultTitle.split(' - ')
  const finalTitle = props.value + divider + parts[parts.length - 1]

  useEffect(() => {
    document.title = finalTitle
  }, [finalTitle])

  return null
}
export default TabTitle
