import styled from '@emotion/styled'
import { Calendar, Times } from '@mehilainen/mds-customer/icons'
import equal from 'fast-deep-equal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Chip from '../../../common/components/Chip/Chip'
import { Gender } from '../../../common/components/FilterOptions/types'
import { RowFlex, VisuallyHidden } from '../../../common/components/Layout/Layout'
import { useIsDesktop, useIsTablet } from '../../../common/hooks/useBreakpoint'
import { capitalizeFirstLetter } from '../../../common/utils/text'
import { defaultSelectedDurations, defaultSelectedTimeRanges } from '../../../state/search/atoms'
import { useModalEvents, useSearchEvents } from '../../../xstate/events'
import {
  useIsUserSelectedDate,
  useSearchFilterOptions,
  useSelectedDate,
} from '../../../xstate/selectors'
import SearchFilterInsuranceChip from '../components/SearchFilterInsuranceChip'
import useSearchCalendar from '../hooks/useSearchCalendar'

import filterIcon from './img/filter.svg'

const ChipsContainer = styled(RowFlex)`
  gap: 10px;
  flex-wrap: wrap;
`

interface Props {
  hideAddFiltersButton?: boolean
  hideInsuranceButton?: boolean
}

const SearchFilterChips: React.FC<React.PropsWithChildren<Props>> = ({
  hideAddFiltersButton,
  hideInsuranceButton,
}) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const { setCalendarModalOpen } = useSearchCalendar()
  const filterOptions = useSearchFilterOptions()
  const selectedDate = useSelectedDate()
  const userHasSelectedDate = useIsUserSelectedDate()
  const { openFilterModal } = useModalEvents()
  const { setSearchFilters } = useSearchEvents()
  const [statusMessage, setStatusMessage] = useState<string>('')

  const showTimeRangeChip = !equal(
    [...defaultSelectedTimeRanges].sort(),
    [...filterOptions.timeRanges].sort()
  )
  const showAppointmentTypeChip = true

  const showDurationsChip = filterOptions.durations.length > 0
  const showLanguageChip = filterOptions.language !== null
  const showGenderChip = filterOptions.gender !== Gender.UNSPECIFIED
  const showPatientAgeGroupChip = filterOptions.patientAgeGroup !== null

  if (
    hideAddFiltersButton &&
    !showTimeRangeChip &&
    !showAppointmentTypeChip &&
    !showLanguageChip &&
    !showGenderChip &&
    !showPatientAgeGroupChip &&
    !showDurationsChip
  ) {
    return null
  }

  return (
    <ChipsContainer data-cy="searchFilterChip-container">
      <VisuallyHidden aria-live="polite">{statusMessage}</VisuallyHidden>
      {!hideAddFiltersButton && (
        <Chip
          variant="important"
          label={t('component.searchFilterChips.addFiltersText')}
          onClick={() => openFilterModal()}
          icon={filterIcon}
          order="icon-first"
          dataCy="searchFilterChips-addFilters"
        />
      )}

      {!hideInsuranceButton && <SearchFilterInsuranceChip setStatusMessage={setStatusMessage} />}

      {showTimeRangeChip &&
        filterOptions.timeRanges.map((timeRange, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={`${t('common.removeFilter')} ${t(
              `component.searchFilterChips.label.timeRange.${timeRange}`
            )}`}
            label={t(`component.searchFilterChips.label.timeRange.${timeRange}`)}
            onClick={() => {
              setStatusMessage(
                `${t('common.filterRemoved')} ${t(
                  `component.searchFilterChips.label.timeRange.${timeRange}`
                )}`
              )
              setSearchFilters({
                ...filterOptions,
                timeRanges: filterOptions.timeRanges.filter((tr) => tr !== timeRange),
              })
            }}
            icon={<Times />}
          />
        ))}

      {showAppointmentTypeChip &&
        filterOptions.appointmentTypes.map((appointmentType, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={`${t('common.removeFilter')} ${t(
              `component.searchFilterChips.label.appointmentType.${appointmentType}`
            )}`}
            label={t(`component.searchFilterChips.label.appointmentType.${appointmentType}`)}
            onClick={() => {
              setStatusMessage(
                `${t('common.filterRemoved')} ${t(
                  `component.searchFilterChips.label.appointmentType.${appointmentType}`
                )}`
              )
              setSearchFilters({
                ...filterOptions,
                appointmentTypes: filterOptions.appointmentTypes.filter(
                  (selected) => selected !== appointmentType
                ),
              })
            }}
            icon={<Times />}
          />
        ))}

      {showLanguageChip && (
        <Chip
          variant="important"
          ariaLabel={`${t('common.removeFilter')} ${t(
            `component.searchFilterChips.label.language`,
            {
              language: filterOptions.language?.language,
            }
          )}`}
          label={t(`component.searchFilterChips.label.language`, {
            language: filterOptions.language?.language,
          })}
          onClick={() => {
            setStatusMessage(
              `${t('common.filterRemoved')} ${t(`component.searchFilterChips.label.language`, {
                language: filterOptions.language?.language,
              })}`
            )
            setSearchFilters({ ...filterOptions, language: null })
          }}
          icon={<Times />}
        />
      )}

      {showGenderChip && (
        <Chip
          variant="important"
          ariaLabel={`${t('common.removeFilter')} ${t(
            `component.searchFilterChips.label.gender.${filterOptions.gender}`
          )}`}
          label={t(`component.searchFilterChips.label.gender.${filterOptions.gender}`)}
          onClick={() => {
            setStatusMessage(
              `${t('common.filterRemoved')} ${t(
                `component.searchFilterChips.label.gender.${filterOptions.gender}`
              )}`
            )
            setSearchFilters({ ...filterOptions, gender: Gender.UNSPECIFIED })
          }}
          icon={<Times />}
        />
      )}

      {showPatientAgeGroupChip && (
        <Chip
          variant="important"
          ariaLabel={`${t('common.removeFilter')} ${
            filterOptions.patientAgeGroup!.patientAgeGroup
          }`}
          label={filterOptions.patientAgeGroup!.patientAgeGroup}
          onClick={() => {
            setStatusMessage(
              `${t('common.filterRemoved')} ${filterOptions.patientAgeGroup!.patientAgeGroup}`
            )
            setSearchFilters({ ...filterOptions, patientAgeGroup: null })
          }}
          icon={<Times />}
        />
      )}
      {showDurationsChip &&
        filterOptions.durations.map((duration, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={`${t('common.removeFilter')} ${
              duration.toString() + ' ' + t(`component.searchFilterChips.label.duration`)
            }`}
            label={duration.toString() + ' ' + t(`component.searchFilterChips.label.duration`)}
            onClick={() => {
              setStatusMessage(
                `${t('common.filterRemoved')} ${
                  filterOptions.durations.toString() +
                  ' ' +
                  t(`component.searchFilterChips.label.duration`)
                }`
              )
              if (filterOptions.durations.length === 1) {
                setSearchFilters({ ...filterOptions, durations: defaultSelectedDurations })
                return
              }
              setSearchFilters({
                ...filterOptions,
                durations: filterOptions.durations.filter((d) => d !== duration),
              })
            }}
            icon={<Times />}
          />
        ))}

      {!isDesktop && !isTablet && (
        <Chip
          variant="important"
          ariaLabel={t('common.calendar')}
          label={
            userHasSelectedDate
              ? capitalizeFirstLetter(selectedDate.format('dd D.M.'))
              : t('common.calendar')
          }
          onClick={setCalendarModalOpen}
          icon={<Calendar />}
          order="icon-first"
        />
      )}
    </ChipsContainer>
  )
}

export default SearchFilterChips
