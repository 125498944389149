import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Primary500, Gray600, Error500, Gray900 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnFlex, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Label = styled(Text)`
  margin-bottom: 4px;
`

const HelperContainer = styled(RowFlex)`
  margin-top: 4px;
`

const HelperText = styled(Text)`
  color: ${Gray600};
`

const CharacterCount = styled(Text)`
  margin-left: auto;
  white-space: nowrap;
`

const Container = styled(ColumnFlex)<{ error: boolean }>`
  ${(props) =>
    props.error
      ? css`
          ${Label}, ${HelperText} {
            color: ${Error500};
          }
        `
      : css`
          &:focus-within {
            ${Label}, ${HelperText} {
              color: ${Primary500};
            }
          }
        `}
`

export interface Props {
  id?: string
  label?: string
  helperText?: string | null | false
  error?: boolean
  characterCount?: string
  className?: string
  required?: boolean
}

const InputFieldContainerNew: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  helperText,
  error,
  characterCount,
  id,
  children,
  className,
  required,
}) => {
  const { t } = useTranslation()

  return (
    <Container error={Boolean(error)} className={className}>
      {label && label !== '' && (
        <Label $size={300} $color={Gray900} as="label" htmlFor={id} $weight="Medium">
          {label}
          {required && ' *'}
        </Label>
      )}
      {children}
      {helperText || characterCount ? (
        <HelperContainer>
          <HelperText id={`helper-${id}`} $size={300}>
            {helperText}
          </HelperText>
          {characterCount && (
            <CharacterCount
              $size={300}
              $color={Gray600}
              id={`counter-${id}`}
              aria-label={t('common.characterCount', { char: characterCount })}
            >
              {characterCount}
            </CharacterCount>
          )}
        </HelperContainer>
      ) : null}
    </Container>
  )
}

export default InputFieldContainerNew
