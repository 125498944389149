import styled from '@emotion/styled'
import { Button, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Divider from '../../../common/components/Divider/Divider'
import { ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import Modal from '../../../common/components/Modal/Modal'
import { Text } from '../../../common/components/Typography/Typography'
import UserSelectModalButton from '../../../common/components/UserSelect/UserSelectModalButton'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { useOHC } from '../../../common/hooks/useOHC'
import { scale } from '../../../common/utils/scale'
import useReserveState from '../hooks/useReserveState'
import { createAddressEditorValidationSchema } from '../utils/form'

import ReserveFormFieldset from './ReserveFormFieldset'

const HalfLengthContainer = styled(RowFlex)<{ isMobile: boolean }>`
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  & > * {
    width: ${(props) => (props.isMobile ? '100%' : 'calc(50% - 12px)')};
  }
`

interface DetailsWithButtonProps {
  details: Array<{ label: string; detail: string | string[] }>
  buttonText?: string
  onClick?(): void
}

const DetailsWithButton: React.FC<React.PropsWithChildren<DetailsWithButtonProps>> = ({
  details,
  buttonText,
  onClick,
  children,
}) => {
  return (
    <RowFlex $justifyContent="space-between" $gap={scale(2)}>
      <ColumnFlex $gap={scale(2)}>
        {details.map((detail, index) => (
          <ColumnFlex key={`${detail.label}-${detail.detail}-${index}`}>
            <Text $size={300} $weight="Regular" $height="Medium">
              {detail.label}
            </Text>
            {Array.isArray(detail.detail) ? (
              detail.detail.map((detailString) => (
                <Text key={`detail-${detailString}`} $size={400} $weight="Regular" $height="Medium">
                  {detailString}
                </Text>
              ))
            ) : (
              <Text $size={400} $weight="Regular" $height="Medium">
                {detail.detail}
              </Text>
            )}
          </ColumnFlex>
        ))}
      </ColumnFlex>
      {onClick && buttonText ? (
        <div>
          <Button type="button" variant="outlined" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      ) : (
        children ?? <div />
      )}
    </RowFlex>
  )
}

interface AddressEditorProps
  extends Pick<
    FormikProps<{
      address: string
      postalCode: string
      city: string
    }>,
    'values' | 'handleChange' | 'handleBlur' | 'errors'
  > {}

const AddressEditor: React.FC<React.PropsWithChildren<AddressEditorProps>> = ({
  values,
  errors,
  handleBlur,
  handleChange,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  return (
    <ColumnFlex $gap="15px">
      <TextField
        id="address"
        label={t('component.reserveContactInformationForm.label.address')}
        helperText={errors.address}
        error={Boolean(errors.address)}
        value={values.address}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        data-cy="addressField"
      />
      <HalfLengthContainer isMobile={isMobile}>
        <TextField
          id="postalCode"
          label={t('component.reserveContactInformationForm.label.postalCode')}
          helperText={errors.postalCode}
          error={Boolean(errors.postalCode)}
          value={values.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          data-cy="postalCodeField"
        />
        <TextField
          id="city"
          label={t('component.reserveContactInformationForm.label.city')}
          helperText={errors.city}
          error={Boolean(errors.city)}
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          data-cy="cityField"
        />
      </HalfLengthContainer>
    </ColumnFlex>
  )
}

type AddressInformation = {
  address: string
  postalCode: string
  city: string
}

interface AddressEditorModalProps {
  open: boolean
  firstName: string
  lastName: string
  initialValues: AddressInformation
  setOpen(value: boolean): void
  onSubmit(values: AddressInformation): void
}

const AddressEditorModal: React.FC<React.PropsWithChildren<AddressEditorModalProps>> = ({
  open,
  firstName,
  lastName,
  initialValues,
  setOpen,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const validationSchema = createAddressEditorValidationSchema(t)

  if (!open) {
    return null
  }

  return (
    <Formik<typeof initialValues>
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, handleChange, handleBlur, submitForm, isSubmitting, isValidating }) => (
        <Modal
          open={true}
          primaryButtonDisabled={isValidating || isSubmitting}
          primaryButtonText={t('common.save')}
          onPrimaryButtonClick={() => {
            submitForm().then(() => setOpen(false))
          }}
          secondaryButtonText={t('common.close')}
          onSecondaryButtonClick={() => setOpen(false)}
        >
          <Form>
            <ColumnFlex $alignItems="center">
              <Text>{t('component.reserveContactInformationForm.addressModal.heading')}</Text>
              <Text
                $size={300}
                $weight="Regular"
                $height="Medium"
              >{`${firstName} ${lastName}`}</Text>
            </ColumnFlex>
            <AddressEditor
              values={values}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

interface Props
  extends Pick<
    FormikProps<{
      ssn: string
      firstName: string
      lastName: string
      address: string
      postalCode: string
      city: string
      additionalInformation: string
      sendConfirmationSms: boolean
      minorDisclosureOfInformation: boolean
      showAddressFields: boolean
    }>,
    'values' | 'handleChange' | 'handleBlur' | 'errors' | 'setValues'
  > {}

const ReserveAddressInformationForm: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setValues,
}) => {
  const { selectedUser, loggedInUser, users, setSelectedUser } = useReserveState()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { isOHCSide } = useOHC()
  const showUserSelectButton = !isOHCSide && Boolean(loggedInUser?.isDelegateUser)

  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)

  return (
    <>
      {loggedInUser && loggedInUser.ssn !== selectedUser.ssn && (
        <>
          <ReserveFormFieldset legend={t('component.reserveContactInformationForm.reserver')}>
            <DetailsWithButton
              details={[
                {
                  label: t('component.reserveContactInformationForm.label.name'),
                  detail: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
                },
                {
                  label: t('component.reserveContactInformationForm.label.ssn'),
                  detail: `${loggedInUser.ssn}`,
                },
              ]}
            />
          </ReserveFormFieldset>
          <Divider />
        </>
      )}

      <ReserveFormFieldset legend={t('component.reserveContactInformationForm.customer')}>
        {loggedInUser && selectedUser.ssn && (
          <DetailsWithButton
            details={[
              ...(selectedUser.firstName && selectedUser.lastName
                ? [
                    {
                      label: t('component.reserveContactInformationForm.label.name'),
                      detail: `${selectedUser.firstName} ${selectedUser.lastName}`,
                    },
                  ]
                : []),
              {
                label: t('component.reserveContactInformationForm.label.ssn'),
                detail: `${selectedUser.ssn}`,
              },
            ]}
          >
            {showUserSelectButton && (
              <UserSelectModalButton
                users={users}
                loggedInUser={loggedInUser}
                onSelectUser={(user) => {
                  if (selectedUser.omUid !== user.omUid) {
                    setSelectedUser(user)
                    setValues((prev) => {
                      return {
                        ...prev,
                        ssn: user.ssn,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: user.phone,
                        email: user.email,
                        additionalInformation: values.additionalInformation,
                      }
                    })
                  }
                }}
              />
            )}
          </DetailsWithButton>
        )}
        {!selectedUser.firstName && !selectedUser.lastName && (
          <HalfLengthContainer isMobile={isMobile}>
            <TextField
              id="firstName"
              label={t('component.reserveContactInformationForm.label.firstName')}
              helperText={errors.firstName}
              error={Boolean(errors.firstName)}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              data-cy="firstNameField"
            />
            <TextField
              id="lastName"
              label={t('component.reserveContactInformationForm.label.lastName')}
              helperText={errors.lastName}
              error={Boolean(errors.lastName)}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              data-cy="lastNameField"
            />
            {!selectedUser.ssn && (
              <TextField
                id="ssn"
                label={t('component.reserveContactInformationForm.label.ssn')}
                helperText={errors.ssn}
                error={Boolean(errors.ssn)}
                value={values.ssn}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                data-cy="ssnField"
                aria-describedby="ssn-instructions"
              />
            )}
          </HalfLengthContainer>
        )}
        {values.showAddressFields && (
          <>
            <Divider />
            {!selectedUser.hasAddress ? (
              <AddressEditor
                values={values}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            ) : (
              <DetailsWithButton
                details={[
                  {
                    label: t('component.reserveContactInformationForm.address'),
                    detail: [values.address, `${values.postalCode} ${values.city}`],
                  },
                ]}
                onClick={() => setAddressModalOpen(true)}
                buttonText={t('component.reserveContactInformationForm.edit')}
              />
            )}
          </>
        )}
      </ReserveFormFieldset>

      <AddressEditorModal
        open={addressModalOpen}
        setOpen={setAddressModalOpen}
        firstName={selectedUser.firstName}
        lastName={selectedUser.lastName}
        initialValues={{
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
        }}
        onSubmit={(newAddress) =>
          setValues((prev) => ({
            ...prev,
            ...newAddress,
          }))
        }
      />
    </>
  )
}

export default ReserveAddressInformationForm
