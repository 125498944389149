import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  White,
  Primary500,
  Gray300,
  Gray500,
  Gray600,
  Primary600,
} from '@mehilainen/mds-customer/colors'
import { ArrowRight, BurgerMenu, Globe, Lock } from '@mehilainen/mds-customer/icons'
import { Button, Menu as Menu, MenuItem } from '@mui/material'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../../__generated__/api'
import { jwtKey, LangOptionButtonBorderColor } from '../../../constants'
import { LoginStatus } from '../../../state/user/atoms'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useOHC } from '../../hooks/useOHC'
import i18n from '../../i18n/i18n'
import api from '../../services/api'
import { maxWidth } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { CenteredRowFlex, ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import UserButton from './UserButton'
import DesktopLogo from './desktopLogo.svg?react'
import DesktopLogoWhite from './desktopLogoWhite.svg?react'
import MobileLogo from './mobileLogo.svg?react'
import MobileLogoWhite from './mobileLogoWhite.svg?react'

const HamburgerIcon = styled(BurgerMenu)`
  width: 26px;
  height: 26px;
`

const RoleHeading = styled(Text)<{ variant: 'nested' | 'detached' }>`
  color: ${(props) => (props.variant === 'nested' ? White : Primary500)};
`

const LogoutButton = styled.button<{ isMobile: boolean }>`
  cursor: pointer;
  padding: ${(props) => (props.isMobile ? '10px 14px' : '10px 24px')};
  background: ${White};
  color: ${Primary500};
  border: 1px solid ${Gray300};
  border-radius: 100px;

  &:hover {
    border: 1px solid ${Gray500};
  }
`

const ImpersonatedLogout = styled(LogoutButton)<{ isMobile: boolean }>`
  background: ${White};
  color: ${Primary500};
  border: 1px solid ${White};
  padding: ${(props) => (props.isMobile ? '8px 14px' : '8px 24px')};
`

const ImpersonatedButtonText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25vw;
`

const ImpersonatedButtonContainer = styled(CenteredRowFlex)`
  gap: 10px;
`
const ImpersonatedButtonTextContainer = styled(ColumnFlex)`
  align-items: start;
`

const ButtonWithIcon = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
`

const MenuAndLogoutContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 6px;

  :hover {
    cursor: pointer;
  }
`

const LogoutContainer = styled.nav`
  text-align: right;
`

const StyledMenuItem = styled(MenuItem)<{ dense?: boolean }>`
  display: flex;
  flex-direction: row;
  background: transparent;
  border: none;
  width: 100%;
  padding: ${(props) => (props.dense ? `${scale(1)}` : `${scale(2)} ${scale(1)}`)};
  justify-content: space-between;
  align-items: center;
  color: ${Primary500};
  text-decoration: none !important;
  :not(:last-child) {
    border-bottom: 1px solid ${LangOptionButtonBorderColor};
  }

  &:focus-visible {
    outline: auto;
    background: transparent;
  }
`

const MenuTextItem = styled(Text)<{ dense?: boolean }>`
  display: inline-block;
  background: transparent;
  border: none;
  padding: ${(props) => (props.dense ? `${scale(1)}` : `${scale(2)} ${scale(1)}`)};
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  text-transform: uppercase;
`

const MenuOption = styled(StyledMenuItem)`
  :not(:last-child) {
    border-bottom: 1px solid ${LangOptionButtonBorderColor};
  }
`

const MenuOptionButton = styled(MenuOption)`
  color: ${Primary500};

  :hover {
    color: ${Primary600};
    cursor: pointer;
  }

  a {
    line-height: 1.8;
  }
`

const LogoButton = styled.a<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-self: ${(props) => (props.isMobile ? 'start' : 'center')};
`

const DetachedTheme = css`
  position: relative;

  ${ButtonWithIcon} {
    color: ${Primary500};
  }
`

const Container = styled.header<{
  isMobile: boolean
  loginStatus: LoginStatus
  variant: 'nested' | 'detached'
}>`
  position: absolute;
  width: 100%;
  display: grid;
  gap: ${(props) => (props.isMobile ? '0px' : '25px')};
  grid-template-columns: ${(props) => (props.isMobile ? '1fr 0fr 0fr' : '1fr 1fr 1fr')};
  align-items: center;
  padding: ${(props) => (props.isMobile ? '12px 16px' : '12px 16px')};
  background: transparent;
  ${(props) => props.variant === 'detached' && DetachedTheme}

  > svg {
    justify-self: center;
  }

  > div {
    justify-self: flex-end;
  }

  @media (min-width: ${maxWidth.banner}px) {
    width: ${maxWidth.banner}px;
    left: calc(50% - ${maxWidth.banner}px / 2);
  }
`

interface Props {
  onLanguageSelect(lang: SupportedLanguage): void
  loginStatus?: LoginStatus
  logoutCallBack?(): void
  variant: 'nested' | 'detached'
}

const NavBar: React.FC<React.PropsWithChildren<Props>> = ({
  loginStatus,
  logoutCallBack,
  onLanguageSelect,
  variant,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { isOHCSide, ohcAllowedStatus, setOHC } = useOHC()

  const [userName, setUserName] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      setAnchorEl(buttonRef.current)
    },
    [setAnchorEl]
  )

  const handleSetUserName = useCallback(async () => {
    const response = await api.v1.getUser({})
    const user = response.data
    setUserName(`${user.firstName} ${user.lastName}`)
  }, [setUserName])

  useEffect(() => {
    const token = sessionStorage.getItem(jwtKey)
    if (!userName && loginStatus === 'impersonated' && token) {
      handleSetUserName()
    } else if (!loginStatus && userName) {
      setUserName(null)
    }
  }, [handleSetUserName, loginStatus, userName])

  const languages = [
    {
      langCode: SupportedLanguage.Fi,
      label: 'Suomeksi',
    },
    {
      langCode: SupportedLanguage.Sv,
      label: 'På Svenska',
    },
    {
      langCode: SupportedLanguage.En,
      label: 'In English',
    },
  ]

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLangSelect = (selected: SupportedLanguage) => {
    handleClose()
    onLanguageSelect(selected)
  }

  const handleSwitchOHC = () => {
    setOHC(!isOHCSide)
    handleClose()
  }

  const getMenuLinks = () => {
    if (ohcAllowedStatus === 'allowed') {
      // OHC User
      const links = t('component.navBar.ohcLinks').split(';')

      return (
        <>
          <MenuOptionButton
            key={isOHCSide ? 'linkMenuItem-private' : 'linkMenuItem-occupational'}
            onClick={handleSwitchOHC}
            className="no-external-icon"
          >
            <Text $size={200} $weight="Regular">
              {t(
                `component.navBar.linkContents.${isOHCSide ? 'private' : 'occupational'}.linkText`
              )}
            </Text>
            <ArrowRight htmlColor="initial" />
          </MenuOptionButton>
          {links.map((key) => (
            <StyledMenuItem
              key={`linkMenuItem-${key}`}
              className="no-external-icon"
              {...{
                href: t(`component.navBar.linkContents.${key}.link`),
                component: 'a',
              }}
            >
              <Text $size={200} $weight="Regular">
                {t(`component.navBar.linkContents.${key}.linkText`)}
              </Text>
              <ArrowRight htmlColor="initial" />
            </StyledMenuItem>
          ))}
        </>
      )
    } else {
      // Private user
      const links = t('component.navBar.privateLinks').split(';')

      return links.map((key) => (
        <StyledMenuItem
          key={`linkMenuItem-${key}`}
          {...{
            href: t(`component.navBar.linkContents.${key}.link`),
            component: 'a',
          }}
          className="no-external-icon"
        >
          <Text $size={200} $weight="Regular">
            {t(`component.navBar.linkContents.${key}.linkText`)}
          </Text>
          <ArrowRight htmlColor="initial" />
        </StyledMenuItem>
      ))
    }
  }

  return (
    <Container
      isMobile={isMobile}
      loginStatus={loginStatus}
      variant={variant}
      data-cy="navbar-container"
    >
      <MenuAndLogoutContainer
        aria-label={t('common.openMenu')}
        style={{ gridColumn: isMobile ? 2 : 1, gridRow: 1 }}
      >
        <ButtonWithIcon
          onClick={handleClick}
          data-cy="navbar-lang-select-container"
          aria-expanded={anchorEl !== null}
          aria-owns={anchorEl ? 'language-popover' : undefined}
          aria-label={t('common.openMenu')}
          aria-haspopup="true"
          ref={buttonRef}
        >
          <HamburgerIcon />
        </ButtonWithIcon>
        {!isMobile && (
          <RoleHeading onClick={handleClick} variant={variant}>
            {t(`common.mode.${isOHCSide ? 'occupational' : 'private'}`)}
          </RoleHeading>
        )}
      </MenuAndLogoutContainer>
      <Menu
        id="language-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            padding: scale(1.5),
            width: '250px',
          },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        {getMenuLinks()}

        <MenuTextItem $weight="Regular" $color={Gray600} $size={200}>
          {t('component.navBar.changeLanguage')}
        </MenuTextItem>
        {languages.map((language, index) =>
          i18n.language === language.langCode ? (
            <MenuOption key={`${language.langCode}-${index}`}>
              <Text $color={'initial'} $size={200} $weight="Regular" lang={language.langCode}>
                {language.label}
              </Text>
            </MenuOption>
          ) : (
            <MenuOptionButton
              onClick={() => handleLangSelect(language.langCode)}
              key={`${language.langCode}-${index}`}
              data-cy={`navbar-lang-option-${language.langCode}`}
              lang={language.langCode}
            >
              <Text $size={200} $weight="Regular" as="a" className="no-external-icon">
                {language.label}
              </Text>
              <Globe />
            </MenuOptionButton>
          )
        )}
      </Menu>
      <LogoButton
        className="no-external-icon"
        isMobile={isMobile}
        href={t('common.wwwPageLink')}
        target="_self"
        aria-label={t('component.navBar.toWwwSite')}
        style={{ gridColumn: isMobile ? 1 : 2, gridRow: 1 }}
      >
        {isMobile ? (
          variant === 'nested' ? (
            <MobileLogoWhite />
          ) : (
            <MobileLogo />
          )
        ) : variant === 'nested' ? (
          <DesktopLogoWhite />
        ) : (
          <DesktopLogo />
        )}
      </LogoButton>

      <LogoutContainer style={{ gridColumn: 3, gridRow: 1 }}>
        {loginStatus === 'authenticated' && <UserButton />}
        {loginStatus === 'authenticated' && !isMobile && (
          <Button
            onClick={logoutCallBack}
            style={{ marginLeft: scale(1) }}
            variant="text"
            size="small"
          >
            <Text $size={200} $color={variant === 'detached' ? Primary500 : White}>
              {t('component.navBar.logout')}
            </Text>
          </Button>
        )}
        {loginStatus === 'external' && (
          <LogoutButton isMobile={isMobile} onClick={logoutCallBack}>
            <Text $size={300}>{t('component.navBar.logout')}</Text>
          </LogoutButton>
        )}
        {loginStatus === 'impersonated' && (
          <ImpersonatedLogout isMobile={isMobile} onClick={logoutCallBack}>
            <ImpersonatedButtonContainer>
              <Lock htmlColor={Primary500} style={{ width: 18, height: 18 }} />
              <ImpersonatedButtonTextContainer>
                <Text $size={200}>{t('component.navBar.buttonHelpText')}</Text>
                {!isMobile && (
                  <ImpersonatedButtonText $size={300}>{userName}</ImpersonatedButtonText>
                )}
              </ImpersonatedButtonTextContainer>
            </ImpersonatedButtonContainer>
          </ImpersonatedLogout>
        )}
      </LogoutContainer>
    </Container>
  )
}

export default NavBar
