import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// List of parameters that should be preserved across navigation
const PRESERVED_PARAMS = ['omUid']

/**
 * Hook to preserve specific query parameters across navigation
 * This ensures parameters like omUid stay in the URL when navigating between pages
 */
const usePreserveQueryParams = (): void => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // Listen for location changes
    const unlisten = history.listen((newLocation) => {
      const currentParams = new URLSearchParams(location.search)
      const newParams = new URLSearchParams(newLocation.search)

      let paramsAdded = false

      // Add preserved params from current URL to new URL if they don't exist
      PRESERVED_PARAMS.forEach((param) => {
        if (currentParams.has(param) && !newParams.has(param)) {
          newParams.set(param, currentParams.get(param)!)
          paramsAdded = true
        }
      })

      // If we added any params, update the URL
      if (paramsAdded) {
        const newSearch = newParams.toString()
        history.replace({
          ...newLocation,
          search: newSearch ? `?${newSearch}` : '',
        })
      }
    })

    return () => {
      unlisten()
    }
  }, [history, location])
}

export default usePreserveQueryParams
