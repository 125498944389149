import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectInput from '../../../common/components/SelectInput/SelectInput'
import { useNode } from '../../../common/hooks/useNode'
import { useModalEvents } from '../../../xstate/events'
import { useSelectedPractitioner, useSelectedSearchNodeId } from '../../../xstate/selectors'

const SearchServiceSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { openServiceModal } = useModalEvents()
  const selectedSearchNodeId = useSelectedSearchNodeId()
  const selectedPractitioner = useSelectedPractitioner()
  const { node: selectedSearchNode } = useNode(selectedSearchNodeId)

  return (
    <SelectInput
      label={t('component.serviceSearch.label')}
      value={selectedPractitioner?.name ?? selectedSearchNode?.name}
      onClick={openServiceModal}
      dataCy="searchSelect-textField"
    />
  )
}

export default SearchServiceSelect
